
export default class Types {
  /** CLEAR NOTIFICATIONS INFO **/
  static CLEAR_NOTIFICATIONS_INFO = 'CLEAR_NOTIFICATIONS_INFO';

  /** GET NOTIFICATIONS INFO **/
  static DELETE_NOTIFICATIONS_INFO_FAILED = 'DELETE_NOTIFICATIONS_INFO_FAILED';
  static DELETE_NOTIFICATIONS_INFO_SUCCESS = 'DELETE_NOTIFICATIONS_INFO_SUCCESS';

  /** GET NOTIFICATIONS INFO **/
  static GET_NOTIFICATIONS_INFO_FAILED = 'GET_NOTIFICATIONS_INFO_FAILED';
  static GET_NOTIFICATIONS_INFO_SUCCESS = 'GET_NOTIFICATIONS_INFO_SUCCESS';

  /** PUT NOTIFICATIONS INFO **/
  static PUT_NOTIFICATIONS_INFO_FAILED = 'PUT_NOTIFICATIONS_INFO_FAILED';
  static PUT_NOTIFICATIONS_INFO_SUCCESS = 'PUT_NOTIFICATIONS_INFO_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_NOTIFICATIONS_INFO = 'SET_NOTIFICATIONS_INFO';
}
