import Routing from "./Routing";

export default class UserPermission {
  static PERMISSION_ASSISTANT_VIEWS = [
      Routing.cardList,
      Routing.cardDetail,
      Routing.digitalTicket,
      Routing.userProfile,
      Routing.costumerSupport,
  ]

}
