import React from 'react';
import { Route, Redirect, useParams, useLocation } from 'react-router-dom';
import Routing from './Routing';
import UserManager from "../user/UserManager";
import {TypeUsers} from "../backOffice/BackOfficeManager";
import UserPermission from "./UserPermission";

const PrivateRoute = ({ component: Component, rest, accessToken, redirect, path, maintenance, props }) => {
  const { userInfo } = props;

  if(maintenance) {
    return(<Redirect to={Routing.maintenancePage} />)
  }

  if (!accessToken) {
    const search = getSearchQueryFromPath();
    const loginPath = getPathWithSearchQuery(search);

    return (<Redirect to={redirect ? redirect : loginPath} />)
  }

  const profileUser = UserManager.getUserProfile(userInfo);
  if (
    profileUser === TypeUsers.ASSISTANT &&
    !UserPermission.PERMISSION_ASSISTANT_VIEWS.includes(path) &&
    path !== Routing.newPassword &&
    path !== Routing.login &&
    path !== Routing.contact &&
    path !== Routing.confirmation
  ){
    return (<Redirect to={Routing.cardList} />)
  }

  return (
    <Route {...rest} path={path} render={props => <GetUseParamsView component={Component} props={props} />} />
  );
};

function getSearchQueryFromPath() {
  const location = useLocation();
  const { search } = location;
  return search;
}

function getPathWithSearchQuery(search) {
  let loginPath = Routing.login

  if (search.length > 0) {
    const basePathToRedirectToDocuments = '?redirect=facturas-anexos&'
    const query = search.substring(search.indexOf('?') + 1, search.length)
    loginPath = `${Routing.login}${basePathToRedirectToDocuments}${query}`
  }

  return loginPath
}

function GetUseParamsView({ component: Component, props }) {
  let { id } = useParams();
  return <Component idParams={id} {...props} />;
}


export default PrivateRoute;
