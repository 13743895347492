
export const emailValidator = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const isChecked = (checkBoxValue) => {
  // Check format
  if (!checkBoxValue || typeof checkBoxValue !== 'boolean') return false;

  return true;
};

export const isValidCardCVV = (cvv) => {
  // Check format
  if (!cvv || typeof cvv !== 'string') return false;

  // Check length
  return cvv.length >= 3;
};

export const isValidCardExpirationDate = (expirationDate) => {
  // Check format
  if (!expirationDate || typeof expirationDate !== 'string') return false;

  // Check length
  return expirationDate.length >= 5;
};

export const isValidCardNumber = (card, cardLength) => {
  // Check format
  if (!card || typeof card !== 'string') return false;

  // Check length
  return card.length >= cardLength;
};

export const isValidDni = (dni) => {
  // Check format
  if (!dni || typeof dni !== 'string') return false;

  let number
  let letter
  let word
  let regular_dni_expression = /^\d{8}[a-zA-Z]$/;

  if (regular_dni_expression.test(dni)) {
    number = dni.substr(0, dni.length - 1);
    letter = dni.substr(dni.length - 1, 1);
    number = number % 23;
    word = 'TRWAGMYFPDXBNJZSQVHLCKET';
    word = word.substring(number, number + 1);
    if (word != letter.toUpperCase()) {
      //console.log('Dni erroneo, la word del NIF no se corresponde');
      return false
    } else {
      //console.log('Dni correcto');
      return true
    }
  } else {
    //console.log('Dni erroneo, formato no válido');
    return false
  }
}

export const isValidCifOrDni = (cif) => {
  const nifRegex = /^[0-9]{8}[A-Za-z]$/i;
  const nieRegex = /^[A-Za-z]{1}[0-9]{7}[A-Za-z0-9]{1}$/i
  // Check format
  if (!cif || typeof cif !== 'string') return false;

  return nifRegex.test(cif) || nieRegex.test(cif);
};

export const isValidCode = (code) => {
  const regex = /^#?([0-9]{6})$/;
  // Check format
  if (!code || typeof code !== 'string') return false;
  // Check length and number type
  return regex.test(code);
};

export const isValidCompany = (company) => {
  // Check format
  if (!company || typeof company !== 'string') return false;

  // Check length
  return company.length > 0;
};

export const isValidConsult = (consult) => {
  // Check format
  if (!consult || typeof consult !== 'string') return false;

  // Check length
  return consult.length > 20;
};

export const isValidEmail = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Check format
  if (!email || typeof email !== 'string') return false;

  return regex.test(email);
};

export const isValidIdCard = (id) => {
  const regex = /^#?([0-9]{13})$/;

  if (!id || typeof id !== 'string') return false;

  return regex.test(id);
}

export const isValidIdSap = (id) => {
  const regex = /^#?([0-9]{6})$/;

  if (!id || typeof id !== 'string') return false;

  return regex.test(id);
}

export const isValidLoginForm = (username, password) => {
  return isValidEmail(username) && isValidPassword(password);
};

export const isValidName = (name) => {
  // Check format
  if (!name || typeof name !== 'string') return false;

  // Check length
  return name.length > 2;
};

export const isValidNumber = (value) => {
  // Check format
  if (!value || typeof value !== 'number') return false;

  // Check length
  return value.length > 0;
};

export const isValidPassword = (password) => {
  // Check format
  if (!password || typeof password !== 'string') return false;

  // Check length
  return password.length >= 6;
};

export const isValidPasswordCoincidence = (password, passwordRepeat) => {
  // Check format
  if (!password || !passwordRepeat || typeof password !== 'string' || typeof passwordRepeat !== 'string') return false;

  // Check length
  if (password.length !== passwordRepeat.length) return false;

  // Check passwords
  return password.trim() === passwordRepeat.trim();
};

export const isValidPhone = (phone) => {
  const regex = /^#?([0-9]{9})$/;
  // Check format
  if (!phone || typeof phone !== 'string') return false;

  // Check length
  return regex.test(phone);
};

export const isValidPostalCode = (postalCode) => {
  // Check format
  if (!postalCode || typeof postalCode !== 'string') return false;

  // Check length
  return postalCode.length >= 5;
};

export const isValidString = (value) => {
  // Check format
  if (!value || typeof value !== 'string') return false;

  // Check length
  return value.length > 0;
};
export const isValidSurnames = (surnames) => {
  // Check format
  if (!surnames || typeof surnames !== 'string') return false;

  // Check length
  return surnames.length > 0;
};

export const isValidObject = (value) => {
  // Check format
  if (!value || typeof value !== 'object') return false;

  // Check length
  return true;
};
