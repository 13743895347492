import Types from "./Types";

const INITIAL_STATE = {
  billingQuantity: 0.00,
  billingStatus: "",
  dataCards: null,
  dataIdCard: null,
  dateActivationStart: '',
  dateActivationEnd: '',
  dateLastPurchaseStart: '',
  dateLastPurchaseEnd: '',
  error:'',
  isLoadingCard: false,
  isLoadingCardDetails: false,
  isLoadingPostSearch: false,
  initialBalance: 0.00,
  usesStatus: "",
  uuid: '',
  totalResults: null,
  cardsCurrentPage: 1,
  cardsLimit: 25,
  pagesNum: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR CARD **/
    case Types.CLEAR_DATA_CARDS:
      return { ...INITIAL_STATE };

    /** GET CARD DETAILS**/
    case Types.GET_CARD_DETAILS_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        dataIdCard: action.payload,
      };

    /** GET CARDS **/
    case Types.GET_CARDS_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_CARDS_SUCCESS:
      return {
        ...state,
        dataCards: action.payload.data,
        pagesNum: action.payload.numPages,
        totalResults: action.payload.totalResults
      };

    /** UPDATE STATE **/
    case Types.SET_DATA_CARDS:
      return { ...state, [action.payload.prop]: action.payload.value };


    default:
      return state;
  }
}
