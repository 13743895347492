import React from 'react';

const PaperTicketDetailsInformation = (props) => {

  const {afterPayText, merchantType, fillText} = props;

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center mt-4'}>
      <p className={'textPaperTicketTitle'}>{afterPayText}</p>
      <p className={'textPaperTicket'}>{merchantType} - {fillText}</p>
    </div>
  )
};

const containerInfo = (title, description, title2, description2) => {
  if (title && title2){
    return (
      <div>
        <div>
          <p>{title} </p>
          <p>{description}</p>
        </div>

        <div>
          <p>{title2} </p>
          <p>{description2}</p>
        </div>

      </div>
    )
  }
  return(
    <div>
      <p>{title} </p>
      <p>{description}</p>
    </div>
  )
};


export default PaperTicketDetailsInformation
