import Types from "./Types";

const INITIAL_STATE = {
  code: null,
  codeSent: false,
  codeSentMessage: '',
  error: [],
  isLoading: false,
  password: '',
  repeatPassword: '',
  username: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DATA **/
    case Types.CLEAR_DATA_REMEMBER_PASSWORD:
      return { ...INITIAL_STATE };

    /** LOGIN **/
    case Types.REMEMBER_PASSWORD_FAILED:
      return { ...state, error: action.payload };

    case Types.REMEMBER_PASSWORD_START:
      return { ...state, error: null };

    case Types.REMEMBER_PASSWORD_SUCCESS:
      return { ...state };

    /** UPDATE STATE **/
    case Types.SET_REMEMBER_PASSWORD_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
};