import Types from "./Types";
import {postCardPin} from '../../services/api/API';
import {isDev} from '../../utils/Utils';
import {setRechargeCardsDataState} from '../rechargeCards/RechargeCardsActions';
import {strings} from '../../resources/locales/i18n';

export const apiPostCardsPin = (toggleModal) => async (dispatch, getState) => {
  const { excelFile, formType } = getState().CardsPinReducer;

  dispatch(setCardPinDataState({ prop: 'dataCardsPin', value: null }));
  dispatch(setCardPinDataState({ prop: 'errorRequest', value: '' }));

  let base64String = '';

  if (formType === 'uploadForm'){
    // -- 1. Check file size
    if (!excelFile) {
      dispatch(setCardPinDataState({ prop: 'errorRequest', value: strings('cardsPin.dontUploadedExcel') }));
      toggleModal();
      return;
    }

    if (excelFile && excelFile.size > 2000000) {
      if (isDev()) console.log('fileSize - Error: ', `${excelFile.size} es superior a 2Mb`);
      dispatch(setCardPinDataState({ prop: 'errorRequest', value: `${excelFile.name} es superior a 2Mb. Prueba con un archivo más pequeño` }));
      toggleModal();
      return;
    }
    // -- 2. Convert to base64
    const getBase64ExcelInfo = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    const base64Info = await getBase64ExcelInfo(excelFile)
    base64String = base64Info.substr(base64Info.indexOf(',') + 1)
  }

  dispatch(setCardPinDataState({ prop: 'isLoadingCardsPin', value: true }));

  await dispatch(postCardPin(base64String,
    (tag, response) => {
      if (isDev()) console.log('apiPostCardsPin - Error: ', response);

      let error = strings('error.server400')
      if (response && response.data && response.data.message){
        error = response.data.message
      }
      if (response.message){
        error = response.message
      }

      dispatch(setCardPinDataState({
        prop: 'errorRequest',
        value: error
      }));
      toggleModal();
    },
    (tag, response) => {
      if (isDev()) console.log('apiPostCardsPin - Success: ', response);
      dispatch({
        type: Types.GET_CARD_PIN_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setCardPinDataState({ prop: 'isLoadingCardsPin', value: false }));
};

export const clearDataCardPin = () => ({
  type: Types.CLEAR_DATA_CARD_PIN,
});

export const setCardPinDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_CARD_PIN,
  payload: { prop, value }
});
