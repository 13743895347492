import Types from "./Types";

const INITIAL_STATE = {
  admin: '',
  cognitoResponse: '',
  confirmationCode: '',
  email: '',
  error: [],
  errorRequest: '',
  isLoadingConfirmation: false,
  isLoadingGetCode: false,
  logoutMessage: '',
  mfa: false,
  organization: '',
  phone: '',
  username: '',
  requestMessage: '',
  surnames: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DATA **/
    case Types.CLEAR_DATA_CREATE_USER:
      return { ...INITIAL_STATE, error: [] };

    /** CREATE_USER **/
    case Types.CREATE_USER_FAILED:
      return { ...state, error: action.payload };

    case Types.CREATE_USER_SUCCESS:
      return { ...state };


    /** UPDATE STATE **/
    case Types.SET_CREATE_USER_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
};
