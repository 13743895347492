export default class EditOrgManager {
  
  static getOrgId(org) {
    return org && org.id ? org.id : null
  }

  static getOrgIdSap(org) {
    return org && org.id_sap ? org.id_sap : null
  }

  static getOrgName(org) {
    return org && org.name ? org.name : null
  }

}