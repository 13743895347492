import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Loading} from '../../components';
import PaperTicketManager from './PaperTicketManager';
import PaperTicketHeader from './components/PaperTicketHeader';
import {apiGetPaperTicket, apiGetPaperTicketOnline, clearDataPaperTicket, setPaperTicketState} from './PaperTicketActions';
import PaperTicketItem from './components/PaperTicketItem';
import PaperTicketPaymentDetails from './components/PaperTicketPaymentDetails';
import './PaperTicket.css';
import PaperTicketDetailsInformation from './components/PaperTicketDetailsInformation';
import PaperTicketPromotion from './components/PaperTicketPromotion';
import {strings} from '../../resources/locales/i18n';
import Routing from '../routing/Routing';
import HeaderContainer from '../navegation/HeaderContainer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CardsManager from '..//cards/CardsManager';
import {getMomentDateFormat} from '../../utils/Utils';
import { Link } from 'react-router-dom';


class PaperTicketContainer extends Component {

  componentDidMount() {
    this._clearDataPaperTicket();
    this._apiGetPaperTicket();
  }

  render () {
    return (
      <>
        {this.headerContainer()}
        {this.renderContent()}
      </>
    );
  }

  renderContent(){
    const {dataPaperTickets, isLoadingPaperTicket } = this.props;

    if (isLoadingPaperTicket){
      return(
        <div className={'d-flex justify-content-center align-items-center'} style={{height: 100}}>
          <Loading color={'#006138'} height={35} width={35}/>
        </div>
      )
    }

    if (!dataPaperTickets || dataPaperTickets.length === 0){
      return(
        <div className={'d-flex justify-content-center align-items-center'} style={{height: 100}}>
          <p>{strings("paperTicket.notFoundYourPaper")}</p>
        </div>
      )
    }

    return(
      <div className={'d-flex justify-content-center mx-5 pb-5'} style={{color: '#464646', backgroundColor: 'white'}}>
       { <div className={'d-flex flex-column'} style={{flex:0.45}}>
          {this.renderInformation()}
        </div>}
        <div className={'d-flex flex-column ml-5 pl-4 ml-5 px-3'} style={{flex:0.55}}>
          <div id='paperTicket' className={'d-flex flex-column px-3'} style={{width: 600}}>
            {this.renderHeaderTicket()}
            {this.renderItemsTicket()}
            {this.renderPaymentDetails()}
            {this.renderInformationTicket()}
            {this.renderInformationPromotions()}
          </div>
        </div>
      </div>
    )
  }

  renderHeaderTicket () {
    const { dataPaperTickets } = this.props;
    const cif           = PaperTicketManager.getCif(dataPaperTickets);
    const corporateName = PaperTicketManager.getCorporateName(dataPaperTickets);
    const shopCode      = PaperTicketManager.getShopCode(dataPaperTickets);
    const shopName      = PaperTicketManager.getShopName(dataPaperTickets);
    const phone         = PaperTicketManager.getShopPhone(dataPaperTickets);
    const document      = PaperTicketManager.getTicketId(dataPaperTickets);
    const seller        = PaperTicketManager.getOperatorId(dataPaperTickets);
    const date          = PaperTicketManager.getPurchaseDate(dataPaperTickets);
    const hours         = PaperTicketManager.getPurchaseHour(dataPaperTickets);
    const logo          = PaperTicketManager.getLogo(dataPaperTickets);

    return(
      <PaperTicketHeader
        address={shopCode+"-"+shopName}
        business={corporateName}
        cif={cif}
        code={shopCode}
        image={logo}
        phone={phone}
        document={document}
        seller={seller}
        date={date}
        hours={hours}
      />
    )
  }

  renderItemsTicket() {
    const { dataPaperTickets } = this.props;
    const items = PaperTicketManager.getItems(dataPaperTickets);
    const itemCount = PaperTicketManager.getItemCount(dataPaperTickets);
    const grandTotal = PaperTicketManager.getGrandTotal(dataPaperTickets);
    const exchangeAmount = PaperTicketManager.getExchangeAmount(dataPaperTickets);
    const paymentMethods = PaperTicketManager.getPaymentMethods(dataPaperTickets);
    const subtotal = PaperTicketManager.getSubtotal(dataPaperTickets);
    const discountAmount = PaperTicketManager.getDiscountAmount(dataPaperTickets);
    const giftCard = PaperTicketManager.getGiftCard(dataPaperTickets);
    const shippingDescription = PaperTicketManager.getShippingDescription(dataPaperTickets);
    const shippingAmount = PaperTicketManager.getShippingAmount(dataPaperTickets);

    return(
      <PaperTicketItem
        items={items}
        itemCount={itemCount}
        grandTotal={grandTotal}
        exchangeAmount={exchangeAmount}
        paymentMethods={paymentMethods}
        subtotal={subtotal}
        discountAmount={discountAmount}
        giftCard={giftCard}
        shippingDescription={shippingDescription}
        shippingAmount={shippingAmount}
      />
    )
  }

  renderInformation() {
    const { dataPaperTickets, idCardUserSelect, transactionsItemSelect, isLoadingTicketDownload } = this.props;

    // const centerId = CardsManager.getCenterId(transactionsItemSelect);
    const date = getMomentDateFormat(CardsManager.getTransactionDate(transactionsItemSelect), 'LL');
    const center = PaperTicketManager.getShopName(dataPaperTickets);

    return (
      <section className="container wrapper wrapper--content pt-5 pb-5">
        <div className="row mb-4">
          <div className="col-12 col-md-11 ml-5 pl-5 text-left ">
            <p className="mb-5">{strings("paperTicket.ticketIssuedAfterPayment")}<span
              className="text-black">{idCardUserSelect}</span>{strings("paperTicket.carriedOutCenter")}
              <span className="text-black">{center}</span>{strings("paperTicket.ofDay")}
              <span className="text-black">{date}</span>
            </p>
            <Link onClick={() => this._downloadPDF()}
              className="btn-secundary" style={{ textDecoration: 'none', cursor: 'pointer' }}>
              {isLoadingTicketDownload ? <Loading color={'#77bd1e'} /> :
                <>
                  {strings("paperTicket.downloadTicket")}
                  <span className="material-icons p-1">download</span>
                </>}
            </Link>
          </div>
        </div>
      </section>
    )
  }


  renderInformationTicket () {
      const { dataPaperTickets } = this.props;
      const afterPayText = PaperTicketManager.getAfterPayText(dataPaperTickets);
      const merchantType = PaperTicketManager.getMerchantType(dataPaperTickets);
      const fillText = PaperTicketManager.getBillText(dataPaperTickets);

      return(
        <PaperTicketDetailsInformation
          afterPayText={afterPayText}
          merchantType={merchantType}
          fillText={fillText}
        />
      )
    }

    renderPaymentDetails () {
      const { dataPaperTickets } = this.props;
      const paymentDetailText = PaperTicketManager.getPaymentDetailText(dataPaperTickets);

      if (paymentDetailText) {
        return (
          <PaperTicketPaymentDetails paymentDetailText={paymentDetailText}/>
        )
      }

      return (<div />)

    }

    renderInformationPromotions () {
      const { dataPaperTickets, qrAccessToken } = this.props;
      const bpText = PaperTicketManager.getBpData(dataPaperTickets);
      const documentText = PaperTicketManager.getDocumentText(dataPaperTickets).toUpperCase();
      const promotions = PaperTicketManager.getAppliedPromotions(dataPaperTickets);
      const ticketQr = PaperTicketManager.getTicketQr(dataPaperTickets);

      return(
        <PaperTicketPromotion
          bpText={bpText}
          documentText={documentText}
          promotions={promotions}
          image={ticketQr}
          qrAccessToken={qrAccessToken}
        />
      )
    }

  headerContainer() {
    const { idCardUserSelect } = this.props;
    const breadcrumbList = [
      {name: strings('viewName.home'),path: Routing.app},
      {name: strings('viewName.cardList'),path: Routing.cardList},
      {name: strings('viewName.cardDetail'), path: idCardUserSelect ? '/tarjetas-compra/'+idCardUserSelect : Routing.cardList},
      {name: strings('viewName.digitalTicket'),path: '#'}
    ]

    return (
      <HeaderContainer title={strings('viewName.digitalTicket')} breadcrumbList={breadcrumbList} />
    )
  }


  /** PRIVATE METHODS **/
  _apiGetPaperTicket = () => {
    const {idParams} = this.props;

    if (idParams.includes('-')){
      return this.props.apiGetPaperTicket(idParams);
    }

    this.props.apiGetPaperTicketOnline(idParams);
  };

  _clearDataPaperTicket = () => {
    this.props.clearDataPaperTicket();
  };

  async _downloadPDF() {
    this.props.setPaperTicketState('isLoadingTicketDownload', true)
    const { idParams } = this.props;
    const paperTicket = document.getElementById('paperTicket')

    const positionInfo = paperTicket.getBoundingClientRect();
    const height = positionInfo.height;
    const width = positionInfo.width;

    await html2canvas(paperTicket, { width, height })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF({ orientation: 'p', unit: 'px', format: [width + 10, height + 10] });
        pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        pdf.save(idParams);
      })
    this.props.setPaperTicketState('isLoadingTicketDownload', false)
  }
}

const mapStateToProps = ({ CardTransactionsReducer, PaperTicketReducer }) => {
  const { dataPaperTickets, isLoadingPaperTicket, isLoadingTicketDownload } = PaperTicketReducer;
  const { idCardUserSelect, transactionsItemSelect } = CardTransactionsReducer;

  return {
    dataPaperTickets, isLoadingPaperTicket, isLoadingTicketDownload,
    idCardUserSelect, transactionsItemSelect
  };
};

const mapStateToPropsAction = {
  apiGetPaperTicket,
  apiGetPaperTicketOnline,
  clearDataPaperTicket,
  setPaperTicketState
};

export default connect(mapStateToProps, mapStateToPropsAction)(PaperTicketContainer);
