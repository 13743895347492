export default class Types {
  /** CLEAR **/
  static CLEAR_DATA_CREATE_USER = 'CLEAR_DATA_CREATE_USER';

  /** POST CREATE_USER **/
  static CREATE_USER_FAILED      = 'CREATE_USER_FAILED';
  static CREATE_USER_SUCCESS     = 'CREATE_USER_SUCCESS';

  /** UPDATE STATE **/
  static SET_CREATE_USER_STATE   = 'SET_CREATE_USER_STATE';
}
