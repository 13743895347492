import BackOfficeManager, {TypeUsers} from "../backOffice/BackOfficeManager";
export default class UserManager {

  static getUserAttributes(user) {
    return user && user.attributes ? user.attributes : null
  }

  static getUserEmail(user) {
    return user && user.attributes.email ? user.attributes.email : null
  }

  static getUserFamilyName(user) {
    return user && user.attributes && user.attributes.family_name ? user.attributes.family_name : ''
  }

  static getUserFullName(user) {
    return user && user.attributes && user.attributes.name ? user.attributes.name : null
  }

  static getUserId(user) {
    return user && user.Username ? user.Username : null
  }

  static getUserOrgName(user, orgData) {
    let orgName = '';
    if (user && user.attributes && user.attributes.family_name && orgData) {

      orgData.filter(org => {
        if (org.id_sap === user.attributes.family_name) {
          orgName = org.name
        }
      })
    }
    return user && user.attributes && user.attributes.family_name ?
      orgName : null;
  }

  static getUserPhone(user) {
    let phone = ''
    if (user && user.attributes.phone_number) {
      phone = user.attributes.phone_number.replace('+34', '')
    }
    return user && user.attributes.phone_number ? phone : null;
  }


  static getUserProfile(user) {
    return user && user.attributes && user.attributes.profile ? user.attributes.profile : TypeUsers.ASSISTANT
  }
}
