import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import { apiGetDashboardInfo, clearDashboardInfo, setDashboardInfoDataState } from './DashboardActions.js'
import { apiGetNotificationsInfo } from '../notifications/NotificationsActions'
import { apiGetBackOfficeOrgInfo } from '../backOffice/BackOfficeActions'
import { setNewPasswordState } from '../auth/newPassword/NewPasswordActions'

// Components
import Chart from 'chart.js';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { OnboardingContainer } from 'src/components/OnboardingContainer'

// Resources
import DashboardManager from './DashboardManager.js'
import { formatToNumberWithCommas, formatToNumberWithCommasINT, getActualMonth, getMomentNow, getMomentYearAgo, parseDecimal } from '../../utils/Utils'
import Routing from '../routing/Routing.jsx';
import { strings } from '../../resources/locales/i18n.js';

class DashboardContainer extends Component {

  componentDidMount() {
    this._apiGetBackOfficeOrgInfo();
    this._apiGetDashboardInfo();
    this._apiGetNotificationsInfo();
  }

  componentDidUpdate(prevProps) {
    const { dashboardData, isLoadingDashboardInfo } = this.props;

    if (prevProps.dashboardData !== dashboardData && isLoadingDashboardInfo){
      setTimeout(() => this._setDataGraphics(), 300)
    }

  }

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
    ]
    const { isFirstTime, isLoadingDashboardInfo } = this.props;

    return (
      <>
        {isFirstTime ?
          <OnboardingContainer markAsSeen={() => this.props.setNewPasswordState({ prop: 'isFirstTime', value: false })} />
          :
          <div />
        }
        <HeaderContainer title={strings('viewName.dashboard')} breadcrumbList={breadcrumbList} />

        <section className="container wrapper wrapper--content pt-5 pb-5">
          {isLoadingDashboardInfo ?
            <div className={'d-flex justify-content-center align-items-center'} style={{ height: 300 }}>
              <Loading color={'#006138'} height={50} width={50} />
            </div> :
            <div>
              {this.renderResumeCards()}
              {this.renderGraphics()}
              {this.renderCardStatisticsTable()}
            </div>
          }
        </section>
      </>
    );
  }

  renderBodyTable() {
    const { dashboardData } = this.props;

    if (!dashboardData) {
      return <div />
    }

    const { cardStatistics } = dashboardData;
    if (!cardStatistics) {
      return <div />
    }

    return (
      cardStatistics.map((item) => {
        const status = DashboardManager.getStatus(item)
        const cardQuantityFormatted = formatToNumberWithCommas(item.quantity);
        return (
          <tr key={item.status}>
            <th className="align-middle">{status}</th>
            <td className="align-middle">{cardQuantityFormatted}</td>
            <td className="align-middle">
              {/* <a className="btn-out disabled">{strings('dashboardView.tableCardsStatus.buttonSeeMore')}</a> */}
            </td>
          </tr>
        )
      })
    )
  }

  renderGraphics() {
    const { dashboardData, isLoadingDashboardInfo } = this.props;

    if (!dashboardData) {
      return <div />
    }

    const { historyBalance } = dashboardData;
    const currentMonthData = DashboardManager.getCurrentMonthData(historyBalance);
    const monthName = DashboardManager.getMonthName(currentMonthData, true)

    return (
        <div className="row align-items-stretch mb-0 mb-md-4">
          <div className="col-12 col-md-7 mb-4 mb-md-0">
            <div className="card">
              <div className="card--hero p-3">
                <h3 className="title-mid">{strings('dashboardView.graphics.historial')}</h3>
              </div>
              <div className="card--content pb-4 px-1 px-lg-5">
                {isLoadingDashboardInfo ?
                  <div className={'d-flex justify-content-center align-items-center'} style={{ height: 100 }}>
                    <Loading color={'#006138'} height={35} width={35} />
                  </div> :
                  <canvas id="barsChart" />
                }
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 mb-4 mb-md-0">
            <div className="card">
              <div className="card--hero p-3">
                <h3 className="title-mid">{strings('dashboardView.graphics.balanceOf')} {monthName || getActualMonth()}</h3>
              </div>
              <div className="card--content pt-1 pb-4 px-lg-4">
                {isLoadingDashboardInfo ?
                  <div className={'d-flex justify-content-center align-items-center'} style={{ height: 100 }}>
                    <Loading color={'#006138'} height={35} width={35} />
                  </div> :
                  <canvas id="doughnutChart" />
                }
              </div>
            </div>
          </div>
        </div>
    )
  }

  renderCardStatisticsTable() {
    const { dashboardData } = this.props;

    if (!dashboardData) {
      return <div />
    }


    return (
      <div className="row module-table">
        <div className="col-12 mb-md-0">
          <div className="card p-0">
            <div className="card--hero p-3 d-flex justify-content-between align-items-center">
              <h3 className="title-mid">{strings('dashboardView.tableCardsStatus.title')}</h3>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                <tr>
                  <th>{strings('dashboardView.tableCardsStatus.cards')}</th>
                  <th>{strings('dashboardView.tableCardsStatus.quantity')}</th>
                </tr>
                </thead>
                <tbody>
                {this.renderBodyTable()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderResumeCards() {
    const { error, dashboardData } = this.props;
    const currentDate = getMomentNow('L');
    const yearAgoDate = getMomentYearAgo('L');
    if (!dashboardData) {

      return <div className="row module-table">
        <div className="col-12 mb-md-0">
          <div className="card p-5 text-center">
            <h3 className="title-mid">{error}</h3>
          </div>
        </div>
      </div>
    }

    const { activeCards, emittedQuantity, redeemedQuantity, totalCards } = dashboardData;
    let activeCardsFormatted = parseDecimal(activeCards, 0);
    activeCardsFormatted = formatToNumberWithCommas(activeCardsFormatted);
    let emittedQuantityFormatted = parseDecimal(emittedQuantity);
    emittedQuantityFormatted = formatToNumberWithCommas(emittedQuantityFormatted);
    let redeemedQuantityFormatted = parseDecimal(redeemedQuantity);
    redeemedQuantityFormatted = formatToNumberWithCommas(redeemedQuantityFormatted);
    let totalCardsFormatted = parseDecimal(totalCards, 0);
    totalCardsFormatted = formatToNumberWithCommas(totalCardsFormatted);

    return (
      <>
        <div className="input-bottom pb-2 mb-3">
          <p className="m-0 font-italic">{strings('dashboardView.period')} {yearAgoDate} - {currentDate}</p>
        </div>
        <div className="row align-items-stretch mb-0 mb-md-4" >
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            {/* CARDS */}
            <div className="card p-4 d-flex justify-content-between">
              <div className="input-top d-flex justify-content-between align-items-start mb-4">
                <div className="input-top--left d-flex flex-column">
                  <span className="title-mid">{strings('dashboardView.cards.card.totalCards')}</span>
                  <span className="title-result">{totalCardsFormatted || 0}</span>
                </div>
                <div className="input-top--right">
                  <span className="material-icons">payment</span>
                </div>
              </div>
              <div className="input-top d-flex justify-content-between align-items-start">
                <div className="input-top--left d-flex flex-column">
                  <span className="title-mid">{strings('dashboardView.cards.card.activeCards')}</span>
                  <span className="title-result">{activeCardsFormatted || 0}</span>
                </div>
                <div className="input-top--right">
                  <span className="material-icons">credit_score</span>
                </div>
              </div>
              <div className="input-bottom pt-2 mt-4">
                <Link to={Routing.cardList} className="m-0" style={{
                  fontSize: 14, fontStyle: 'italic',
                }}>{strings('dashboardView.cards.cardsManagement')}</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 mb-4 mb-md-0">
            {/* style={{ background: 'white', borderRadius: 10, flexDirection: 'column' }} */}
            <div className='card d-flex flex-column'>

              <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-lg-5 pb-lg-1 mb-1">

                {/* EMITTED */}
                <div className="col-12 col-lg-5 card p-4 d-flex justify-content-between">
                  <div className="input-top d-flex justify-content-between align-items-start">
                    <div className="input-top--left d-flex flex-column">
                      <span className="title-mid">{strings('dashboardView.cards.euro.emittedQuantity')}</span>
                      <span className="title-result">{emittedQuantityFormatted || 0} €</span>
                    </div>
                    <div className="input-top--right">
                      <span className="material-icons">card_giftcard</span>
                    </div>
                  </div>
                </div>

                {/* REDEEMED */}
                <div className="col-12 col-lg-5 card p-4 d-flex justify-content-between">
                  <div className="input-top d-flex justify-content-between align-items-start">
                    <div className="input-top--left d-flex flex-column">
                      <span className="title-mid">{strings('dashboardView.cards.euro.redeemedQuantity')}</span>
                      <span className="title-result">{redeemedQuantityFormatted || 0} €</span>
                    </div>
                    <div className="input-top--right">
                      <span className="material-icons">shopping_basket</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* DIVIDER */}
              <div className="input-bottom m-4 pt-2" style={{ borderTop: '1px solid rgba(195, 195, 195, 0.3)' }}>
                <Link to={Routing.documents} className="m-0" style={{
                  fontSize: 14, fontStyle: 'italic',
                }} >{strings('dashboardView.cards.downloadBills')}</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  /* PRIVATE METHOD */

  _apiGetBackOfficeOrgInfo() {
    this.props.apiGetBackOfficeOrgInfo();
  }

  _apiGetDashboardInfo() {
    this.props.apiGetDashboardInfo().then(e => this._setDataGraphics());
  }

  _apiGetNotificationsInfo() {
    this.props.apiGetNotificationsInfo();
  }

  _setDataGraphics() {
    const { dashboardData } = this.props;
    if (!dashboardData) {
      return <div />
    }

    const { historyBalance } = dashboardData;
    const monthsLabelData = DashboardManager.getHistoryMonths(historyBalance);
    const spendingData = DashboardManager.getHistorySpending(historyBalance);
    const emittedQuantityData = DashboardManager.getHistoryEmitted(historyBalance);

    const currentEmittedQuantity = DashboardManager.getCurrentEmittedQuantity(historyBalance);
    const currentSpending = DashboardManager.getCurrentSpending(historyBalance);

    let barsChartId = document.getElementById('barsChart');

    console.log("barsChartId", barsChartId)
    let doughnutChartId = document.getElementById('doughnutChart');

    const barsDataset = {
      labels: monthsLabelData,
      datasets: [{
        label: strings('dashboardView.graphics.emitted'),
        backgroundColor: 'rgb(255, 99, 132,1)',
        stack: 'Stack Saldo',
        data: emittedQuantityData
      }, {
        label: strings('dashboardView.graphics.redeemed'),
        backgroundColor: 'rgb(255, 159, 64,1)',
        stack: 'Stack Gasto',
        data: spendingData
      }]
    };

    const doughnutDataset = {
      datasets: [{
        label: 'Dataset 1',
        backgroundColor: [
          'rgb(255, 99, 132,1)',
          'rgb(255, 159, 64,1)',
        ],
        data: [currentEmittedQuantity, currentSpending]
      }],
      labels: [strings('dashboardView.graphics.emitted'), strings('dashboardView.graphics.redeemed')]
    };


    if (barsChartId){
      const barsChart = new Chart(barsChartId,
        {
          type: 'bar',
          data: barsDataset,
          options: {
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return formatToNumberWithCommas(tooltipItem.yLabel);
                }
              }
            },
            maintainAspectRatio: true,
            responsive: true,
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: ''
            },
            animation: {
              animateScale: true,
            },
            scales: {
              xAxes: [{
                stacked: true
              }],
              yAxes: [{
                stacked: false,
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          }
        }
      );
    }

    if (doughnutChartId){
      const doughnutChart = new Chart(doughnutChartId, {
        type: 'doughnut',
        data: doughnutDataset,
        options: {
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return formatToNumberWithCommas(data.datasets[0].data[tooltipItem.index])
              }
            }
          },
          maintainAspectRatio: true,
          responsive: true,
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: ''
          },
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      });
    }

  }
}


const mapStateToProps = ({ DashboardReducer, NewPasswordReducer }) => {
  const {
    error,
    dashboardData,
    isLoadingDashboardInfo,
  } = DashboardReducer;
  const { isFirstTime, } = NewPasswordReducer;

  return {
    error,
    dashboardData,
    isFirstTime,
    isLoadingDashboardInfo,
  }
}

const mapStateToPropsAction = {
  apiGetBackOfficeOrgInfo, apiGetDashboardInfo, apiGetNotificationsInfo, clearDashboardInfo, setDashboardInfoDataState, setNewPasswordState
};
export default connect(mapStateToProps, mapStateToPropsAction)(DashboardContainer);
