
export default class Types {


  /** CLEAR USER DATA **/
  static CLEAR_DATA_USER       = 'CLEAR_DATA_USER'

  /** CLEAR USER PASSWORDS DATA **/
  static CLEAR_PASSWORDS_USER  = 'CLEAR_PASSWORDS_USER'

  /** EDIT USER PASSWORD **/
  static EDIT_PASSWORD_FAILED  = 'EDIT_PASSWORD_FAILED'

  /** USER ACCESS TOKEN **/
  static SET_USER_ACCESS_TOKEN = 'SET_USER_ACCESS_TOKEN';

  /** USER DATA **/
  static SET_USER_DATA         = 'SET_USER_DATA';
  static SET_USER_DATA_PROPS   = 'SET_USER_DATA_PROPS';

  /** USER LOGOUT **/
  static SET_USER_LOGOUT       = 'SET_USER_LOGOUT';
}
