export default class Types {

  /** CLEAR DATA TRANSACTIONS **/
  static CLEAR_DATA_CARD_TRANSACTIONS = 'CLEAR_DATA_CARD_TRANSACTIONS';

  /** GET CARDS TRANSACTIONS **/
  static GET_CARD_TRANSACTIONS_FAILED = 'GET_CARD_TRANSACTIONS_FAILED';
  static GET_CARD_TRANSACTIONS_SUCCESS = 'GET_CARD_TRANSACTIONS_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_CARD_TRANSACTIONS = 'SET_DATA_CARD_TRANSACTIONS';

}
