
export default class Types {
  /** CLEAR HOMES **/
  static CLEAR_DATA_LOGIN  = 'CLEAR_DATA_LOGIN';
  static CLEAR_CODE_LOGIN  = 'CLEAR_CODE_LOGIN';

  /** POST LOGIN **/
  static LOGIN_FAILED      = 'LOGIN_FAILED';
  static LOGIN_START       = 'LOGIN_START';
  static LOGIN_SUCCESS     = 'LOGIN_SUCCESS';

  /** UPDATE STATE **/
  static SET_LOGIN_STATE   = 'SET_LOGIN_STATE';
}
