import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import {
  apiGetCognitoUser,
  apiPostLoginWithCode,
  clearCodeLogin,
  clearDataLogin,
  setLoginState,
  validateOnBlurLogin
} from './LoginActions';

// Components
import { AlertContainer } from 'src/components';
import { Loading } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import { localAssets } from '../../../resources/assets/assets.js';
import { strings } from '../../../resources/locales/i18n.js';
import Routing from '../../routing/Routing';


class LoginContainer extends Component {

  componentDidMount() {
    this._clearDataLogin();
  }

  render() {
    const { error, isLoadingGetUser, logoutMessage, password, username } = this.props;

    return (
      <>
        <AlertContainer
          message={logoutMessage}
          className={'alert-success position-absolute col-12 col-md-6 text-center '}
          style={{ zIndex: 4, opacity: 0.85 }}
        />
        <section className="container-fluid wrapper login" >
          <div className="row h-100">
            <div className="col-12 col-md-6 login-form d-flex align-items-start justify-content-center py-5">
              <div className="login-form--wrapper d-flex flex-column align-items-start px-4">
                <img src={localAssets.imgLogoHiperdino.default} alt="logo de Hiperdino" className="mb-4" />
                <div className="login-form--wrapper d-flex align-items-center ">
                  <span className="title-big mb-4">
                    {strings('global.title')} {process.env.REACT_APP_IS_DEV}
                  </span>
                </div>
                {this.renderCodeModal()}
                <form id="loginForm" onSubmit={(e) => this._apiGetCognitoUser(e)} action="" className="w-100 mt-5">
                  <span className="title-mid d-block mb-4">{strings('login.formTitle')}</span>

                  <AlertContainer
                    error={error}
                    id={'request'}
                    className={'alert-danger'}
                  />

                  <TextInput
                    title={strings('login.userInput')}
                    id="username"
                    name="username"
                    type="text"
                    error={error}
                    placeholder=""
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={username}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                  />

                  <TextInput
                    title={strings('login.passwordInput')}
                    id="password"
                    name="password"
                    type="password"
                    error={error}
                    placeholder=""
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={password}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                  />

                  <div className="form-group mb-4">
                    <Link to={Routing.rememberPassword}>{strings('button.forgotPassword')}</Link>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn-primary w-100">
                      {isLoadingGetUser ? <Loading /> : strings('button.continue')}
                    </button>
                  </div>

                </form>

                <div className="new-add">
                  <span className="title-mid d-block mb-4">
                    {strings('login.organizationRegister')}
                  </span>
                  <p>
                    {strings('login.organizationDescription')}
                  </p>
                  <Link to={Routing.contact} className="btn-secundary w-75">
                    {strings('login.contactButton')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 login-img" />
          </div>
        </section >
      </>

    );
  }

  renderCodeModal() {
    const { error, isLoadingLogin } = this.props;
    const { code1, code2, code3, code4, code5, code6 } = this.props;
    let hasError = false;
    let hasErrorRequest = false;
    let elementError = '';

    if (error) {
      error.map((item) => {
        if (item.key === 'code') {
          hasError = true;
          return elementError = item;
        }
        if (item.key === 'request') {
          hasError = true;
          return elementError = item;
        }
      });
    }

    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="codeModal">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ opacity: 1 }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this._clearCodeLogin() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form id="codeForm" onSubmit={(e) => { this._createWholeCode(); this._apiPostLoginWithCode(e) }} action="">

                <h5 className="title-mid text-center p-1" id="exampleModalLabel">{strings('login.enterYourCode')}</h5>
                <div className="modal-body d-flex justify-content-center row">
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code1"
                    maxLength="1"
                    name="code-1"
                    onChange={(e) => { this._handleInputChange(e) }}
                    placeholder=""
                    value={code1}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code2"
                    maxLength="1"
                    name="code-2"
                    onChange={(e) => { this._handleInputChange(e) }}
                    placeholder=""
                    value={code2}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code3"
                    maxLength="1"
                    name="code-3"
                    onChange={(e) => { this._handleInputChange(e) }}
                    placeholder=""
                    value={code3}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code4"
                    maxLength="1"
                    name="code-4"
                    onChange={(e) => { this._handleInputChange(e) }}
                    placeholder=""
                    value={code4}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code5"
                    maxLength="1"
                    name="code-5"
                    onChange={(e) => { this._handleInputChange(e) }}
                    placeholder=""
                    value={code5}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code6"
                    maxLength="1"
                    name="code-6"
                    onChange={(e) => { this._handleInputChange(e) }}
                    placeholder=""
                    value={code6}
                  />
                </div>
                {hasError && elementError ? <div style={{ fontSize: "12px", color: "#DE4150", textAlign: 'center' }}>{elementError.value}</div> : null}

                <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
                  <button type="submit" className="btn btn-primary mb-3">
                    {isLoadingLogin ? <Loading /> : strings('button.continue')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{ opacity: .9, zIndex: -1 }} />
      </div>
    )
  }

  /* PRIVATE METHOD */
  _apiGetCognitoUser(e) {
    e.preventDefault();
    this.props.apiGetCognitoUser(() => this._handleViewChange(), () => this._handleViewChangeToConfirmAccount(), this._handleViewChangeToCreateNewPassword, () => this._toggleModal(), this._handleViewChangeToRememberPassword)
  }

  _apiPostLoginWithCode(e) {
    e.preventDefault();
    this.props.apiPostLoginWithCode(() => this._handleViewChange());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearCodeLogin() {
    this.props.clearCodeLogin();
  }

  _clearDataLogin() {
    this.props.clearDataLogin();
  }

  _createWholeCode() {
    const { code1, code2, code3, code4, code5, code6 } = this.props;
    const fullCode = code1 + code2 + code3 + code4 + code5 + code6;
    this._handleValueChange('code', fullCode)
  }

  _focusNextInput(e) {
    const { maxLength, name, value } = e.target;
    const [fieldName, fieldIndex] = name.split('-');
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextInput = document.querySelector(`input[name=code-${parseInt(fieldIndex, 10) + 1}]`)
        if (nextInput !== null) {
          nextInput.focus();
        }
      }
    }
  }

  _handleInputChange(e) {
    this._setMaxInputNumber(e);
    this._focusNextInput(e);
    this._handleValueChange(e.target.id, e.target.value)
  };

  _handleValueChange = (prop, value) => {
    this.props.setLoginState({ prop, value });
  };

  _handleViewChange = () => {
    const { history, location } = this.props;
    const { search } = location;

    const searchQuery = '?' + search.substring(search.indexOf('&') + 1, search.length)
    const route = search.includes('redirect=facturas') ? `${Routing.documents}${searchQuery}` : Routing.dashboard;

    history.push(route);
  };

  _handleViewChangeToConfirmAccount = () => {
    const { history } = this.props;
    history.push(Routing.confirmation);
  };

  _handleViewChangeToCreateNewPassword = () => {
    const { history } = this.props;
    history.push(Routing.newPassword);
  };

  _handleViewChangeToRememberPassword = () => {
    const { history } = this.props;
    history.push(Routing.rememberPassword);
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurLogin(inputType)
  }

  _setMaxInputNumber(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  _toggleModal() {
    const codeModal = document.getElementById('codeModal');
    if (codeModal.classList.contains('invisible')) {
      codeModal.classList.remove('invisible')
    } else {
      codeModal.classList.add('invisible')
    }
  }
}


const mapStateToProps = ({ LoginReducer }) => {
  const {
    code1, code2, code3, code4, code5, code6, cognitoUser,
    error, isLoadingGetUser, isLoadingLogin, logoutMessage, password, username
  } = LoginReducer;

  return {
    code1, code2, code3, code4, code5, code6, cognitoUser,
    error, isLoadingGetUser, isLoadingLogin, logoutMessage, password, username
  }
}


const mapStateToPropsAction = {
  apiGetCognitoUser,
  apiPostLoginWithCode,
  clearCodeLogin,
  clearDataLogin,
  setLoginState,
  validateOnBlurLogin,
};

export default connect(mapStateToProps, mapStateToPropsAction)(LoginContainer);
