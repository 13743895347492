
/** CLEAR PAPER TICKET **/
export const CLEAR_PAPER_TICKET = 'CLEAR_PAPER_TICKET';

/** PAPER TICKET **/
export const GET_PAPER_TICKET_FAILED  = 'GET_PAPER_TICKET_FAILED';
export const GET_PAPER_TICKET_SUCCESS = 'GET_PAPER_TICKET_SUCCESS';

/** PAPER TICKET ONLINE **/
export const GET_PAPER_TICKET_ONLINE_FAILED  = 'GET_PAPER_TICKET_ONLINE_FAILED';
export const GET_PAPER_TICKET_ONLINE_SUCCESS = 'GET_PAPER_TICKET_ONLINE_SUCCESS';

/** UPDATE STATE **/
export const SET_DATA_PAPER_TICKET = 'SET_DATA_PAPER_TICKET';

