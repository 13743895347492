import React, { Component } from 'react';
import {strings} from '../resources/locales/i18n'

const SelectorContainer = (props) => {
  const { id, changeCurrentLimit, apiGetData, resultsQuantity, setDataProps, totalResults } = props;
  const FONT_SIZE = {
    fontSize: 14,
    color: '#777'
  }

  if (totalResults <= 25) {
    return <div />
  }

  return (
    <div className='col-4 py-3'>
      <span style={FONT_SIZE}>{strings('selectorComponent.showing')} </span>
      <select
        style={FONT_SIZE}
        name="paginator"
        id="showingResults"
        style={{ width: 60, height: 30, ...FONT_SIZE }}
        className='mx-2'
        id={id}
        value={resultsQuantity}
        onChange={(e) => { changeCurrentLimit(setDataProps, e.target.value, e.target.id); setDataProps({ prop: id + 'CurrentPage', value: 1 }); apiGetData(); }}
      >
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="75">75</option>
      </select>
      <span style={FONT_SIZE}> {strings('selectorComponent.results')}</span>
    </div>
  )
};

export { SelectorContainer }


