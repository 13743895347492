import Types from "./Types";

const INITIAL_STATE = {
	cardTransactions: null,
  idCardUserSelect: null,
  isLoadingGetTransactions: false,
  isLoadingPostTranslationDownload: false,
  transactionsItemSelect: null,
	cardsTransactionsCurrentPage: 1,
  cardsTransactionsLimit: 25,
  pagesNum: '',
};

export default (state = INITIAL_STATE, action) => {

	switch (action.type) {

    /** CLEAR CARD **/
    case Types.CLEAR_DATA_CARD_TRANSACTIONS:
      return { ...INITIAL_STATE };

		/** GET CARD TRANSACTIONS**/
		case Types.GET_CARD_TRANSACTIONS_FAILED:
			return { ...state, error: action.payload };

		case Types.GET_CARD_TRANSACTIONS_SUCCESS:
			return {
				...state,
				cardTransactions: action.payload.data,
        pagesNum: action.payload.numPages,
				totalResults: action.payload.totalResults
			};

    /** UPDATE STATE **/
    case Types.SET_DATA_CARD_TRANSACTIONS:
      return { ...state, [action.payload.prop]: action.payload.value };

		default:
			return state;
	}
}
