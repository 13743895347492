
export default class Types {
  /** CLEAR HOMES **/
  static CLEAR_DATA_CONFIRMATION  = 'CLEAR_DATA_CONFIRMATION';

  /** POST CONFIRMATION **/
  static CONFIRMATION_FAILED      = 'CONFIRMATION_FAILED';
  static CONFIRMATION_SUCCESS     = 'CONFIRMATION_SUCCESS';

  /** UPDATE STATE **/
  static SET_CONFIRMATION_STATE   = 'SET_CONFIRMATION_STATE';
}
