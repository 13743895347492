import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import { apiPostSearch, clearDataCards, setCardsDataState } from './CardsActions';

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import CardsFormContainer from './CardsFormContainer.jsx';

// Resources
import CardsManager from './CardsManager';
import { strings } from '../../resources/locales/i18n';
import Routing from '../routing/Routing';

class CardsContainer extends Component {

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.cardList'), path: '#' }
    ]
    return (
      <>
        <HeaderContainer title={strings('viewName.cardList')} breadcrumbList={breadcrumbList} />
        {this.renderFormCard()}
        {this.renderResultsText()}
        {this.renderListCard()}
      </>
    )
  }

  renderFormCard() {

    return (
      <>
        <section className="container wrapper wrapper--hero pt-5 pb-5">
          <div className="row">
            <div className="col-12">
              <CardsFormContainer />
            </div>
          </div>
        </section>
      </>
    )
  }


  renderListCard() {
    const { dataCards } = this.props;

    if (!dataCards || dataCards.length === 0) {
      return <div />
    }

    return (
      <div id='tableResult'>
        <section className="container wrapper wrapper--content pt-4 pb-5">
          <div className="row module-table">
            <div className="col-12 mb-md-0">
              <div className="card p-0">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{strings('title.card')}</th>
                        <th>{strings('title.initialBalance')}</th>
                        <th>{strings('title.balance')}</th>
                        <th>{strings('title.expense')}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderItemCard()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  renderItemCard() {
    const { dataCards } = this.props
    if (!dataCards) {
      return <div />
    }
    return (
      dataCards.map((item) => {
        const uuid = CardsManager.getUuid(item);
        const balance = CardsManager.getBalance(item);
        const initialBalance = CardsManager.getBalanceInitial(item);
        const totalExpense = CardsManager.getTotalExpense(item);
        return (
          <tr key={uuid}>
            <th className="align-middle">{uuid}</th>
            <td className="align-middle">{initialBalance}</td>
            <td className="align-middle">{balance}</td>
            <td className="align-middle">{totalExpense}</td>
            <td className="align-middle">
              <Link to={`/tarjetas-compra/${uuid}`} className="btn-out">{strings('button.consult')}</Link>
            </td>
          </tr>
        )
      })
    )
  }

  renderResultsText() {
    const { dataCards, totalResults } = this.props
    const resultsMaxLimit = 500;

    if (!dataCards) {
      return <div />
    }

    if (dataCards && totalResults > resultsMaxLimit) {
      return (
        <div id="tableTitle" className="container wrapper wrapper--content">
          <div className="title-mid py-4">{strings('purchaseCardResults.globalSearch')}</div>
          <div className="title-big mt-4">{strings('purchaseCardResults.showingGlobalResults', {results: totalResults - 1})}</div>
        </div>
      )
    }

    if (dataCards && dataCards.length === 0) {
      return (
        <div className="container wrapper wrapper--content">
          <div className="title-big mb-4 py-5">{strings('purchaseCardResults.noResults')}</div>
        </div>
      )
    } else {
      return (
        <div className="container wrapper wrapper--content">
          <div className="title-big mt-5">
            {strings('purchaseCardResults.showingResults', { results: totalResults })}</div>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ CardsReducer }) => {
  const { cardsCurrentPage, cardsLimit, dataCards, pagesNum, totalResults } = CardsReducer;
  return {
    cardsCurrentPage, cardsLimit, dataCards, pagesNum, totalResults
  };
};
const mapStateToPropsAction = {
  apiPostSearch,
  setCardsDataState
};

export default connect(mapStateToProps, mapStateToPropsAction)(CardsContainer);
