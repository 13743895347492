import React from 'react';
import PropTypes from 'prop-types';

// Resources
import {strings} from '../../../resources/locales/i18n';
import {localAssets} from '../../../resources/assets/assets';


const PaperTicketHeader = (prostyles) => {
  const { address, business, cif, image, phone, document, seller, date, hours, code  } = prostyles;
  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <div className={'d-flex justify-content-center align-items-center'} >
        <img src={localAssets.imgLogoTicket.default}/>
      </div>

      <div className={'d-flex flex-column justify-content-center align-items-center'}>
        {containerInfoHeader(business)}
        {containerInfoHeader(strings('paperTicket.cif'), cif)}
        {containerInfoHeader(address)}
        {containerInfoHeader(strings('paperTicket.phone'), phone)}
      </div>

     <div className={'d-flex mt-3 w-100'}>
        {containerInfo(strings('paperTicket.center'), code, 0.13)}
        {containerInfo(strings('paperTicket.seller'), seller, 0.15)}
        {containerInfo(strings('paperTicket.document'), document, 0.38)}
        {containerInfo(strings('paperTicket.date'), date, 0.2)}
        {containerInfo(strings('paperTicket.hours'), hours, 0.14, {justifyContent: 'flex-end', alignItems: 'flex-end'})}
      </div>
    </div>
  )
};

const containerInfo = (title, description, flex, style) => {
  return(
    <div className={'d-flex flex-column'} style={{flex: flex, ...style}}>
      <p className={'textPaperTicketTitle'}>{title}</p>
      <p className={'textPaperTicket'}>{description}</p>
    </div>
  )
};

const containerInfoHeader = (title, description) => {
  return(
    <div className={'d-flex'}>
      <p className={'textPaperTicket'}>{title}&nbsp;</p>
      <p className={'textPaperTicket'}>{description}</p>
    </div>
  )
};

PaperTicketHeader.propTypes = {
  address:     PropTypes.string,
  business:    PropTypes.string,
  cif:         PropTypes.string,
  image:       PropTypes.string,
  phone:       PropTypes.string,
  document:    PropTypes.string,
  seller:      PropTypes.string,
  date:        PropTypes.string,
  hours:       PropTypes.string,
  code:        PropTypes.string,
};

export default PaperTicketHeader
