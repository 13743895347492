export async function formatImageToSvg(html) {
  const t = jQuery(jQuery.parseHTML(html)[0]), n = t.attr("class"), i = t.attr("src");
  let svg ="";

  await jQuery.get(i, async function (i) {
    let o = jQuery(i).find("svg");
    o = o.attr("class", n + " replaced-svg")
    svg = o[0].outerHTML
  }, "xml")

  return svg
}
