
export default class LoginManager {

  static getUserTokenAmazon = (data) => {
    return data && data.signInUserSession && data.signInUserSession.idToken && data.signInUserSession.idToken.jwtToken ?
      data.signInUserSession.idToken.jwtToken : '';
  };

  static getUserRefreshToken = (data) => {
    return data && data.signInUserSession && data.signInUserSession.refreshToken && data.signInUserSession.refreshToken.token ?
      data.signInUserSession.refreshToken.token : '';
  };

}
