import Types from "./Types";

const INITIAL_STATE = {
  dateStart: '',
  dateEnd: '',
  dataDocuments: null,
  documentMaxQuantity: '',
  documentMinQuantity: '',
  documentQuantityType: '',
  documentSearchType: '',
  documentType: '',
  idReference: '',
  isLoadingPostDocuments: false,
  isLoadingPostDocumentsDownload: false,
  documentsTotalResults: null,
  documentsCurrentPage: 1,
  documentsLimit: 25,
  documentsPagesNum: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DOCUMENTS **/
    case Types.CLEAR_DATA_DOCUMENTS:
      return { ...INITIAL_STATE };

    /** POST DOCUMENTS **/
    case Types.POST_DOCUMENTS_FAILED:
      return { ...state, error: action.payload };

    case Types.POST_DOCUMENTS_SUCCESS:
      const dataDocuments = action.payload.items.filter(item =>  item.description[0] !== '9').map(item => { return {...item, isLoading: false} });
      const dataDocumentsLength = dataDocuments.length;

      return {
        ...state,
        dataDocuments: dataDocuments,
        documentsTotalResults: dataDocumentsLength,
        documentsPagesNum: action.payload.numPages,
      };

    /** UPDATE STATE DOCUMENTS **/
    case Types.SET_DATA_DOCUMENTS:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
