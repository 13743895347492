import * as Types from './Types';
import {parseStringVar} from "../../utils/Utils";

const INITIAL_STATE = {
  dataPaperTickets: null,
  isLoadingPaperTickets: false,
  isLoadingTicketDownload: false,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR PAPER TICKET **/
    case Types.CLEAR_PAPER_TICKET:
      return { ...INITIAL_STATE }

    /** PAPER TICKET **/
    case Types.GET_PAPER_TICKET_FAILED:
      return { ...state, dataPaperTickets: null };

    case Types.GET_PAPER_TICKET_SUCCESS:
      return { ...state, dataPaperTickets: action.payload };

      /** PAPER TICKET ONLINE **/
    case Types.GET_PAPER_TICKET_ONLINE_FAILED:
      return { ...state, dataPaperTickets: null };

    case Types.GET_PAPER_TICKET_ONLINE_SUCCESS:
      const data = { }
      const items = []

      Object.keys(action.payload).map((key) => {
        data[parseStringVar(key)] = action.payload[key]
      })


      action.payload['items'].map((item) => {
        const itemParse = {}
        Object.keys(item).map((keyItem) => {
          itemParse[parseStringVar(keyItem)] = item[keyItem]
        })
        items.push(itemParse);
      })

      return { ...state, dataPaperTickets: {...data, items} };

    /** UPDATE STATE **/
    case Types.SET_DATA_PAPER_TICKET:
      return { ...state, [action.payload.prop]: action.payload.value };


    default:
      return state;
  }
}
