import Types from "./Types";
import FormValidationManager from '../../utils/managers/FormValidationManager.js';
import { isDev } from '../../utils/Utils.js';
import { Auth } from 'aws-amplify';
import { strings } from "../../resources/locales/i18n";
import { postCreateUser } from '../../services/api/API'


// Services

export const apiCreateUser = (openSuccessModal) => async (dispatch, getState) => {
  const { admin, email, mfa, organization, phone, username, surnames } = getState().CreateUserReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formAdminCreateUser({ email, phone, username });
  if (error) {
    // Scroll to and focus first error input
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' })
      firstError.focus({ preventScroll: true })
    }
    return dispatch({
      type: Types.CREATE_USER_FAILED,
      payload: error
    });
  }
  dispatch(setSignupState({ prop: 'error', value: [] }));
  // -- 2. Request
  const fullName = username.trim() + ' ' + surnames.trim();
  const isAdminProfile = admin === 'true' ? 'Admin' : '';
  const booleanMFA = !!mfa; // Expression !! is a similar way as Boolean(value) to convert a value from string to boolean and It’s also the recommended method by Airbnb's JavaScript style guide

  const newUserInfo = {
    mfa: booleanMFA,
    attributes: {
      family_name: organization,
      email: email.trim(),
      locale: mfa,
      name: fullName.trim(),
      phone_number: `+34${phone}`,
      profile: admin,
      // other custom attributes
    },
    username: email.trim(),
  }
  dispatch(setSignupState({ prop: 'isLoadingGetCode', value: true }));
  await dispatch(postCreateUser(
    newUserInfo,
    (tag, response) => {
      if (isDev()) console.log('apiCreateUser - Error: ', response);
      dispatch({ type: Types.CREATE_USER_FAILED, payload: [{'request': response.error}] });
      dispatch(responseError(response));
    },
    (tag, response) => {
      if (isDev()) console.log('apiCreateUser - Success: ', response);
      dispatch(responseSuccess(response, openSuccessModal))
    }
  ));
  dispatch(setSignupState({ prop: 'isLoadingGetCode', value: false }));
};

export const clearDataSignup = () => ({
  type: Types.CLEAR_DATA_CREATE_USER,
});

export const responseError = (response) => async (dispatch) => {
  if (response) {
    if (isDev()) console.log(response)

    let errorMessage = response.error;
    if(response.error === "An account with the given email already exists."){
      errorMessage = strings('createUserView.error.accountAlreadyExists')
    }
    dispatch(setSignupState({
      prop: 'error',
      value: [{
        key: "request",
        value: errorMessage
      }]
    }));
  }
};

export const responseSuccess = (response, openSuccessModal) => async (dispatch) => {
  dispatch(clearDataSignup());
  openSuccessModal();
}

export const setSignupState = ({ prop, value }) => ({
  type: Types.SET_CREATE_USER_STATE,
  payload: { prop, value }
});

export const showMessageAfterRedirectInLogin = ({ prop, value }) => async (dispatch) => {
  dispatch(
    setSignupState({ prop, value })
  )
  setTimeout(() => dispatch(
    setSignupState({ prop, value: '' })
  ), 4000)
}

export const validateOnBlurSignup = (inputType) => async (dispatch, getState) => {
  const { email, error, phone, username } = getState().CreateUserReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formAdminCreateUser({ email, error, phone, username });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.CREATE_USER_FAILED,
    payload: error.slice()
  });
}
