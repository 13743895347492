import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { TextInput } from 'src/components';
import { ResponsibleConsumptionAmountTypeStatus } from './ResponsibleConsumptionManager';
import {
  apiPostResponsibleConsumptionDownload,
  clearDataResponsibleConsumption,
  setResponsibleConsumptionDataState,
} from './ResponsibleConsumptionActions';
import { strings } from '../../resources/locales/i18n';
import { getMomentNow, getMomentYearAgo, parseDecimal } from '../../utils/Utils.js';
import { Loading } from '../../components';
import Routing from '../routing/Routing.jsx';

class ResponsibleConsumptionContainer extends Component {
  state = {
    modalIsShown: false,
    key: Math.random(),
  }
  componentDidMount() {
    this._clearDataResponsibleConsumption()
  }
  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.responsibleConsumption'), path: '#' }
    ]

    return (
      <>
        <HeaderContainer title={strings('viewName.responsibleConsumption')} breadcrumbList={breadcrumbList} />
        {this.renderForm()}
        {this.renderCodeModal()}
      </>
    )
  }

  renderCodeModal(show) {
    const { errorRequest } = this.props;
    const animationBgStyle = this.state.modalIsShown ? {
      opacity: 0.8
    } : {}
    const animationModalStyle = this.state.modalIsShown ? {
      marginTop: -50,
      opacity: 1
    } : {}
    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="codeModal">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ marginTop: -180, opacity: 0, transition: 'opacity 0.4s ease-out, margin-top 0.4s ease-out', ...animationModalStyle }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this._clearDataResponsibleConsumption() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body d-flex justify-content-center row px-5">
                <div className="row justify-content-center">
                  <div className="col-12 mb-4 text-center">
                    <span className="material-icons icon-thanks">
                      search_off
                      </span>
                    <span className="title-big d-block mb-3">{strings('responsibleConsumption.downloadError')}</span>
                    <p>{errorRequest}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
                <button type="button" className="btn btn-primary mb-3" onClick={() => { this._toggleModal(); this._clearDataResponsibleConsumption() }}>
                  {strings('button.accept')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{
          opacity: 0, zIndex: -1,
          transition: 'opacity 0.4s ease-out',
          ...animationBgStyle
        }} />
      </div>
    )
  }

  renderForm() {
    const { dateStart, dateEnd, error, idReference, isLoadingResponsibleConsumption } = this.props;

    return (
      <section className="container wrapper wrapper--content pt-5 pb-2">
        <div className="row mb-4">
          <div className="col-12">
            <div className="card px-5 pt-5 pb-0">
              <form id="ResponsibleConsumptionForm" onSubmit={(e) => this._apiPostResponsibleConsumptionDownload(e)} action="" className="w-100 mt-3 mb-5">
                <div className="row mb-3">

                  { /** DOCUMENT ID */}
                  <div className="col-12 col-md-10 mb-3">
                    <div className="form-group form-group--label">
                      <label htmlFor="idReference">{strings('placeholder.cardNumber')}</label>
                      <input
                        id={"idReference"}
                        className="form-control"
                        type="text"
                        placeholder=""
                        value={idReference}
                        onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                      />
                      <small style={{ lineHeight: 1 }}>{strings('documentsView.documentIdDescriptionOptional')}</small>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">

                  { /** DATE PICKER */}
                  <div className="row col-12 mb-3">

                    {/* START */}
                    <div className="form-group col-12 col-sm-6 col-md-5">
                      <div className="title-mid mb-1">{strings('documentsView.date.from')}</div>
                      <div style={{ position: 'relative' }}>
                        <span className="material-icons icon-calendar"> event </span>
                        <input
                          required={idReference.length < 4}
                          id={"dateStart"}
                          className="form-control datepicker"
                          type="date"
                          placeholder=""
                          value={dateStart}
                          onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                          min="2021-01-01"
                          max={dateEnd ? dateEnd : getMomentNow("yyyy-MM-DD")}
                        />
                      </div>
                    </div>
                    {/* END */}
                    <div className="form-group col-sm-6 col-md-5">
                      <div className="title-mid mb-1">{strings('documentsView.date.to')}</div>
                      <div style={{ position: 'relative' }}>
                        <span className="material-icons icon-calendar">event</span>
                        <input
                          required={idReference.length < 4 || dateStart}
                          id={"dateEnd"}
                          className="form-control datepicker"
                          type="date"
                          placeholder=""
                          value={dateEnd}
                          onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                          min={dateStart}
                          // max={dateStart ?
                          //   this._compareDateStartToEnd(dateStart, "yyyy-MM-DD", 1, 'Y') :
                          //   getMomentNow("yyyy-MM-DD")}
                          max={getMomentNow("yyyy-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>

                  { /** DOCUMENT QUANTITY TYPE RADIO INPUT */}
                  <div className="col-12">
                    <span className="title-mid d-block mb-3">	{strings('documentsView.searchBy.title')}</span>
                  </div>
                  <div key={this.state.key} className="col-12 mb-3 d-flex">
                    <div className="form-check mb-2 mr-5">
                      <input
                        required
                        className="form-check-input"
                        type="radio"
                        name={"documentQuantityType"}
                        id={"documentQuantityType"}
                        value={ResponsibleConsumptionAmountTypeStatus.ALL_AMOUNTS}
                        onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                      />
                      <label className="form-check-label" htmlFor={"documentQuantityType"}>
                        {strings('documentsView.allAmounts')}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={"documentQuantityType"}
                        id={"documentQuantityType"}
                        value={ResponsibleConsumptionAmountTypeStatus.AMOUNT}
                        onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                      />
                      <label className="form-check-label" htmlFor={"documentQuantityType"}>
                        {strings('documentsView.singleAmount')}
                      </label>
                    </div>
                  </div>
                  {this.renderComparison()}
                </div>

                { /** BUTTON */}
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="form-group d-flex justify-content-left">
                      <button type="submit" className="btn-primary">
                        {isLoadingResponsibleConsumption ? <Loading /> :
                          <>
                            {strings('responsibleConsumption.downloadReport')}
                            <span className="material-icons p-1">download</span>
                          </>}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderComparison() {
    const { documentQuantityType, documentMaxQuantity, documentMinQuantity } = this.props;
    const minValueForMaxQuantity = parseInt(documentMinQuantity) + 0.01
    if (documentQuantityType === ResponsibleConsumptionAmountTypeStatus.AMOUNT) {
      return (
        <div className="row col-12 mb-3 roll-out">
          { /** COMPARISON MIN QUANTITY */}
          <div className="col-12 col-sm-5">
            <div className="title-mid mb-1">{strings('documentsView.date.from')}</div>
            <div className="form-group">
              <input
                required
                id={"documentMinQuantity"}
                className="form-control"
                type="number"
                placeholder="15,00"
                value={documentMinQuantity}
                min="0"
                max={documentMaxQuantity}
                step="0.01"
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value)}
              />
            </div>
          </div>
          { /** COMPARISON MAX QUANTITY */}
          <div className="col-12 col-sm-5">
            <div className="title-mid mb-1">{strings('documentsView.date.to')}</div>
            <div className="form-group">
              <input
                required
                id={"documentMaxQuantity"}
                className="form-control"
                type="number"
                placeholder="60,00"
                value={documentMaxQuantity}
                min={minValueForMaxQuantity}
                step="0.01"
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value)}
              />
            </div>
          </div>
        </div>
      )
    }

    return <div />
  }

  /* PRIVATE METHOD */
  async _apiPostResponsibleConsumptionDownload(e) {
    e.preventDefault();
    await this.props.apiPostResponsibleConsumptionDownload(() => this._toggleModal());
    this._getRandomKey()
    // this._scrollToFirstInvalidInputIfError();
  }

  _clearDataResponsibleConsumption() {
    this._getRandomKey()
    this.props.clearDataResponsibleConsumption();
  }

  _compareDateStartToEnd = (date, format, numberDays, type) => {
    const dateUser = moment(date).add(numberDays, type).format("yyyyMMDD");
    const dateNow = getMomentNow("yyyyMMDD");

    if (dateNow > dateUser) {
      // date is past
      return moment(date).add(numberDays, type).format(format);
    }
    // date is future
    return getMomentNow(format)
  }

  _handleValueChange = (prop, value) => {
    this.props.setResponsibleConsumptionDataState({ prop, value });
  };

  _handleValueDecimalChange = (prop, value) => {
    this.props.setResponsibleConsumptionDataState({ prop, value: parseDecimal(value) });
  };

  _getRandomKey() {
    this.setState({ key: Math.random() })
  }

  _scrollToFirstInvalidInputIfError() {
    const { error } = this.props;
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' })
      firstError.focus({ preventScroll: true })
    }
  }

  _toggleModal() {
    const codeModal = document.getElementById('codeModal');
    if (codeModal.classList.contains('invisible')) {
      codeModal.classList.remove('invisible')
      this.setState({ modalIsShown: true })
    } else {
      codeModal.classList.add('invisible')
      this.setState({ modalIsShown: false })
    }
  }
};

const mapStateToProps = ({ ResponsibleConsumptionReducer }) => {
  const {
    error, errorRequest, dateStart, dateEnd, documentMinQuantity,
    documentQuantityType, documentMaxQuantity,
    idReference, isLoadingResponsibleConsumption
  } = ResponsibleConsumptionReducer;

  return {
    error, errorRequest, dateStart, dateEnd, documentMinQuantity,
    documentQuantityType, documentMaxQuantity,
    idReference, isLoadingResponsibleConsumption
  };
};

const mapStateToPropsAction = {
  clearDataResponsibleConsumption,
  apiPostResponsibleConsumptionDownload,
  setResponsibleConsumptionDataState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(ResponsibleConsumptionContainer)
