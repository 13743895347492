import React, { Component } from 'react';
import '../../resources/styles/_maintenancePage.css';
import {localAssets} from '../../resources/assets/assets';
import {strings} from '../../resources/locales/i18n';

class MaintenancePage extends Component {


  render() {
    return (
      <div className="msg-box">
        <img src={localAssets.imgIconError.default} alt="" />
        <h2>{strings('pageMaintenance.title')}</h2>
        <h3>{strings('pageMaintenance.description')}</h3>
      </div>
    );
  }

}

export default MaintenancePage;
