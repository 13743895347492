
export default class Types {
  /** CLEAR PURCHASE_CARDS **/
  static CLEAR_DATA_PURCHASE_CARDS = 'CLEAR_DATA_PURCHASE_CARDS';

  /** FORM PURCHASE CARDS **/
  static FORM_PURCHASE_CARDS_FAILED = 'FORM_PURCHASE_CARDS_FAILED';
  static FORM_PURCHASE_CARDS_SUCCESS = 'FORM_PURCHASE_CARDS_SUCCESS';

  /** POST EMAIL PURCHASE CARDS **/
  static POST_PURCHASE_CARDS_FAILED = 'POST_PURCHASE_CARDS_FAILED';
  static POST_PURCHASE_CARDS_SUCCESS = 'POST_PURCHASE_CARDS_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_PURCHASE_CARDS = 'SET_DATA_PURCHASE_CARDS';
}
