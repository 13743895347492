import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import {
  apiPostPurchaseCards,
  clearDataPurchaseCards,
  setPurchaseCardsDataState,
  setPurchaseCardsElementDataState,
} from './PurchaseCardsActions.js';

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import Select from 'react-select'
// Resources
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing';
import PurchaseCardsManager, { RechargeableStatus, RestrictionsOptions } from './PurchaseCardsManager';
import { getMomentNow, getMomentNextYear, parseDecimal } from '../../utils/Utils.js';

class PurchaseCardsContainer extends Component {
  state = {
    key: 0,
    formModuleElement: {
      alcohol: false,
      amount: '',
      cosmetics: false,
      endDate: '',
      isRechargeable: '',
      magazines: false,
      quantity: '',
      reference: '',
      restrictions: [],
      tobacco: false,
    },
    modalIsShown: false,
  }
  componentDidMount() {
    this._clearDataPurchaseCards();
  }

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.purchaseCards'), path: '#' },
    ];
    const title = strings('viewName.purchaseCards');
    return (
      <>
        <HeaderContainer title={title} breadcrumbList={breadcrumbList} />
        {this.renderPurchaseSection()}
        {this.renderRequestConfirmationModal()}
      </>
    )
  };
  renderRequestConfirmationModal() {
    const { confirmationRequest, errorRequest } = this.props;
    const animationBgStyle = this.state.modalIsShown ? {
      opacity: 0.8
    } : {}
    const animationModalStyle = this.state.modalIsShown ? {
      marginTop: -50,
      opacity: 1
    } : {}
    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="confirmationRequest">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ marginTop: -180, opacity: 0, transition: 'opacity 0.4s ease-out, margin-top 0.4s ease-out', ...animationModalStyle }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this._clearDataPurchaseCards() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body d-flex justify-content-center row px-5">
                <div className="row justify-content-center">
                  <div className="col-12 mb-4 text-center">
                    <span className="material-icons icon-thanks">
                      send
                      </span>
                    <span className="title-big d-block mb-3">{confirmationRequest ? strings('purchaseCardsView.requestSent') : strings('rechargeCardsView.error')}</span>
                    <p>{confirmationRequest || errorRequest}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
                <button type="button" className="btn btn-primary mb-3" onClick={() => { this._toggleModal(); this._clearDataPurchaseCards() }}>
                  {strings('button.accept')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{
          opacity: 0, zIndex: -1,
          transition: 'opacity 0.4s ease-out',
          ...animationBgStyle
        }} />
      </div>
    )
  }

  renderFormModule() {
    const { purchaseCardsData } = this.props;

    const multipleSelectStyles = {
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
      }),

      valueContainer: (provided, state) => ({
        ...provided,
        padding: '5px 8px',
        minHeight: '60px',
      }),
      container: (provided, state) => ({
        ...provided,
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : '',
        borderRadius: 4
      }),
      control: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
      })

    }
    return (
      <>
        {
          purchaseCardsData.map((item, index) => {
            return (
              <div id="templateTarjeta" className="row add-block">
                {/* QUANTITY */}
                <div className="col-12 col-sm-6 mb-4">
                  <div className="form-group form-group--label">
                    <label htmlFor="quantity">{strings('purchaseCardsView.form.quantity')}</label>
                    <input
                      required
                      id={"quantity"}
                      data-name="quantity"
                      className="form-control"
                      type="number"
                      placeholder=""
                      value={item.quantity}
                      onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                      onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value, index, 0)}
                    />
                  </div>
                </div>
                {/* REFERENCE */}
                <div className="col-12 col-sm-6 mb-4">
                  <div className="form-group form-group--label">
                    <label htmlFor="reference">{strings('purchaseCardsView.form.reference')}</label>
                    <input
                      id={"reference"}
                      data-name="reference"
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={item.reference}
                      onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                    />
                  </div>
                </div>
                {/* AMOUNT */}
                <div className="col-12 col-sm-6 mb-3">
                  <div className="form-group form-group--label">
                    <label htmlFor="amount">{strings('purchaseCardsView.form.amount')}</label>
                    <input
                      required
                      id={"amount"}
                      data-name="amount"
                      className="form-control"
                      type="number"
                      placeholder=""
                      value={item.amount}
                      onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                      onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value, index, 2)}
                    />
                  </div>
                </div>

                {/* RESTRICTIONS */}
                <div className="col-12 mt-3">
                  <label >{strings('purchaseCardsView.form.restrictions.title')}</label>
                  <Select
                    key={this.state.key}
                    isMulti
                    name="restrictions"
                    options={RestrictionsOptions}
                    styles={multipleSelectStyles}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#80bdff',
                      },
                    })}
                    placeholder={strings('purchaseCardsView.form.restrictions.placeholder')}
                    onChange={(restrictionsValue) => this._handleValueChange('restrictions', restrictionsValue.map(selected => selected.value), index)}
                    noOptionsMessage={() => strings('purchaseCardsView.form.restrictions.noOptions')}
                  />
                </div>
                {/* RECHARGEABLE TYPE */}
                <div className="col-12 col-sm-6 mt-5 mb-3">
                  <label >{strings('purchaseCardsView.form.rechargeable.title')}</label>
                  <select
                    required
                    id="isRechargeable"
                    className="form-group "
                    aria-label="Default select example"
                    value={item.isRechargeable}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                  >
                    <option value={RechargeableStatus.SELECT}>{strings('form.error.selectOption')}</option>
                    <option value={RechargeableStatus.YES}>{strings('purchaseCardsView.form.rechargeable.yes')}</option>
                    <option value={RechargeableStatus.NO}>{strings('purchaseCardsView.form.rechargeable.no')}</option>
                  </select>
                </div>
                { /** DATE PICKER */}
                <div className="form-group col-12 col-sm-6 mt-sm-5 mb-3">
                  <div className="mb-2">{strings('purchaseCardsView.form.expirationDate')}</div>
                  <div style={{position:'relative'}}>

                    <span className="material-icons icon-calendar">event</span>
                    <input
                      required
                      id={"endDate"}
                      className="form-control datepicker"
                      type="date"
                      placeholder=""
                      value={item.endDate}
                      onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                      min={getMomentNow("yyyy-MM-DD")}
                    />
                  </div>
                </div>

                {index === 0 ? null : <a key={`btn-${index}`} id="deleteButton" className='btn-delete' onClick={(e) => this._deleteFormModule(e, index)}>{strings('button.delete')}</a>}

              </div>
            )
          })
        }
      </>
    )
  }
  renderPurchaseSection() {
    const { isLoadingPurchaseCards } = this.props;
    return (
      <section className="container wrapper py-5">
        <div className="row module-tabs">
          <div className="col-12 mb-md-0">
            <div className="card p-0">

              <ul className="nav tab-nav nav-tabs p-4 text-center d-flex justify-content-between flex-wrap" id="pills-tab" role="tablist">
                <li className="nav-item p-0 m-0" role="presentation">
                  <Link to={Routing.purchaseCards} className="nav-link title-lite white py-4 active" id="compra-tab" data-toggle="pill" role="tab" aria-controls="compra-tarjeta" aria-selected="true">{strings('purchaseCardsView.buy')}</Link>
                </li>
                <li className="nav-item p-0 m-0" role="presentation">
                  <Link to={Routing.rechargeCards} className="nav-link  title-lite white py-4" id="recarga-tab" data-toggle="pill" role="tab" aria-controls="recarga-tarjeta" aria-selected="false">{strings('purchaseCardsView.recharge')}</Link>
                </li>
              </ul>

              <div className="tab-content p-4 d-flex justify-content-center" id="pills-tabContent">
                <div className="tab-pane fade show active" id="compra-tarjeta" role="tabpanel" aria-labelledby="compra-tab">
                  <form id="tarjetasForm" onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault();
                      return false;
                    }
                  }} onSubmit={(e) => this._apiPostPurchaseCards(e)} action="" className="w-100 mt-3 mb-5">
                    <div className="row">
                      <div className="col-12 pb-3">
                        <span className="title-mid d-block">{strings('purchaseCardsView.orderData')}</span>
                      </div>
                    </div>
                    {this.renderFormModule()}
                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="form-group mt-2 mb-5">
                          <a id="addButtonTarjeta" className="link-text d-flex align-items-center" style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={(e) => this._addFormModule(e)}><span className="material-icons mr-1">playlist_add</span>{strings('purchaseCardsView.addCard')}</a>
                        </div>
                        <div className="form-group d-flex justify-content-center">
                          <button type="submit" className="btn-primary">{isLoadingPurchaseCards ?
                            <Loading /> : strings('purchaseCardsView.request')}</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    )
  }

  _addFormModule(e) {
    const { purchaseCardsData } = this.props;
    e.preventDefault();
    this._setPurchaseCardsDataState({ prop: 'purchaseCardsData', value: purchaseCardsData.concat(this.state.formModuleElement) })
  }

  _apiPostPurchaseCards(e) {
    e.preventDefault();
    this.props.apiPostPurchaseCards(() => this._toggleModal())
  }

  _clearDataPurchaseCards() {
    this.props.clearDataPurchaseCards();
    this._getRandomKey()
  }

  _deleteFormModule(e, index) {
    const { purchaseCardsData } = this.props;
    e.preventDefault();
    const clonePurchaseCardsData = [...purchaseCardsData]
    clonePurchaseCardsData.splice(index, 1)
    this._setPurchaseCardsDataState({
      prop: 'purchaseCardsData', value: clonePurchaseCardsData
    })
  }

  _handleValueChange = (prop, value, index) => {
    this.props.setPurchaseCardsElementDataState({ prop, value }, index);
  };

  _handleValueDecimalChange = (prop, value, index, decimals) => {
    if (value !== '')
      this.props.setPurchaseCardsElementDataState({ prop, value: parseDecimal(value, decimals) }, index);
  };

  _getRandomKey() {
    this.setState({ key: Math.random() })
  }

  _setPurchaseCardsDataState({ prop, value }) {
    this.props.setPurchaseCardsDataState({ prop, value });
  }

  _toggleModal() {
    const confirmationRequest = document.getElementById('confirmationRequest');
    if (confirmationRequest.classList.contains('invisible')) {
      confirmationRequest.classList.remove('invisible')
      this.setState({ modalIsShown: true })
    } else {
      confirmationRequest.classList.add('invisible')
      this.setState({ modalIsShown: false })
    }
  }
}

const mapStateToProps = ({ PurchaseCardsReducer }) => {
  const { confirmationRequest, errorRequest, isLoadingPurchaseCards, purchaseCardsData } = PurchaseCardsReducer;
  return {
    confirmationRequest, errorRequest, isLoadingPurchaseCards, purchaseCardsData
  }
}


const mapStateToPropsAction = {
  apiPostPurchaseCards,
  clearDataPurchaseCards,
  setPurchaseCardsDataState,
  setPurchaseCardsElementDataState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(PurchaseCardsContainer);