import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import {
  apiPutEditOrg,
  clearDataEditOrg,
  setEditOrgState,
  validateOnBlurEditOrg,
} from './EditOrgActions'

// Components
import { AlertContainer } from 'src/components';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { ModalContainer } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import EditOrgManager from "./EditOrgManager";
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';

class EditOrgContainer extends Component {

  state = {
    isModalShown: false,
  }

  componentDidMount() {
    const orgInfo = this.props.location.params;
    this.setOrgInfo(orgInfo);
  }

  componentWillUnmount() {
    this._clearDataEditOrg();
  }
  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.backOffice'), path: Routing.backOffice },
      { name: strings('viewName.editOrg'), path: Routing.nowhere },
    ]
    const orgInfo = this.props.location.params;
    if(!orgInfo){
      const {history} = this.props;
      history.push(Routing.backOffice) 
    }
    return (
      <>
        <HeaderContainer title={strings('viewName.backOffice')} breadcrumbList={breadcrumbList} />
        <ModalContainer
          closeAction={() => this._handleViewChange()}
          icon='check'
          message={strings('editOrgView.modal.message')}
          modalId='editUserConfirm'
          isModalShown={this.state.isModalShown}
          primaryAction={() => this._handleViewChange()}
          primaryButtonText={strings('editOrgView.modal.primaryButton')}
          title={strings('editOrgView.modal.title')}
        />
        <section className="container wrapper wrapper--content d-flex pt-5 pb-5 justify-content-center">
          <div className="col-12 col-lg-9 mb-4 mb-md-0">
            <div className="card pl-5 pr-5 pb-3 pt-2">
              {this.renderEditOrgForm()}
            </div>
          </div>
        </section>
      </>
    )
  }

  renderEditOrgForm() {
    const
      {
        error, idSap, isLoadingEditOrg, name, requestMessage,
      } = this.props;

    return (
      <form id="editOrgForm" onSubmit={(e) => this._apiPostEditOrg(e)} action="" className="w-100 mt-5">
        <AlertContainer
          error={error}
          id={'request'}
          className={'alert-danger'}
        />
        <div className="row pb-4">
          <div className="col-12 pb-5">
            <span className="title-mid d-block">{strings('editOrgView.userOrg')}</span>
          </div>
          { /** NAME */}
          <div className="col-12  col-sm-6">
            <TextInput
              title={strings('editOrgView.form.name')}
              id="name"
              name="name"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={name}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>

          { /** ID SAP */}
          <div className="col-12 col-sm-6">
            <TextInput
              title={strings('editOrgView.form.idSap')}
              id="idSap"
              name="idSap"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={idSap}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          
          <div className="form-group d-flex mt-4 justify-content-center">
            <button type="submit" className="btn-primary">
              {isLoadingEditOrg ? <Loading /> : strings('editOrgView.button.save')}
            </button>
          </div>
        </div>
      </form>
    )
  }

  /* PRIVATE METHOD */

  _apiPostEditOrg(e) {
    e.preventDefault();
    this.props.apiPutEditOrg(() => this._toggleModal());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearDataEditOrg() {
    this.props.clearDataEditOrg();
  }

  _handleValueChange = (prop, value) => {
    this.props.setEditOrgState({ prop, value });
  };

  _handleViewChange = () => {
    this._toggleModal();
    const { history } = this.props;
    history.push(Routing.backOffice);
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurEditOrg(inputType)
  }

  setOrgInfo(orgInfo) {
    const id = EditOrgManager.getOrgId(orgInfo)
    const idSap = EditOrgManager.getOrgIdSap(orgInfo)
    const name = EditOrgManager.getOrgName(orgInfo)
    
    this.props.setEditOrgState({ prop: 'id', value: id });
    this.props.setEditOrgState({ prop: 'idSap', value: idSap });
    this.props.setEditOrgState({ prop: 'name', value: name });
  }

  _toggleModal() {
    const editUserConfirm = document.getElementById('editUserConfirm');
    if (editUserConfirm.classList.contains('invisible')) {
      editUserConfirm.classList.remove('invisible')
      this.setState({ isModalShown: true })
    } else {
      editUserConfirm.classList.add('invisible')
      this.setState({ isModalShown: false })
    }
  }
}

const mapStateToProps = ({ EditOrgReducer }) => {
  const {
    error, id, idSap, isLoadingEditOrg, name, requestMessage,
   } = EditOrgReducer;

  return {
    error, id, idSap, isLoadingEditOrg, name, requestMessage,
  }
}

const mapStateToPropsAction = {
  apiPutEditOrg,
  clearDataEditOrg,
  setEditOrgState,
  validateOnBlurEditOrg,
};
export default connect(mapStateToProps, mapStateToPropsAction)(EditOrgContainer);