import React from 'react';
import axios from 'axios';
import Config from "react-environment";

// Resources
import Tags from './Tags';
import { isDev } from '../../utils/Utils';
import { strings } from '../../resources/locales/i18n';
import { apiRefreshToken } from '../../modules/auth/login/LoginActions';
import { DocumentAmountTypeStatus } from '../../modules/documents/DocumentsManager';
import { ResponsibleConsumptionAmountTypeStatus } from '../../modules/responsibleConsumption/ResponsibleConsumptionManager';


/** VERBS **/
const DEL  = 'DELETE';
const GET  = 'GET';
const POST = 'POST';
const PUT  = 'PUT';


/** *** **/
/** DEL **/
/** *** **/
export const delCustomerAddress = (props, addressId, callbackError, callbackSuccess) => async (dispatch) => {
  const { accessToken, storeCode } = props;
  let store  = getStoreCode(storeCode);
  let url    = `/rest/${store}/app/V1/customer/addresses/${addressId}?apiVersion=${Config.API_VERSION}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  return dispatch(launchAsyncTask(Tags.DEL_CUSTOMER_ADDRESS, DEL, url, config, null, callbackError, callbackSuccess));
};

export const deleteBackOfficeOrg = (orgSapId, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  let url    = `${Config.REACT_APP_BASE_URL}/orgs/${orgSapId}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  await dispatch(launchAsyncTask(Tags.DEL_BACK_OFFICE_ORG, DEL, url, config, null, callbackError, callbackSuccess));
};

export const deleteNotification = (notificationId, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  let url    = `${Config.REACT_APP_BASE_URL}/notifications/${notificationId}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  await dispatch(launchAsyncTask(Tags.DEL_NOTIFICATION, DEL, url, config, null, callbackError, callbackSuccess));
};

/** *** **/
/** GET **/
/** *** **/

export const checkCard = (id, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/checkcard/${id}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_CHECK_CARD, GET, url, config, params, callbackError, callbackSuccess));
};

export const getCards = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/cards`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_CARDS, GET, url, config, params, callbackError, callbackSuccess));
};

export const getCardDetails = (id, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url = `${Config.REACT_APP_BASE_URL}/card/${id}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_CARD_DETAILS, GET, url, config, params, callbackError, callbackSuccess));
};


export const getPaperTicket = (entityId, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/ticket/${entityId}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_PAPER_TICKET, GET, url, config, params, callbackError, callbackSuccess));
};

export const getPaperTicketOnline = (entityId, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/ticket/online/${entityId}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_PAPER_TICKET_ONLINE, GET, url, config, params, callbackError, callbackSuccess));
};

export const getCardTransactions = (id, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { cardsTransactionsCurrentPage, cardsTransactionsLimit } = getState().CardTransactionsReducer;


  let url    = `${Config.REACT_APP_BASE_URL}/card/${id}/transactions?page=${cardsTransactionsCurrentPage}&pageSize=${cardsTransactionsLimit}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_CARD_TRANSACTIONS, GET, url, config, params, callbackError, callbackSuccess));
};

export const getBackOfficeOrgDownload = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/excelOrgs`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_BACK_OFFICE_ORG_DOWNLOAD, GET, url, config, params, callbackError, callbackSuccess));
};

export const getBackOfficeRequestsDownload = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/excelBuyRech`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_BACK_OFFICE_REQUESTS_DOWNLOAD, GET, url, config, params, callbackError, callbackSuccess));
};

export const getBackOfficeUsersDownload = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/excelUsers`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_BACK_OFFICE_USERS_DOWNLOAD, GET, url, config, params, callbackError, callbackSuccess));
};

export const getBackOfficeOrgInfo = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { orgCurrentPage, orgLimit } = getState().BackOfficeReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/orgs?page=${orgCurrentPage}&pageSize=${orgLimit}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_BACK_OFFICE_ORG, GET, url, config, params, callbackError, callbackSuccess));
};

export const getBackOfficeRequestsInfo = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { requestsCurrentPage, requestsLimit } = getState().BackOfficeReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/buyrech?page=${requestsCurrentPage}&pageSize=${requestsLimit}`;

  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_BACK_OFFICE_REQUESTS, GET, url, config, params, callbackError, callbackSuccess));
};

export const getBackOfficeUsersInfo = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { usersCurrentPage, usersLimit } = getState().BackOfficeReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/users?page=${usersCurrentPage}&pageSize=${usersLimit}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_BACK_OFFICE_USERS, GET, url, config, params, callbackError, callbackSuccess));
};

export const getDashboardInfo = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/dashboard`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_DASHBOARD, GET, url, config, params, callbackError, callbackSuccess));
};

export const getNotificationsInfo = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/notifications`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.GET_NOTIFICATIONS, GET, url, config, params, callbackError, callbackSuccess));
};

export const getTransactionsDownload = (idDocument, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/downexcel/${idDocument}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': '/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
  };

  let params = {};


  return dispatch(launchAsyncTask(Tags.GET_DOCUMENT_DOWNLOAD, GET, url, config, params, callbackError, callbackSuccess));
};

/** **** **/
/** POST **/
/** **** **/

export const deleteBackOfficeUser = (userId, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/deleteuser`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    usernameId: userId ? userId : null,
  };

  await dispatch(launchAsyncTask(Tags.POST_DELETE_BACK_OFFICE_USER, POST, url, config, params, callbackError, callbackSuccess));
};

export const postCardPin = (base64String, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { uuid, days, formType, excelFile} = getState().CardsPinReducer;

  let url = `${Config.REACT_APP_BASE_URL}/cardpins`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {
    "cards": formType === 'cardsForm'? uuid : null,
    "filename": formType === 'uploadForm'? excelFile.name : null,
    "base64String": base64String,
    "expire_days": days
  };

  return dispatch(launchAsyncTask(Tags.POST_CARD_PIN, POST, url, config, params, callbackError, callbackSuccess));
};

export const postCreateOrg = (newOrgInfo, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/orgs`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = newOrgInfo;

  await dispatch(launchAsyncTask(Tags.POST_CREATE_BACK_OFFICE_ORG, POST, url, config, params, callbackError, callbackSuccess));
};

export const postCreateUser = (newUserInfo, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  let url = `${Config.REACT_APP_BASE_URL}/createuser`;

  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let adminString = newUserInfo.attributes.profile === true && typeof newUserInfo.attributes.profile === 'boolean' ? 'Admin' : '';
  adminString = newUserInfo.attributes.profile === 'true' && typeof newUserInfo.attributes.profile === 'string' ? 'Admin' : adminString;

  let params = {
    mfa: newUserInfo.mfa,
    username: newUserInfo.username,
    userAttributes: [
      {
        Name: "email",
        Value: newUserInfo.attributes.email
      },
      {
        Name: "email_verified",
        Value: "true"
      },
      {
        Name: "family_name",
        Value: newUserInfo.attributes.family_name
      },
      {
        Name: "locale",
        Value: newUserInfo.attributes.locale
      },
      {
        Name: "name",
        Value: newUserInfo.attributes.name
      },
      {
        Name: "phone_number",
        Value: newUserInfo.attributes.phone_number
      },
      {
        Name: "phone_number_verified",
        Value: "true"
      },
      {
        Name: "profile",
        Value: newUserInfo.attributes.profile
      },
    ]
  };

  return dispatch(launchAsyncTask(Tags.POST_EDIT_USER, POST, url, config, params, callbackError, callbackSuccess));
};

export const getOrg = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { idSap } = getState().CreateOrgReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/getOrgName`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {
    "cliente": idSap
  };

  return dispatch(launchAsyncTask(Tags.GET_ORG, POST, url, config, params, callbackError, callbackSuccess));
};

export const postDocuments = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const {
    dateStart, dateEnd, documentQuantityType, documentMaxQuantity,
    documentMinQuantity, idReference, documentSearchType, documentType
  } = getState().DocumentsReducer;

  let min = 0;
  let max = null;
  if (documentQuantityType === DocumentAmountTypeStatus.AMOUNT) {
    min = documentMinQuantity
    max = documentMaxQuantity
  }

  let url    = `${Config.REACT_APP_BASE_URL}/documents`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    from:         dateStart ? dateStart : null,
    to:           dateEnd ? dateEnd : null,
    documentType: documentType ? documentType : null,
    searchBy:     documentSearchType ? documentSearchType : null,
    reference:    idReference ? idReference : null,
    minAmount:    min,
    maxAmount:    max
  };


  return dispatch(launchAsyncTask(Tags.POST_DOCUMENTS, POST, url, config, params, callbackError, callbackSuccess));
};

export const postDocumentsDownload = (idDocument, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/document/download`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    }
  };

  let params = {
    'downloadUrl': idDocument
  };


  return dispatch(launchAsyncTask(Tags.GET_DOCUMENT_DOWNLOAD, POST, url, config, params, callbackError, callbackSuccess));
};

export const postEditUser = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const {
    admin, userId, username, email, organization, phone,
  } = getState().EditUserReducer;

  let url = `${Config.REACT_APP_BASE_URL}/modifyuser`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    usernameId: userId,
    userAttributes: [
      {
        Name: "phone_number",
        Value: `+34${phone}`
      },
      {
        Name: "email",
        Value: email
      },
      {
        Name: "email_verified",
        Value: "true"
      },
      {
        Name: "family_name",
        Value: organization
      },
      {
        Name: "name",
        Value: username
      },
      {
        Name: "profile",
        Value: admin
      },
    ]
  };

  return dispatch(launchAsyncTask(Tags.POST_EDIT_USER, POST, url, config, params, callbackError, callbackSuccess));
};

export const postEmail = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const {
    cif, company, consult, consultType, email, legalConsent, username, surnames, telephone
  } = getState().CostumerSupportReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/sendmail`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    cif:          cif ? cif : null,
    company:      company ? company : null,
    consult:      consult ? consult : null,
    consultType:  consultType ? consultType : null,
    email:        email ? email : null,
    legalConsent: legalConsent ? legalConsent : null,
    username:     username ? username : null,
    surnames:     surnames ? surnames : null,
    telephone:    telephone ? telephone : null,
  };

  return dispatch(launchAsyncTask(Tags.POST_EMAIL_COSTUMER_SUPPORT, POST, url, config, params, callbackError, callbackSuccess));
};

export const postPurchaseCards = (data, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/buyrech`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    type: "buy",
    cards: data
  };

  return dispatch(launchAsyncTask(Tags.POST_PURCHASE_CARDS, POST, url, config, params, callbackError, callbackSuccess));
};

export const postRechargeCards = ({ formType, base64String }, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { excelFile, rechargeCardsData } = getState().RechargeCardsReducer;
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/buyrech`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };
  const cardsFormParams = {
    type:  "recharge",
    cards: rechargeCardsData
  };

  const uploadFormParams = {
    type:         "recharge",
    filename:     excelFile ? excelFile.name : null,
    base64String: base64String ? base64String : null
  };

  let params = formType === 'excel' ? uploadFormParams : cardsFormParams;

  return dispatch(launchAsyncTask(Tags.POST_RECHARGE_CARDS, POST, url, config, params, callbackError, callbackSuccess));
};

export const postResponsibleConsumptionDownload = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const {
    dateStart, dateEnd, documentQuantityType, documentMaxQuantity,
    documentMinQuantity, idReference
  } = getState().ResponsibleConsumptionReducer;

  let min = null;
  let max = null;
  if (documentQuantityType === ResponsibleConsumptionAmountTypeStatus.AMOUNT) {
    min = documentMinQuantity
    max = documentMaxQuantity
  }

  let url    = `${Config.REACT_APP_BASE_URL}/downexcelrespo`;
  let config = {
    headers: {
      'Authorization': 'Bearer '+ accessToken,
      'Content-Type': 'application/json',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // 'Content-Type': '/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
  };

  let params = {
    from:         dateStart ? dateStart : null,
    to:           dateEnd ? dateEnd : null,
    reference:    idReference ? idReference : null,
    minAmount:    min,
    maxAmount:    max
  };


  return dispatch(launchAsyncTask(Tags.POST_RESPONSIBLE_CONSUMPTION_DOWNLOAD, POST, url, config, params, callbackError, callbackSuccess));
};

export const postSearch = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { billingStatus, dateActivationStart, dateActivationEnd, dateLastPurchaseStart, dateLastPurchaseEnd, initialBalance, usesStatus, uuid, cardsCurrentPage, cardsLimit, } = getState().CardsReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/search?page=${cardsCurrentPage}&pageSize=${cardsLimit}`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    uuid:                  uuid ? uuid : null,
    dateActivationStart:   dateActivationStart ? dateActivationStart.replace('/', '-') : null,
    dateActivationEnd:     dateActivationEnd ? dateActivationEnd.replace('/', '-') : null, //nuevo
    dateLastPurchaseStart: dateLastPurchaseStart ? dateLastPurchaseStart.replace('/', '-') : null,
    dateLastPurchaseEnd:   dateLastPurchaseEnd ? dateLastPurchaseEnd.replace('/', '-') : null, //nuevo
    usesStatus:            usesStatus ? usesStatus : null,
    initialBalance:        initialBalance && initialBalance !== "0.00" ? initialBalance : null,
    billingStatus:         billingStatus ? billingStatus : null,
  };


  return dispatch(launchAsyncTask(Tags.POST_SEARCH, POST, url, config, params, callbackError, callbackSuccess));
};

/** *** **/
/** PUT **/
/** *** **/

export const putEditOrg = (callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;
  const { id, idSap, name } = getState().EditOrgReducer;

  let url = `${Config.REACT_APP_BASE_URL}/orgs`;
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    name: name ? name : null,
    id_sap: idSap ? idSap : null,
    id: id
  };

  return dispatch(launchAsyncTask(Tags.PUT_EDIT_ORG, PUT, url, config, params, callbackError, callbackSuccess));
};

export const putNotifications = (notificationId, callbackError, callbackSuccess) => async (dispatch, getState) => {
  const { accessToken } = getState().UserReducer;

  let url    = `${Config.REACT_APP_BASE_URL}/notifications/${notificationId}`;
  let params = {
  };
  let config = {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
      'Content-Type': 'application/json'
    }
  };

  return dispatch(launchAsyncTask(Tags.PUT_NOTIFICATIONS, PUT, url, config, params, callbackError, callbackSuccess));
};

/** *************** **/
/** PRIVATE METHODS **/
/** *************** **/
export const getStoreCode = (userStoreCode) => {
  const defaultStoreCode = "c9494";
  return userStoreCode ? userStoreCode : defaultStoreCode;
};

export const launchAsyncTask = (tag, verb, url, config, params, callbackError, callbackSuccess) => async (dispatch) => {
  let response   = null;
  let httpClient = axios.create();
  httpClient.defaults.baseURL = Config.REACT_APP_BASE_URL;

  if (verb === DEL) {
    await httpClient.delete(url, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  if (verb === GET) {
    await httpClient.get(url, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  if (verb === POST) {
    await httpClient.post(url, params, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  if (verb === PUT) {
    await httpClient.put(url, params, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  dispatch(onResponse(tag, response, callbackError, callbackSuccess, { verb, url, config, params }));
};

export const onResponse = (tag, response, callbackError, callbackSuccess, props) => async (dispatch, getState) => {
  if (isDev()) console.log('TAG: ', tag, ' | Response: ', response);

  if (response === undefined || response === null)
    return;

  switch (response.status) {
    case 200:
      callbackSuccess(tag, response.data);
      break;

    case 201:
      callbackSuccess(tag, response.data);
      break;

    case 400:
      callbackError(tag, response.data);
      break;

    case 401:
      if (isDev()) console.log('Invalid credentials 401 - Logout');
      const { verb, url, params } = props
      await dispatch(apiRefreshToken((config) =>
        dispatch(launchAsyncTask(tag, verb, url, config, params, callbackError, callbackSuccess))
      ));

      break;

    case 402:
      if (response.data && response.data.error && response.data.error === 'invalidUsername') {
        callbackSuccess(tag, response); // We don't give any clues about the invalid username
      }
      break;

    case 403:
      if (response.data && response.data.error && response.data.error === 'passwordAlreadyRequested') {

      }
      if (response.data && response.data.response && response.data.response.length > 0) {
        // DialogManager.singleAlert(response.data.response);
        callbackError(tag, response);
      }
      break;

    case 404:
      callbackError(tag, response);
      break;

    default:
      callbackError(tag, response.data);
      break;
  }
};

