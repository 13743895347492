import { Auth } from 'aws-amplify';
import Types from './Types';

import { clearDashboardInfo } from '../dashboard/DashboardActions';
import { clearDataCards } from '../cards/CardsActions';
import { clearDataCardTransactions } from '../cardTransactions/CardTransactionsActions';
import { clearDataCostumerSupport } from '../costumerSupport/CostumerSupportActions';
import { clearDataDocuments } from '../documents/DocumentsActions';
import { clearDataPaperTicket } from '../paperTicket/PaperTicketActions';
import { clearDataResponsibleConsumption } from '../responsibleConsumption/ResponsibleConsumptionActions';
import { clearMenuState } from '../drawer/DrawerActions';
import { showMessageAfterRedirectInLogin } from '../auth/login/LoginActions';

import { strings } from '../../resources/locales/i18n';
import { isDev } from '../../utils/Utils.js';
import FormValidationManager from '../../utils/managers/FormValidationManager.js';

export const apiEditPassword = (openSuccessModal) => async (dispatch, getState) => {
  const { currentPassword, newPassword, userInfo } = getState().UserReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formEditPassword({ currentPassword, newPassword });
  if (error) {
    // Scroll to and focus first error input
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' })
      firstError.focus({ preventScroll: true })
    }
    return dispatch({
      type: Types.EDIT_PASSWORD_FAILED,
      payload: error
    });
  }
  dispatch(setUserDataProps({ prop: 'passwordError', value: [] }));

  // -- 2. Request
  dispatch(setUserDataProps({ prop: 'isLoadingEditPassword', value: true }));
  await Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, currentPassword, newPassword);
    })
    .then(data => {
      dispatch(clearPasswords());
      openSuccessModal();
    })
    .catch(err => dispatch(responseError(err)))
  dispatch(setUserDataProps({ prop: 'isLoadingEditPassword', value: false }));
}

export const clearPasswords = () => async (dispatch) => {
  dispatch({ type: Types.CLEAR_PASSWORDS_USER })
}

export const responseError = (error) => async (dispatch) => {
  if (isDev()) console.log(error)
  if (!error) {
    return;
  }

  let errorMessage = strings('error.somethingWentWrong');

  if (error.code === "NotAuthorizedException") {
    errorMessage = strings('login.error.userDoesNotExist')
  }
  if (error.code === "LimitExceededException") {
    errorMessage = strings('error.limitExceeded')
  }
  dispatch(setUserDataProps({ prop: 'passwordError', value: [{ key: "request", value: errorMessage }] }));
}

export const setUserAccessToken = (accessToken, refreshToken) => async (dispatch) => {
  dispatch({
    type: Types.SET_USER_ACCESS_TOKEN,
    payload: { accessToken, refreshToken }
  });
};

export const setUserInfo = () => async (dispatch) => {
  const userInfo = await Auth.currentUserInfo();
  dispatch({
    type: Types.SET_USER_DATA,
    payload: userInfo
  })
}

export const setUserDataProps = ({ prop, value }) => ({
  type: Types.SET_USER_DATA_PROPS,
  payload: { prop, value }
});

export const setUserLogout = (showMessageLogin) => async (dispatch) => {
  await dispatch({ type: Types.SET_USER_LOGOUT, });
  await dispatch(clearDashboardInfo());
  await dispatch(clearDataCards());
  await dispatch(clearDataCardTransactions());
  await dispatch(clearDataCostumerSupport());
  await dispatch(clearDataDocuments());
  await dispatch(clearDataPaperTicket());
  await dispatch(clearDataResponsibleConsumption());
  await dispatch(clearMenuState());

  if (showMessageLogin) {
    dispatch(showMessageAfterRedirectInLogin({ prop: 'logoutMessage', value: strings('login.logoutMessage') }));
  }
};

export const validateOnBlurChangePassword = (inputType) => async (dispatch, getState) => {
  const { currentPassword, passwordError, newPassword } = getState().UserReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formEditPassword({ currentPassword, newPassword });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  passwordError.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { passwordError.push(errorItem) }
  } else {
    if (indexError !== null) { passwordError.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.EDIT_PASSWORD_FAILED,
    payload: passwordError.slice()
  });
}