import { combineReducers } from 'redux';

//Reducer
import BackOfficeReducer              from '../modules/backOffice/BackOfficeReducer';
import CardsReducer                   from '../modules/cards/CardsReducer';
import CardsPinReducer                 from '../modules/cardsPin/CardsPinReducer';
import CardTransactionsReducer        from '../modules/cardTransactions/CardTransactionsReducer';
import ConfirmAccountReducer          from '../modules/auth/confirmAccount/ConfirmAccountReducer';
import CostumerSupportReducer         from '../modules/costumerSupport/CostumerSupportReducer';
import CreateOrgReducer               from '../modules/createOrg/CreateOrgReducer';
import CreateUserReducer              from '../modules/createUser/CreateUserReducer';
import DashboardReducer               from '../modules/dashboard/DashboardReducer';
import DocumentsReducer               from '../modules/documents/DocumentsReducer';
import DrawerReducer                  from '../modules/drawer/DrawerReducer';
import EditOrgReducer                 from '../modules/editOrg/EditOrgReducer';
import EditUserReducer                from '../modules/editUser/EditUserReducer';
import LoginReducer                   from '../modules/auth/login/LoginReducer';
import PaperTicketReducer             from '../modules/paperTicket/PaperTicketReducer';
import PurchaseCardsReducer           from '../modules/purchaseCards/PurchaseCardsReducer';
import NewPasswordReducer             from '../modules/auth/newPassword/NewPasswordReducer';
import NotificationsReducer           from '../modules/notifications/NotificationsReducer';
import RechargeCardsReducer           from '../modules/rechargeCards/RechargeCardsReducer';
import RememberPasswordReducer        from '../modules/auth/rememberPassword/RememberPasswordReducer';
import ResponsibleConsumptionReducer  from '../modules/responsibleConsumption/ResponsibleConsumptionReducer';
import UserReducer                    from '../modules/user/UserReducer';



const reducer = combineReducers({
  BackOfficeReducer,
  CardsReducer,
  CardTransactionsReducer,
  CardsPinReducer,
  ConfirmAccountReducer,
  CreateOrgReducer,
  CostumerSupportReducer,
  CreateUserReducer,
  DashboardReducer,
  DocumentsReducer,
  DrawerReducer,
  EditOrgReducer,
  EditUserReducer,
  LoginReducer,
  NewPasswordReducer,
  NotificationsReducer,
  PaperTicketReducer,
  PurchaseCardsReducer,
  RechargeCardsReducer,
  RememberPasswordReducer,
  ResponsibleConsumptionReducer,
  UserReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    this.state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;
