
export default class Types {
  /** CLEAR BACK_OFFICE INFO **/
  static CLEAR_BACK_OFFICE_INFO                      = 'CLEAR_BACK_OFFICE_INFO';

  /** DELETE REQUEST MESSAGE */
  static DELETE_BACK_OFFICE_REQUEST_MESSAGE          = 'DELETE_BACK_OFFICE_REQUEST_MESSAGE'

  /** DELETE ORG */
  static DELETE_BACK_OFFICE_ORG_FAILED               = 'DELETE_BACK_OFFICE_ORG_FAILED';
  static DELETE_BACK_OFFICE_ORG_SUCCESS              = 'DELETE_BACK_OFFICE_ORG_SUCCESS';

  /** DELETE USER */
  static DELETE_BACK_OFFICE_USER_FAILED              = 'DELETE_BACK_OFFICE_USER_FAILED';
  static DELETE_BACK_OFFICE_USER_SUCCESS             = 'DELETE_BACK_OFFICE_USER_SUCCESS';

  /** GET BACK_OFFICE INFO **/
  static GET_BACK_OFFICE_ORG_DOWNLOAD_FAILED         = 'GET_BACK_OFFICE_ORG_DOWNLOAD_FAILED';
  static GET_BACK_OFFICE_ORG_INFO_FAILED             = 'GET_BACK_OFFICE_ORG_INFO_FAILED';
  static GET_BACK_OFFICE_ORG_INFO_SUCCESS            = 'GET_BACK_OFFICE_ORG_INFO_SUCCESS';
  static GET_BACK_OFFICE_REQUESTS_DOWNLOAD_FAILED    = 'GET_BACK_OFFICE_REQUESTS_DOWNLOAD_FAILED';
  static GET_BACK_OFFICE_REQUESTS_INFO_FAILED        = 'GET_BACK_OFFICE_REQUESTS_INFO_FAILED';
  static GET_BACK_OFFICE_REQUESTS_INFO_SUCCESS       = 'GET_BACK_OFFICE_REQUESTS_INFO_SUCCESS';
  static GET_BACK_OFFICE_USERS_DOWNLOAD_FAILED       = 'GET_BACK_OFFICE_USERS_DOWNLOAD_FAILED';
  static GET_BACK_OFFICE_USERS_INFO_FAILED           = 'GET_BACK_OFFICE_USERS_INFO_FAILED';
  static GET_BACK_OFFICE_USERS_INFO_SUCCESS          = 'GET_BACK_OFFICE_USERS_INFO_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_BACK_OFFICE_INFO                   = 'SET_BACK_OFFICE_INFO';

}
