
export default class Tags {

  /** DEL **/
  static DEL_CUSTOMER_ADDRESS                   = 'DEL_CUSTOMER_ADDRESS';
  static DEL_NOTIFICATION                       = 'DEL_NOTIFICATION';
  static DEL_BACK_OFFICE_ORG                    = 'DEL_BACK_OFFICE_ORG';

  /** GET **/
  static GET_BACK_OFFICE_ORG                    = 'GET_BACK_OFFICE_ORG';
  static GET_BACK_OFFICE_ORG_DOWNLOAD           = 'GET_BACK_OFFICE_ORG_DOWNLOAD';
  static GET_BACK_OFFICE_REQUESTS               = 'GET_BACK_OFFICE_REQUESTS';
  static GET_BACK_OFFICE_REQUESTS_DOWNLOAD      = 'GET_BACK_OFFICE_REQUESTS_DOWNLOAD';
  static GET_BACK_OFFICE_USERS                  = 'GET_BACK_OFFICE_USERS';
  static GET_BACK_OFFICE_USERS_DOWNLOAD         = 'GET_BACK_OFFICE_USERS_DOWNLOAD';
  static GET_CARDS                              = 'GET_CARDS';
  static GET_CARD_DETAILS                       = 'GET_CARD_DETAILS';
  static GET_CARD_TRANSACTIONS                  = 'GET_CARD_TRANSACTIONS';
  static GET_CHECK_CARD                         = 'GET_CHECK_CARD';
  static GET_DASHBOARD                          = 'GET_DASHBOARD';
  static GET_DOCUMENT_DOWNLOAD                  = 'GET_DOCUMENT_DOWNLOAD';
  static GET_NOTIFICATIONS                      = 'GET_NOTIFICATIONS';
  static GET_ORG                                = 'GET_ORG';
  static GET_PAPER_TICKET                       = 'GET_PAPER_TICKET';
  static GET_PAPER_TICKET_ONLINE                = 'GET_PAPER_TICKET_ONLINE';

  /** POST **/
  static POST_CARD_PIN                          = 'POST_CARD_PIN';
  static POST_CREATE_BACK_OFFICE_ORG            = 'POST_CREATE_BACK_OFFICE_ORG';
  static POST_DELETE_BACK_OFFICE_USER           = 'POST_DELETE_BACK_OFFICE_USER';
  static POST_DOCUMENTS                         = 'POST_DOCUMENTS';
  static POST_EDIT_USER                         = 'POST_EDIT_USER             ';
  static POST_EMAIL_COSTUMER_SUPPORT            = 'POST_EMAIL_COSTUMER_SUPPORT';
  static POST_LOGIN                             = 'POST_LOGIN';
  static POST_PURCHASE_CARDS                    = 'POST_PURCHASE_CARDS';
  static POST_RECHARGE_CARDS                    = 'POST_RECHARGE_CARDS';
  static POST_RESPONSIBLE_CONSUMPTION_DOWNLOAD  = 'POST_RESPONSIBLE_CONSUMPTION_DOWNLOAD';
  static POST_SEARCH                            = 'POST_SEARCH';

  /** PUT **/
  static PUT_CART_PRODUCTS                      = 'PUT_CART_PRODUCTS';
  static PUT_NOTIFICATIONS                      = 'PUT_NOTIFICATIONS';
  static PUT_EDIT_ORG                           = 'PUT_EDIT_ORG';


}
