import * as Types from './Types';
import {isDev} from '../../utils/Utils';
import {getPaperTicket, getPaperTicketOnline} from '../../services/api/API';
import {GET_PAPER_TICKET_ONLINE_FAILED, GET_PAPER_TICKET_ONLINE_SUCCESS} from "./Types";

export const apiGetPaperTicket = (entityId) => async(dispatch) => {

  dispatch(setPaperTicketState('isLoadingPaperTicket', true));

  await dispatch(getPaperTicket(
    entityId,
    (tag, response) => {
      if (isDev()) console.log('apiGetPaperTicket - Error: ', response);
      dispatch({ type: Types.GET_PAPER_TICKET_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetPaperTicket - Success: ', response);
      dispatch({
        type: Types.GET_PAPER_TICKET_SUCCESS,
        payload: response
      });
    })
  );

  dispatch(setPaperTicketState('isLoadingPaperTicket', false));
};

export const apiGetPaperTicketOnline = (entityId) => async(dispatch) => {
  dispatch(setPaperTicketState('isLoadingPaperTicket', true));

  await dispatch(getPaperTicketOnline(
      entityId,
      (tag, response) => {
        if (isDev()) console.log('apiGetPaperTicket - Error: ', response);
        dispatch({ type: Types.GET_PAPER_TICKET_ONLINE_FAILED });
      },
      (tag, response) => {
        if (isDev()) console.log('apiGetPaperTicket - Success: ', response);
        dispatch({
          type: Types.GET_PAPER_TICKET_ONLINE_SUCCESS,
          payload: response
        });
      })
  );

  dispatch(setPaperTicketState('isLoadingPaperTicket', false));
}

export const clearDataPaperTicket = () => async(dispatch) => {
  dispatch({
    type: Types.CLEAR_PAPER_TICKET,
  })
};

export const setPaperTicketState = (prop, value) => async(dispatch) => {
  dispatch({
    type: Types.SET_DATA_PAPER_TICKET,
    payload: {prop, value}
  })
};
