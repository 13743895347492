import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import {getCardTransactions, getTransactionsDownload} from '../../services/api/API';

export const apiGetCardTransactions = (id) => async (dispatch) => {
	dispatch(setCardTransactionsDataState({ prop: 'isLoadingGetTransactions', value: true }));
	await dispatch(getCardTransactions(
		id,
		(tag, response) => {
			if (isDev()) console.log('apiGetCardTransactions - Error: ', response);
			dispatch({ type: Types.GET_CARD_TRANSACTIONS_FAILED });
		},
		(tag, response) => {
			if (isDev()) console.log('apiGetCardTransactions - Success: ', response);
			dispatch({
				type: Types.GET_CARD_TRANSACTIONS_SUCCESS,
				payload: response
			});
			const yOffset = -250;
      const element = document.getElementById('tableResult');
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
      dispatch(setCardTransactionsDataState({ prop: 'idCardUserSelect', value: id }));
		}
	));
	dispatch(setCardTransactionsDataState({ prop: 'isLoadingGetTransactions', value: false }));
};

export const apiGetTransactionsDownload = (id) => async (dispatch) => {
	dispatch(setCardTransactionsDataState({ prop: 'isLoadingPostTranslationDownload', value: true }));
	await dispatch(getTransactionsDownload(
		id,
		(tag, response) => {
			if (isDev()) console.log('apiGetTransactionsDownload - Error: ', response);
			dispatch({ type: Types.GET_CARD_TRANSACTIONS_FAILED });
		},
		(tag, response) => {
			if (isDev()) console.log('apiGetTransactionsDownload - Success: ', response);
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
      const downloadLink = document.createElement("a");
      const fileName = `tarjeta_${id}`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
		}

	));
	dispatch(setCardTransactionsDataState({ prop: 'isLoadingPostTranslationDownload', value: false }));
};

export const clearDataCardTransactions = () => ({
  type: Types.CLEAR_DATA_CARD_TRANSACTIONS,
});


export const setCardTransactionsDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_CARD_TRANSACTIONS,
  payload: { prop, value }
});
