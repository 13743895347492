import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';

// Resources
import Routing from '../routing/Routing';
import { strings } from '../../resources/locales/i18n.js';

class LegalContainer extends Component {
  render() {
    return (
      <>
        <HeaderContainer />
        <section className="container wrapper wrapper--content pt-5 pb-5 col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8" style={{ left: 0 }}>
          <div className="card p-5 d-flex justify-content-between">
            <div className="input-top--left d-flex flex-column">
              <div className="title-mid normal text-center my-5">{strings('legalView.title')}</div>

              <span className="title-lite normal mt-2 mb-2">{strings('legalView.intro.title')}</span>
              <span className="normal">{strings('legalView.intro.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.object.title')}</span>
              <span className="normal">{strings('legalView.object.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.obligations.title')}</span>
              <span className="normal">{strings('legalView.obligations.text')}</span>
              <ol className='pt-4'>
                <li> <span className="normal">{strings('legalView.obligations.list1')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list2')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list3')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list4')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list5')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list6')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list7')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list8')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list9')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list10')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list11')}</span> </li>
                <li> <span className="normal">{strings('legalView.obligations.list12')}</span> </li>
              </ol>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.intellectualProperty.title')}</span>
              <span className="normal">{strings('legalView.intellectualProperty.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.licenses.title')}</span>
              <span className="normal">{strings('legalView.licenses.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.responsibilities.title')}</span>
              <span className="normal">{strings('legalView.responsibilities.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.links.title')}</span>
              <span className="title-lite normal mt-2 mb-2">{strings('legalView.links.subtitle1')}</span>
              <span className="normal">{strings('legalView.links.text1')}</span>
              <span className="title-lite normal mt-4 mb-2">{strings('legalView.links.subtitle2')}</span>
              <span className="normal">{strings('legalView.links.text2')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.cookies.title')}</span>
              <span className="normal">{strings('legalView.cookies.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.policy.title')}</span>
              <span className="normal">{strings('legalView.policy.text')} <Link to={Routing.policy} target="_blank" rel="noopener noreferrer">{strings('legalView.policy.privacyPolicy')}</Link> </span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.duration.title')}</span>
              <span className="normal">{strings('legalView.duration.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.generalities.title')}</span>
              <span className="normal">{strings('legalView.generalities.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('legalView.jurisdiction.title')}</span>
              <span className="normal">{strings('legalView.jurisdiction.text')}</span>

            </div>
          </div>
        </section>
      </>
    )
  }

  /** PRIVATE METHOD **/

}

export default LegalContainer;
