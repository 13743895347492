import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { getCards, getCardDetails, postSearch } from '../../services/api/API';

export const apiGetCardDetails = (id) => async (dispatch) => {
  dispatch(setCardsDataState({ prop: 'isLoadingCardDetails', value: true }));
  await dispatch(getCardDetails(
    id,
    (tag, response) => {
      if (isDev()) console.log('apiGetCardDetails - Error: ', response);
      dispatch({ type: Types.GET_CARD_DETAILS_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetCardDetails - Success: ', response);
      dispatch({
        type: Types.GET_CARD_DETAILS_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setCardsDataState({ prop: 'isLoadingCardDetails', value: false }));
};

export const apiGetCards = () => async (dispatch) => {
  dispatch(setCardsDataState({ prop: 'isLoadingCards', value: true }));
  await dispatch(getCards(
    (tag, response) => {
      if (isDev()) console.log('apiGetCards - Error: ', response);
      dispatch({ type: Types.GET_CARDS_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetCards - Success: ', response);
      dispatch({
        type: Types.GET_CARDS_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setCardsDataState({ prop: 'isLoadingCards', value: false }));
};

export const apiPostSearch = () => async (dispatch) => {
  dispatch(setCardsDataState({ prop: 'isLoadingPostSearch', value: true }));
  await dispatch(postSearch(
    (tag, response) => {
      if (isDev()) console.log('apiPostCard - Error: ', response);
      dispatch({ type: Types.GET_CARDS_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiPostCard - Success: ', response);
      dispatch({
        type: Types.GET_CARDS_SUCCESS,
        payload: response
      });

      const yOffset = -250;
      const tableTitle = document.getElementById('tableTitle');
      const element = tableTitle ? tableTitle : document.getElementById('tableResult');
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' })
      }

    }
  ));
  dispatch(setCardsDataState({ prop: 'isLoadingPostSearch', value: false }));
};

export const clearDataCards = () => ({
  type: Types.CLEAR_DATA_CARDS,
});

export const setCardsDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_CARDS,
  payload: { prop, value }
});
