import Types from "./Types";

const INITIAL_STATE = {
  cif: '',
  company: '',
  consult: '',
  consultType: '',
  email: '',
  error: [],
  isLoadingCostumerSupport: false,
  legalConsent: false,
  surnames: '',
  telephone: '',
  username: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR COSTUMER SUPPORT **/
    case Types.CLEAR_DATA_COSTUMER_SUPPORT:
      return { ...INITIAL_STATE, error: [] };

    /** FORM **/
    case Types.FORM_COSTUMER_SUPPORT_FAILED:
    return { ...state, error: action.payload };

    case Types.FORM_COSTUMER_SUPPORT_SUCCESS:
      return { ...state };

    /** UPDATE STATE **/
    case Types.SET_DATA_COSTUMER_SUPPORT:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
