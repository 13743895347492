import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { postDocuments, postDocumentsDownload } from '../../services/api/API';

export const apiPostDocuments = (setInitialPage) => async (dispatch, getState) => {
  const { documentsLimit } = getState().DocumentsReducer;
  dispatch(setDocumentsDataState({ prop: 'isLoadingPostDocuments', value: true }));
  await dispatch(postDocuments(
    (tag, response) => {
      if (isDev()) console.log('apiPostDocuments - Error: ', response);
      dispatch({ type: Types.POST_DOCUMENTS_FAILED, payload: response });
      const yOffset = -250;
      const element = document.getElementById('noResults');
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    },
    (tag, response) => {
      if (isDev()) console.log('apiPostDocuments - Success: ', response);
      dispatch({
        type: Types.POST_DOCUMENTS_SUCCESS,
        payload: { ...response, numPages: Math.ceil(response.totalResults / documentsLimit) }
      });
      setInitialPage ? setInitialPage() : '';
      const yOffset = -250;
      const element = document.getElementById('tableResult');
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' })
      }
    }
  ));
  dispatch(setDocumentsDataState({ prop: 'isLoadingPostDocuments', value: false }));
};

export const apiPostDocumentsDownload = (idDocument, name, openModal) => async (dispatch, getState) => {
  const { dataDocuments } = getState().DocumentsReducer;

  if (dataDocuments) {
    const dataDocumentsWithItemLoadingTrue = dataDocuments.map(document => {
      if (document.description === name) {
        document.isLoading = true;
      }
      return document;
    })
    dispatch(setDocumentsDataState({ prop: 'dataDocuments', value: dataDocumentsWithItemLoadingTrue }));
  }
  await dispatch(postDocumentsDownload(
    idDocument,
    (tag, response) => {
      if (isDev()) console.log('apiPostDocumentsDownload - Error: ', response);
      dispatch({ type: Types.POST_DOCUMENTS_FAILED });
      openModal ? openModal() : '';
    },
    (tag, response) => {
      if (isDev()) console.log('apiPostDocumentsDownload - Success: ', response);

      const linkSource = response;
      const downloadLink = document.createElement("a");
      const fileName = `${name}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  ));
  if (dataDocuments) {
    const dataDocumentsWithItemLoadingFalse = dataDocuments.map(document => {
      if (document.description === name) {
        document.isLoading = false;
      }
      return document;
    })
    dispatch(setDocumentsDataState({ prop: 'dataDocuments', value: dataDocumentsWithItemLoadingFalse }));
  }
};

export const clearDataDocuments = () => ({
  type: Types.CLEAR_DATA_DOCUMENTS,
});

export const setDocumentsDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_DOCUMENTS,
  payload: { prop, value }
});
