
export default class Types {
  /** CLEAR DASHBOARD INFO **/
  static CLEAR_DASHBOARD_INFO = 'CLEAR_DASHBOARD_INFO';

  /** GET DASHBOARD INFO **/
  static GET_DASHBOARD_INFO_FAILED = 'GET_DASHBOARD_INFO_FAILED';
  static GET_DASHBOARD_INFO_SUCCESS = 'GET_DASHBOARD_INFO_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_DASHBOARD_INFO = 'SET_DASHBOARD_INFO';
}
