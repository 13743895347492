export default class Types {
  /** CLEAR RECHARGE_CARDS **/
  static CLEAR_DATA_RECHARGE_CARDS = 'CLEAR_DATA_RECHARGE_CARDS';

  /** FORM RECHARGE CARDS **/
  static FORM_RECHARGE_CARDS_FAILED = 'FORM_RECHARGE_CARDS_FAILED';
  static FORM_RECHARGE_CARDS_SUCCESS = 'FORM_RECHARGE_CARDS_SUCCESS';

  /** POST EMAIL RECHARGE CARDS **/
  static POST_RECHARGE_CARDS_FAILED = 'POST_RECHARGE_CARDS_FAILED';
  static POST_RECHARGE_CARDS_SUCCESS = 'POST_RECHARGE_CARDS_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_RECHARGE_CARDS = 'SET_DATA_RECHARGE_CARDS';
}
