import { strings } from '../../resources/locales/i18n'
import {TypeUsers} from "../backOffice/BackOfficeManager";

export default class EditUserManager {

  static getUserAdminState(user) {
    return user && user.profile ? user.profile : TypeUsers.ASSISTANT
  }

  static getUserEmail(user) {
    return user && user.email ? user.email : null
  }

  static getUserFullName(user) {
    return user && user.name ? user.name : null
  }

  static getUserId(user) {
    let userIdFromEdit = user && user.Username ? user.Username : null;
    let userIdFromProfile = user && user.username ? user.username : null;
    let userId = userIdFromEdit ? userIdFromEdit : userIdFromProfile;
    return user && userId ? userId : null
  }

  static getUserIdSap(user) {
    return user && user.family_name ? user.family_name : null
  }

  static getUserMfa(data) {
    return data && data.locale ? strings('editUserView.form.yes') : strings('editUserView.form.no')
  }

  static getUserPhone(user) {
    let phone = ''
    if (user && user.phone_number) {
      phone = user.phone_number.replace('+34', '')
    }
    return user && user.phone_number ? phone : null;
  }
}
