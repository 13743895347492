import Types from "./Types";

const INITIAL_STATE = {
  uuid: '',
  days:1,
  dataCardsPin: null,
  excelFile: null,
  formType: 'cardsForm',
  errorRequest: '',
  errorCardsPin: false,
  isLoadingCardsPin: false,
  isLoadingTableDownload: false
};

export default (state = INITIAL_STATE, action) => {

	switch (action.type) {

    /** CLEAR CARD **/
    case Types.CLEAR_DATA_CARD_PIN:
      return { ...INITIAL_STATE };

      case Types.GET_CARD_PIN_FAILED:
        console.log("action.payload", action.payload)
        return { ...state, errorCardsPin: action.payload, dataCardsPin: null, };

    case Types.GET_CARD_PIN_SUCCESS:
      return { ...state, dataCardsPin: action.payload.outputCards, errorCardsPin: '' };


    /** UPDATE STATE **/
    case Types.SET_DATA_CARD_PIN:
      return { ...state, [action.payload.prop]: action.payload.value };

		default:
			return state;
	}
}
