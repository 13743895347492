
export default class PaperTicketManager {

  static getAfterPayText = (data) => {
    return data && data.afterPayText ? data.afterPayText : "";
  };

  static getAppliedPromotions = (data) => {
    return data && data.appliedPromotions ? data.appliedPromotions : "";
  };

  static getBalance = (data) => {
    return data && data.balance ? data.balance + " €" : "";
  };

  static getBillText = (data) => {
    return data && data.billText ? data.billText : "";
  };

  static getBpData = (data) => {
    return data && data.bpData ? data.bpData : "";
  };

  static getCif = (data) => {
    return data && data.cif ? data.cif : "";
  };

   static getCorporateName = (data) => {
    return data && data.corporateName ? data.corporateName : '';
  };

  static getDiscountAmount = (data) => {
    return data && data.discountAmount ? parseFloat(data.discountAmount).toLocaleString('es-ES',{ minimumFractionDigits: 2 })  : "";
  };

  static getDocumentText = (data) => {
    return data && data.documentText ? data.documentText : "";
  };

  static getExchangeAmount = (data) => {
    return data && data.exchangeAmount ? data.exchangeAmount : "";
  };

  static getGiftCard = (data) => {
    return data && data.giftCard ? data.giftCard : [];
  };

  static getGrandTotal = (data) => {
    return data && data.grandTotal ? parseFloat(data.grandTotal).toLocaleString('es-ES',{ minimumFractionDigits: 2 }) : "";
  };

   static getLogo = (data) => {
    return data && data.logo ? data.logo : "";
  };

   static getMerchantType = (data) => {
    return data && data.merchantType ? data.merchantType : "";
  };

   static getPaymentMethods = (data) => {
    return data && data.paymentMethods ? data.paymentMethods : "";
  };

   static getItems = (data) => {
    return data && data.items ? data.items : "";
  };

  static getImageCodeQr = (imagesBaseHost, imagesPaths, qrPaths, qrId) => {
    return `${imagesBaseHost}${imagesPaths}/${qrPaths}/${qrId}`;
  };

   static getTicketId = (data) => {
    return data && data.ticketId ? data.ticketId : "";
  };

  static getNumberCard = (data) => {
    if (data && data.number) {
      const number = data.number;
      return "*".repeat(number.length - 4) + number.slice(-4);
    }
    return "";
  };

  static getPaymentDetailText = (data) => {
    return data && data.paymentDetailText ? data.paymentDetailText : "";
  };

  static getPromotions = (data) => {
    return data && data.promotions ? data.promotions : "";
  };

   static getPurchaseDate = (data) => {
    return data && data.purchaseDate ? data.purchaseDate : "";
  };

   static getPurchaseHour = (data) => {
    return data && data.purchaseHour ? data.purchaseHour : "";
  };

  static getShopName = (data) => {
    return data && data.shopName ? data.shopName : "";
  };

  static getShopCode = (data) => {
    return data && data.shopCode ? data.shopCode : "";
  };

  static getShopPhone = (data) => {
    return data && data.shopPhone ? data.shopPhone : "";
  };

  static getSubtotal = (data) => {
    return data && data.subtotal ? parseFloat(data.subtotal).toLocaleString('es-ES',{ minimumFractionDigits: 2 }) : "";
  };

  static getTicketQr = (data) => {
    return data && data.ticketQr ? data.ticketQr : "";
  };

  static getOperatorId = (data) => {
    return data && data.operatorId ? data.operatorId : "";
  };

   static getIsReturn = (data) => {
    return data && data.isReturn ? data.isReturn : "";
  };

  static getItemName = (data) => {
    return data && data.name ? data.name : "";
  };

  static getItemQty = (data) => {
    return data && data.qty ? data.qty : "";
  };

  static getItemPrice = (data) => {
    return data && data.price ? parseFloat(data.price).toLocaleString('es-ES',{ minimumFractionDigits: 2 }) : "";
  };

  static getItemRowTotal = (data) => {
    return data && data.rowTotal ? parseFloat(data.rowTotal).toLocaleString('es-ES',{ minimumFractionDigits: 2 }) : "";
  };

  static getItemCount = (data) => {
    return data && data.itemCount ? data.itemCount : "";
  };

  static getItemDescription = (data) => {
    return data && data.description ? data.description : "";
  };

  static getItemAmount = (data) => {
    return data && data.amount ? parseFloat(data.amount).toLocaleString('es-ES',{ minimumFractionDigits: 2 }): "";
  };

  static getShippingDescription = (data) => {
    return data && data.shippingDescription ? data.shippingDescription : "";
  };

  static getShippingAmount = (data) => {
    return data && data.shippingAmount ? parseFloat(data.shippingAmount).toLocaleString('es-ES',{ minimumFractionDigits: 2 })  : "";
  };

 }
