
export default class Types {
  /** CLEAR CARDS **/
  static CLEAR_DATA_CARDS = 'CLEAR_DATA_CARDS';

  /** GET CARDS **/
  static GET_CARDS_FAILED = 'GET_CARDS_FAILED';
  static GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';

  /** GET CARDS DETAILS **/
  static GET_CARD_DETAILS_FAILED = 'GET_CARD_DETAILS_FAILED';
  static GET_CARD_DETAILS_SUCCESS = 'GET_CARD_DETAILS_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_CARDS = 'SET_DATA_CARDS';
}
