import React, { Component } from 'react';
import { connect } from 'react-redux';
import jsPDF from 'jspdf';
import {Link} from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// Actions
import {
  apiPostCardsPin,
  clearDataCardPin,
  setCardPinDataState
} from './CardsPinActions';
//Component
import {Loading} from '../../components';

import HeaderContainer from '../navegation/HeaderContainer';
//Resources
import Routing from '../routing/Routing';
import { strings } from '../../resources/locales/i18n.js';
import {objectSize} from '../../utils/Utils';


class CardsPinContainer extends Component {

  state = {
    modalIsShown: false,
    key: 0,
  }

  componentDidMount() {
    this._clearDataCardPin()
  }

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.cardsPin'), path: '#' }
    ]

    return (
      <>
        <HeaderContainer title={strings('viewName.cardsPin')} breadcrumbList={breadcrumbList} />
        <section className="container wrapper pt-5">
          <div className="row">
            <div className="col-12">
              {this.renderForm()}
            </div>
          </div>
        </section>

        {this.renderList()}
        {this.renderRequestModal()}

      </>
    )
  }

  renderForm(){
    const {days, isLoadingCardsPin, formType, excelFile} = this.props;

    return(
      <form id="CardsForm" onSubmit={(e) => this._apiPostCardsPin(e)} >

        <div className="mb-5">
          <div >
            <span className="title-mid d-block mb-3">{strings('cardsPin.sendCardList')}</span>
          </div>
          <div className="mb-3 d-flex">
            <div className="form-check mb-2 mr-5">
              <input
                className="form-check-input"
                type="radio"
                name='formType'
                id='cardsForm'
                value={'cardsForm'}
                checked={formType === 'cardsForm'}
                onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
              />
              <label className="form-check-label" htmlFor="Tarjetas">
                {strings('cardsPin.manually')}
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name='formType'
                id='uploadForm'
                value={'uploadForm'}
                checked={formType === 'uploadForm'}
                onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
              />
              <label className="form-check-label" htmlFor="Facturas">
                {strings('cardsPin.fromExcelFile')}
              </label>
            </div>
          </div>
        </div>

        { /** UUID **/}
        {formType === 'cardsForm' ? this.renderUiid() : this.renderUploadModule()}

        <div className="form-group form-group--label mb-4 col-2 p-0">
          <label htmlFor="user">{strings('label.numbersDays')}</label>
          <input
            style={{width: 200}}
            id={"days"}
            className="form-control"
            type="number"
            placeholder=""
            min={1}
            value={days}
            required
            onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
          />
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="form-group d-flex justify-content-left">
              <button
                type="submit"
                className="btn-primary">

                {isLoadingCardsPin ?
                  <Loading />
                  :
                   formType === 'uploadForm' ? strings('cardsPin.sendExcel') : strings('button.assignPin')
                }
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }


  renderList(){
    const {dataCardsPin,isLoadingTableDownload, errorCardsPin } = this.props;

    if (dataCardsPin && objectSize(dataCardsPin) > 0){
      return(
        <div id='tableResult'>
          <section className="container wrapper wrapper--content pt-4 pb-5">
            <div  className='pt-0 pb-4' style={{display:"flex", alignItems: 'center'}}>
              <div className="col-4 m-0 p-0 title-big text-capitalize">{strings('documentsView.results')}</div>
              <div className="col-4 m-0 p-0 pr-3">
                <Link to='#' onClick={() => { this._downloadExcel() }} className="btn-secundary">
                  {isLoadingTableDownload ?
                    <Loading color='#77BD1E'/>
                    :
                    <>
                      {strings('documentsView.downloadExcel')}
                      <span className="material-icons p-1">download</span>
                    </>
                  }
                  </Link>
              </div>

              <div className="col-4 m-0 p-0 pl-3">
                <Link to='#' onClick={() => { this._downloadPDF() }} className="btn-secundary">
                  {isLoadingTableDownload ?
                      <Loading color='#77BD1E'/>
                      :
                      <>
                        {strings('documentsView.downloadPDF')}
                        <span className="material-icons p-1">download</span>
                      </>
                  }
                </Link>
              </div>
            </div>

            <div id='tablePin' className="row module-table">
              <div className="col-12 mb-md-0">
                <div className="card p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{strings('title.card')}</th>
                          <th>{strings('title.code')}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.renderListItem()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
    }

    if (errorCardsPin && errorCardsPin.length > 0){
      return (
        <section className="container wrapper wrapper--content pt-4 pb-5">
          <p>{errorCardsPin}</p>
        </section>
      )
    }

    return <></>
  }


  renderListItem(){
    const {dataCardsPin} = this.props;

    if (dataCardsPin && objectSize(dataCardsPin) > 0){
      return Object.keys(dataCardsPin).map((key) =>{
        return(
          <tr className="cold-12">
            <th>{key}</th>
            <th>{dataCardsPin[key]}</th>
          </tr>
        )
      })
    }

    return(
      <></>
    )
  }

  renderUiid() {
    const {uuid} = this.props;
    return(
      <div className="form-group form-group--label mb-4">
        <label htmlFor="user">{strings('placeholder.cardNumber')}</label>
        <input
          id={"uuid"}
          className="form-control"
          type="text"
          placeholder=""
          value={uuid}
          required
          onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
        />
        <small style={{ lineHeight: 1 }}>{strings('documentsView.documentIdDescriptionOptional')}</small>
      </div>
    )
  }

  renderUploadModule() {
    const { excelFile, isLoadingRechargeCards } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <p className="mt-2">{strings('rechargeCardsView.uploadAFile')}</p>
            {excelFile ?
              <div className="col-12 mt-4 mb-3 p-0 d-flex align-items-center" style={{ maxWidth: 400 }}>
                <span className="material-icons p-1">description</span>
                <span>{excelFile.name}</span>
                <button className="close ml-auto" aria-label="Close" onClick={() => { this._deleteFile() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              : <div />}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group d-flex justify-content-start">
              <input
                key={this.state.key}
                id="file"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => this._handleUploadChange(e)}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              {excelFile ?
                <></>
                :
                <div className="btn-primary mb-5" onClick={(e) => this._uploadFile(e)}>
                  {isLoadingRechargeCards ? <Loading /> :
                    <>
                      {strings('rechargeCardsView.selectFile')}
                    </>}
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  renderRequestModal() {
    const { confirmationRequest, errorRequest } = this.props;
    const animationBgStyle = this.state.modalIsShown ? {
      opacity: 0.8
    } : {}
    const animationModalStyle = this.state.modalIsShown ? {
      marginTop: -50,
      opacity: 1
    } : {}
    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="confirmationRequest">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ marginTop: -180, opacity: 0, transition: 'opacity 0.4s ease-out, margin-top 0.4s ease-out', ...animationModalStyle }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this.renderRequestModal() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body d-flex justify-content-center row px-5">
                <div className="row justify-content-center">
                  <div className="col-12 mb-4 text-center">
                    <span className="material-icons icon-thanks">
                      {confirmationRequest ? 'send' : 'error_outline'}
                    </span>
                    <span className="title-big d-block mb-3">{confirmationRequest ? strings('rechargeCardsView.requestSent') : strings('rechargeCardsView.error')}</span>
                    <p>{confirmationRequest || errorRequest}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
                <button type="button" className="btn btn-primary mb-3" onClick={() => { this._toggleModal() }}>
                  {strings('button.accept')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{
          opacity: 0, zIndex: -1,
          transition: 'opacity 0.4s ease-out',
          ...animationBgStyle
        }} />
      </div>
    )
  }

  _toggleModal() {
    const confirmationRequest = document.getElementById('confirmationRequest');
    if (confirmationRequest.classList.contains('invisible')) {
      confirmationRequest.classList.remove('invisible')
      this.setState({ modalIsShown: true })
    } else {
      confirmationRequest.classList.add('invisible')
      this.setState({ modalIsShown: false })
    }
  }

  /* PRIVATE METHOD */
  _apiPostCardsPin = (e) => {
    e.preventDefault();
    this.props.apiPostCardsPin(() => this._toggleModal())
  }

  _clearDataCardPin = () => {
    this.props.clearDataCardPin();
  }

  _deleteFile() {
    this._handleValueChange('excelFile', null)
    this._getRandomKey();
  }

  async _downloadExcel() {
    const {dataCardsPin} = this.props;

    const parseDataCardsPin = [{ key: 'Tarjeta', value: 'Código' }];
    let maxWidthCard = 20
    let maxWidthValue = 5


    Object.keys(dataCardsPin).map((key,indexKey) => {
      parseDataCardsPin.push({ key: key, value: dataCardsPin[key] })

      if (key.length > maxWidthCard) {
        maxWidthCard = key.length;
      }

      if (dataCardsPin[key].length > maxWidthValue) {
        maxWidthValue = dataCardsPin[key].length;
      }

    })

    let wscols = [
      { width: maxWidthCard + 2, wpx: 20},  // first column
      { width: maxWidthValue + 2 },  // second column
    ];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(parseDataCardsPin, {skipHeader: true });
    ws["!cols"] = wscols;
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, strings('viewName.cardsPin') + fileExtension);

  }

  async _downloadPDF() {
    this._handleValueChange('isLoadingTableDownload', true)

    const {dataCardsPin} = this.props;
    const pdf = new jsPDF({ orientation: 'h' });

    let index = 0

    if (dataCardsPin && objectSize(dataCardsPin) > 0){
      Object.keys(dataCardsPin).map((key,indexKey) => {

        if (index === 0){
          //Text Title
          pdf.setFontSize(20);
          pdf.setTextColor(175,175,175);

          pdf.text('Tarjeta', 10, 20);
          pdf.text('Código', 175, 20);

          //Text Normal
          pdf.setFontSize(16);
          pdf.setTextColor(0,0,0);
          pdf.setDrawColor(175, 175, 175);
          pdf.setFillColor(255,255,200);
        }

        pdf.text(key, 10, index*10 + 30);
        pdf.text(dataCardsPin[key], 175, index * 10  + 30);

        if (index % 2 === 0 && Object.keys(dataCardsPin).length !== (indexKey +1) ) {
          pdf.setTextColor(200,200,200);
          pdf.setFillColor(200,200,200);
          pdf.rect(10, index*10 + 33, 190, index * 10  + 33, 'F');
          pdf.setFillColor(0,0,0);
          pdf.setTextColor(0,0,0);
        }

        if ((index % 2 !== 0 && index !== 0) || index === 24 || Object.keys(dataCardsPin).length === (indexKey +1) ) {
          pdf.setTextColor(255, 255, 255);
          pdf.setFillColor(255,255,255);
          pdf.rect(0, index*10 + 33, 200, index * 10  + 33, 'F');
          pdf.setFillColor(0,0,0);
          pdf.setTextColor(0,0,0);
        }

        index ++;

        if (index % 25 === 0){
          pdf.addPage();
          index = 0;
        }

      })
    }

    pdf.save(strings('viewName.cardsPin'));

    this._handleValueChange('isLoadingTableDownload', false)
  }

  _handleValueChange = (prop, value) => {
    this.props.setCardPinDataState({ prop, value });
  }

  _handleUploadChange(e) {
    const excelFile = e.target.files[0];
    this._handleValueChange('excelFile', excelFile);
    this._getRandomKey();
  }

  _getRandomKey() {
    this.setState({ key: Math.random() })
  }

  _uploadFile() {
    const input = document.getElementById("file")
    input.click()
  }
}
const mapStateToProps = ({ CardsPinReducer }) => {
  const { uuid, days, formType, excelFile, dataCardsPin, isLoadingCardsPin, isLoadingTableDownload, errorCardsPin, errorRequest } = CardsPinReducer;

  return {
    uuid, days, formType, excelFile, dataCardsPin, isLoadingCardsPin, isLoadingTableDownload, errorCardsPin, errorRequest
  };
};

const mapStateToPropsAction = {
  apiPostCardsPin,
  clearDataCardPin,
  setCardPinDataState
};

export default connect(mapStateToProps, mapStateToPropsAction)(CardsPinContainer);
