import Types from "./Types";

// Actions
// import { clearCognitoResponse } from '../../adminUser/AdminUserAction.js';
import { apiPostLoginFromConfirmation, clearDataLogin } from '../login/LoginActions';

// Resources
import { Auth } from 'aws-amplify';
import FormValidationManager from '../../../utils/managers/FormValidationManager';
import { isDev } from '../../../utils/Utils.js';
import { strings } from "../../../resources/locales/i18n";

// Services
export const apiConfirmAccount = (redirectToLogin, redirectSuccess) => async (dispatch, getState) => {
  const { confirmationCode } = getState().ConfirmAccountReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formConfirmationCode({ confirmationCode });
  if (error) {
    return dispatch({
      type: Types.CONFIRMATION_FAILED,
      payload: error
    });
  }
  dispatch(setConfirmationState({ prop: 'error', value: [] }));

  // -- 2. Request
  const { username, password } = getState().LoginReducer;

  dispatch(setConfirmationState({ prop: 'isLoadingConfirmation', value: true }));
  await Auth.confirmSignUp(username, confirmationCode)
    .catch((error) => {
      dispatch(responseError(error));
    })
    .then((success) => {
      if (success) {
        if (isDev()) console.log('successful confirmation', success);
        dispatch(responseSuccess({ username, password }, redirectToLogin, redirectSuccess));
      }
    })
  dispatch(setConfirmationState({ prop: 'isLoadingConfirmation', value: false }));
}

export const clearDataConfirmation = () => ({
  type: Types.CLEAR_DATA_CONFIRMATION,
});

export const resendConfirmationCode = () => async (dispatch, getState) => {
  const { username } = getState().LoginReducer

  await Auth.resendSignUp(username)
    .catch((error) => {
      if (isDev()) console.log('error', error);
      dispatch(responseError(error))
    })
    .then((success) => {
      if (isDev()) console.log('successful sent', success);
      dispatch(setConfirmationState({
        prop: 'requestMessage',
        value: strings('createUserView.confirmationSuccess')
      }));
    })
}

export const responseError = (error) => async (dispatch) => {
  // {
  //   code: "NotAuthorizedException"
  //   message: "User cannot be confirmed. Current status is CONFIRMED"
  //   name: "NotAuthorizedException"
  // }
  // {
  //   code: "CodeMismatchException"
  //   message: "Invalid verification code provided, please try again."
  //   name: "CodeMismatchException"
  // }
  // {
  //   message: "Confirmation code cannot be empty"
  //   name: "AuthError"
  // }
  // {
  //   code: "InvalidParameterException"
  //   message: "User is already confirmed."
  //   name: "InvalidParameterException"
  // }
  // {
  //   code: "CodeMismatchException"
  //   message: "Invalid verification code provided, please try again."
  //   name: "CodeMismatchException"
  // }
  // {
  //   code: "NotAuthorizedException"
  //   message: "Incorrect username or password."
  //   name: "NotAuthorizedException"
  // }
  if (error) {
    if (isDev()) console.log('error', error);
    dispatch(setConfirmationState({
      prop: 'requestMessage',
      value: ''
    }));
    dispatch(setConfirmationState({
      prop: 'error',
      value: [{
        key: "request",
        value: error.message
      }]
    }));
  }
};

export const responseSuccess = ({ username, password }, redirectToLogin, redirectSuccess) => async (dispatch) => {
  await dispatch(apiPostLoginFromConfirmation({ username, password }, redirectToLogin, redirectSuccess));
  dispatch(clearDataConfirmation());
  // dispatch(clearDataLogin());
}

export const setConfirmationState = ({ prop, value }) => ({
  type: Types.SET_CONFIRMATION_STATE,
  payload: { prop, value }
});

export const showMessageAfterRedirectInLogin = ({ prop, value }) => async (dispatch) => {
  dispatch(
    setLoginState({ prop, value })
  )
  setTimeout(() => dispatch(
    setLoginState({ prop, value: '' })
  ), 4000)
}

export const validateOnBlurConfirmation = (inputType) => async (dispatch, getState) => {
  const { confirmationCode, error } = getState().ConfirmAccountReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formConfirmationCode({ confirmationCode });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.CONFIRMATION_FAILED,
    payload: error.slice()
  });
}
