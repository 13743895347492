export default class Types {

  /** CLEAR DATA TRANSACTIONS **/
  static CLEAR_DATA_CARD_PIN = 'CLEAR_DATA_CARD_PIN';

  /** POST CARD PIN **/
  static GET_CARD_PIN_FAILED = 'GET_CARD_PIN_FAILED';
  static GET_CARD_PIN_SUCCESS = 'GET_CARD_PIN_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_CARD_PIN = 'SET_DATA_CARD_PIN';

}
