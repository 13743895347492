import Types from "./Types";

// Resources
import { strings } from '../../resources/locales/i18n.js';

// Services
import { isDev } from '../../utils/Utils';
import {
  deleteBackOfficeOrg,
  deleteBackOfficeUser,
  getBackOfficeOrgDownload,
  getBackOfficeOrgInfo,
  getBackOfficeRequestsDownload,
  getBackOfficeRequestsInfo,
  getBackOfficeUsersDownload,
  getBackOfficeUsersInfo
} from '../../services/api/API';

export const apiGetBackOfficeOrgInfo = () => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeOrgInfo', value: true }));
  await dispatch(getBackOfficeOrgInfo(
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeOrgInfo - Error: ', response);
      dispatch({ type: Types.GET_BACK_OFFICE_ORG_INFO_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeOrgInfo - Success: ', response);
      dispatch({
        type: Types.GET_BACK_OFFICE_ORG_INFO_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeOrgInfo', value: false }));
};

export const apiGetBackOfficeRequestsInfo = () => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeRequestsInfo', value: true }));
  await dispatch(getBackOfficeRequestsInfo(
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeRequests - Error: ', response);
      dispatch({ type: Types.GET_BACK_OFFICE_REQUESTS_INFO_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeRequests - Success: ', response);
      dispatch({
        type: Types.GET_BACK_OFFICE_REQUESTS_INFO_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeRequestsInfo', value: false }));
};

export const apiGetBackOfficeUsersInfo = () => async (dispatch, getState) => {
  const { usersLimit } = getState().BackOfficeReducer
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeUsersInfo', value: true }));
  await dispatch(getBackOfficeUsersInfo(
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeUsersInfo - Error: ', response);
      dispatch({ type: Types.GET_BACK_OFFICE_USERS_INFO_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeUsersInfo - Success: ', response);

      dispatch({
        type: Types.GET_BACK_OFFICE_USERS_INFO_SUCCESS,
        payload: { ...response }
      });
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeUsersInfo', value: false }));
};

export const apiGetDownloadOrg = () => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingGetOrgDownload', value: true }));
  await dispatch(getBackOfficeOrgDownload(
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeOrgDownload - Error: ', response);
      dispatch({
        type: Types.GET_BACK_OFFICE_ORG_DOWNLOAD_FAILED,
      });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeOrgDownload - Success: ', response);
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
      const downloadLink = document.createElement("a");
      const fileName = `orgs_portal_cliente`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingGetOrgDownload', value: false }));
};

export const apiGetDownloadRequests = () => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingGetRequestsDownload', value: true }));
  await dispatch(getBackOfficeRequestsDownload(
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeRequestsDownload - Error: ', response);
      dispatch({
        type: Types.GET_BACK_OFFICE_REQUESTS_DOWNLOAD_FAILED,
      });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeRequestsDownload - Success: ', response);
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
      const downloadLink = document.createElement("a");
      const fileName = `peticiones_portal_cliente`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingGetRequestsDownload', value: false }));
};

export const apiGetDownloadUsers = () => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingGetUsersDownload', value: true }));
  await dispatch(getBackOfficeUsersDownload(
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeUsersDownload - Error: ', response);
      dispatch({
        type: Types.GET_BACK_OFFICE_USERS_DOWNLOAD_FAILED,
      });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetBackOfficeUsersDownload - Success: ', response);
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
      const downloadLink = document.createElement("a");
      const fileName = `usuarios_portal_cliente`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingGetUsersDownload', value: false }));
};

export const apiDeleteOrg = (orgSapId, openRequestModal) => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeDeleteOrg', value: true }));
  await dispatch(deleteBackOfficeOrg(
    orgSapId,
    (tag, response) => {
      if (isDev()) console.log('apiDeleteBackOfficeOrg - Error: ', response);
      dispatch({
        type: Types.DELETE_BACK_OFFICE_ORG_FAILED,
        payload: ['error', strings('backOfficeView.deleteOrg.error')]
      });
      openRequestModal();
    },
    (tag, response) => {
      if (isDev()) console.log('apiDeleteBackOfficeOrg - Success: ', response);
      dispatch({
        type: Types.DELETE_BACK_OFFICE_ORG_SUCCESS,
        payload: ['success', strings('backOfficeView.deleteOrg.success')]
      });
      dispatch(apiGetBackOfficeOrgInfo())
      dispatch(apiGetBackOfficeUsersInfo())
      openRequestModal();
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeDeleteOrg', value: false }));
};

export const apiDeleteUser = (userId, openRequestModal) => async (dispatch) => {
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeDeleteUser', value: true }));
  await dispatch(deleteBackOfficeUser(
    userId,
    (tag, response) => {
      if (isDev()) console.log('apiDeleteBackOfficeUser - Error: ', response);
      dispatch({
        type: Types.DELETE_BACK_OFFICE_USER_FAILED,
        payload: ['error', strings('backOfficeView.deleteUser.error')]
      });
      openRequestModal();
    },
    (tag, response) => {
      if (isDev()) console.log('apiDeleteBackOfficeUser - Success: ', response);
      dispatch({
        type: Types.DELETE_BACK_OFFICE_USER_SUCCESS,
        payload: ['success', strings('backOfficeView.deleteUser.success')]
      });
      dispatch(apiGetBackOfficeUsersInfo())
      openRequestModal();
    }
  ));
  dispatch(setBackOfficeInfoDataState({ prop: 'isLoadingBackOfficeDeleteUser', value: false }));
};

export const clearBackOfficeInfo = () => ({
  type: Types.CLEAR_BACK_OFFICE_INFO,
});

export const deleteRequestMessage = () => ({
  type: Types.DELETE_BACK_OFFICE_REQUEST_MESSAGE,
});

export const setBackOfficeInfoDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_BACK_OFFICE_INFO,
  payload: { prop, value }
});
