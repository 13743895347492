
export default class Types {
  /** CLEAR DOCUMENTS **/
  static CLEAR_DATA_DOCUMENTS = 'CLEAR_DOCUMENTS_BILLS';

  /** POST DOCUMENTS **/
  static POST_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';
  static POST_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_DOCUMENTS = 'SET_DATA_DOCUMENTS';
}
