import React, { Component } from 'react';

class TextInput extends Component {

  state = {
    enableOnBlur: false,
  }

  render() {
    const {
      title, id, name, type, placeholder,
      onChange, value, onKeyDown, error, readOnly, inputType, smallText } = this.props

    const { enableOnBlur } = this.state
    
    const isReadOnly = readOnly ? true : false;
    let hasError = false;
    let hasErrorRequest = false;
    let elementError = '';

    if (error) {
      error.map((item) => {
        if (item.key === id) {
          hasError = true;
          return elementError = item;
        }
        if (item.key === 'request') {
          hasErrorRequest = true;
        }
      });
    }

    if (inputType === 'textarea') {
      return (
        <>
          <textarea
            id={id}
            className={"form-control " + (hasError ? "input-error" : "") + (!hasError && enableOnBlur && !hasErrorRequest && value.length > 0 ? "input-succes" : "")}
            style={{ height: "200px" }}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            onBlur={(e) => this._onBlur(e)}
            onKeyDown={(e) => onKeyDown(e, id)}
          />
          {this.showError(hasError, elementError)}
        </>
      )
    }
    return (
      <div className="form-group form-group--label mb-4">
        <label htmlFor="password">{title}</label>
        <div className="inner-addon right-addon">
          {hasError && !isReadOnly ? <i className="material-icons icon text-danger">error_outline</i> : <div />}
          {!hasError && enableOnBlur && !hasErrorRequest && value && value.length > 0 && !isReadOnly ? <i className="material-icons icon text-success">check</i> : <div />}
          <input
            id={id}
            name={name}
            className={"form-control " + (hasError && !isReadOnly ? "input-error" : "") + (!hasError && enableOnBlur && !hasErrorRequest && value && value.length > 0 && !isReadOnly ? "input-succes" : "")}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            onBlur={(e) => this._onBlur(e)}
            onKeyDown={(e) => onKeyDown(e, id)}
            readOnly={isReadOnly}
            onFocus={(e) => this._onFocus()}
          />
        </div>

        {this.showError(hasError, elementError, isReadOnly)}
        { smallText ?
          <small style={{ lineHeight: 1 }}>{smallText}</small>
          : <div />
        }
      </div>
    );
  }

  showError(hasError, elementError, isReadOnly) {
    if (hasError && elementError && !isReadOnly) {
      return (
        <div style={{ fontSize: "12px", color: "#DE4150" }}>{elementError.value}</div>
      )
    }
    return (<div />)
  }

  /* PRIVATE METHOD */
  _onBlur(e) {
    const { onBlur } = this.props;
    this.setState({ enableOnBlur: true });
    onBlur(e)
  }

  _onFocus(e) {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    } else {
      return;
    }
  }
}

export { TextInput };
