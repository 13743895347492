import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { checkCard, postRechargeCards } from '../../services/api/API';
import { strings } from '../../resources/locales/i18n';
import FormValidationManager from '../../utils/managers/FormValidationManager.js';


export const apiCheckCard = (id, value) => async (dispatch, getState) => {
  const { error } = getState().RechargeCardsReducer;
  // -- 2. Request
  dispatch(setRechargeCardsDataState({ prop: 'isLoadingRechargeCards', value: true }));
  await dispatch(checkCard(
    value,
    (tag, response) => {
      if (isDev()) console.log('apiFormRechargeCards - Error: ', response);
      dispatch(responseError(response));
    },
    async (tag, response) => {
      if (isDev()) console.log('apiFormRechargeCards - Success: ', response);
      if (response.isValid === false) {
        const errorCopy = [...error]
        console.log(errorCopy)
        await errorCopy.push({ key: id, value: 'No es una tarjeta válida' })
        dispatch(setRechargeCardsDataState({
          prop: 'error',
          value: errorCopy
        }));
      } else{
        dispatch(setRechargeCardsDataState({
          prop: 'error',
          value: []
        }));
      }
    }
  ))
  dispatch(setRechargeCardsDataState({ prop: 'isLoadingRechargeCards', value: false }));
};

export const apiPostRechargeCards = (toggleModal) => async (dispatch, getState) => {
  const { rechargeCardsData } = getState().RechargeCardsReducer;

  // --- 1. Validate form on RechargeCards page

  const error = await rechargeCardsData.map((currentItem, index) => {
    const inputCardIdToVerify = currentItem.cardId;
    const inputError = FormValidationManager.formRechargeCards({ inputCardIdToVerify, index });
    if (inputError !== null) {
      return inputError[0]
    } else {
      return
    }
  }).filter(item => !!item)

  if (error && error.length > 0) {
    return dispatch({
      type: Types.FORM_RECHARGE_CARDS_FAILED,
      payload: error
    });
  }
  dispatch(setRechargeCardsDataState({ prop: 'error', value: [] }));
  dispatch(setRechargeCardsDataState({ prop: 'errorRequest', value: '' }));

  // -- 2. Request
  dispatch(setRechargeCardsDataState({ prop: 'isLoadingRechargeCards', value: true }));
  await dispatch(postRechargeCards(
    { formType: 'cards' },
    (tag, response) => {
      if (isDev()) console.log('apiFormRechargeCards - Error: ', response);
      dispatch(responseError(response, toggleModal));
    },
    (tag, response) => {
      if (isDev()) console.log('apiFormRechargeCards - Success: ', response);
      dispatch(responseSuccess(response, toggleModal));
    }
  ))
  dispatch(setRechargeCardsDataState({ prop: 'isLoadingRechargeCards', value: false }));
};

export const apiPostRechargeExcel = (toggleModal) => async (dispatch, getState) => {
  const { excelFile } = getState().RechargeCardsReducer;

  // -- 1. Check file size
  if (excelFile && excelFile.size > 2000000) {
    if (isDev()) console.log('fileSize - Error: ', `${excelFile.size} es superior a 2Mb`);
    dispatch(setRechargeCardsDataState({ prop: 'errorRequest', value: `${excelFile.name} es superior a 2Mb. Prueba con un archivo más pequeño` }));
    toggleModal();
    return;
  }
  // -- 2. Convert to base64
  const getBase64ExcelInfo = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  const base64Info = await getBase64ExcelInfo(excelFile)
  const base64String = base64Info.substr(base64Info.indexOf(',') + 1)

  // -- 3. Request
  dispatch(setRechargeCardsDataState({ prop: 'isLoadingRechargeCards', value: true }));
  await dispatch(postRechargeCards(
    {
      formType: 'excel',
      base64String: base64String
    },
    (tag, response) => {
      if (isDev()) console.log('apiFormRechargeCards - Error: ', response);
      dispatch(responseError(response, toggleModal));
    },
    (tag, response) => {
      if (isDev()) console.log('apiFormRechargeCards - Success: ', response);
      dispatch(responseSuccess(response, toggleModal));
    }
  ))
  dispatch(setRechargeCardsDataState({ prop: 'isLoadingRechargeCards', value: false }));
};

export const clearDataRechargeCards = () => ({
  type: Types.CLEAR_DATA_RECHARGE_CARDS,
});

export const setRechargeCardsDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_RECHARGE_CARDS,
  payload: { prop, value }
});

export const setRechargeCardsElementDataState = ({ prop, value }, indexPosition) => async (dispatch, getState) => {
  const { rechargeCardsData } = getState().RechargeCardsReducer;
  const cloneRechargeCardsData = rechargeCardsData.map((item, idx) => {

    const propWithoutId = prop.indexOf('-') !== -1 ? prop.slice(0, prop.indexOf('-')) : prop
    if (idx === indexPosition) {
      return {
        ...item,
        [propWithoutId]: value
      }
    }
    return item
  });
  dispatch(setRechargeCardsDataState({ prop: 'rechargeCardsData', value: cloneRechargeCardsData }));
}

export const responseError = (error, toggleModal) => async (dispatch) => {
  if (error && error.message) {
    dispatch(setRechargeCardsDataState({
      prop: 'errorRequest',
      value: error.message
    }));
    toggleModal()
  }
};

export const responseSuccess = (response, toggleModal) => async (dispatch) => {
  dispatch(setRechargeCardsDataState({
    prop: 'confirmationRequest',
    value: response.message
  }));
  toggleModal ? toggleModal() : null;
}

export const validateOnBlurRechargeCards = (inputType, index) => async (dispatch, getState) => {
  const { rechargeCardsData, error } = getState().RechargeCardsReducer;
  let hasError = false
  let errorItem = null
  let indexError = null
  let errorForm = null;

  // --- 1. Get input cardId in array to validate using index
  const inputCardIdToVerify = rechargeCardsData[index].cardId

  // --- 2. Validate form on RechargeCards pag
  errorForm = await FormValidationManager.formRechargeCards({ inputCardIdToVerify, index });

  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }

  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.FORM_RECHARGE_CARDS_FAILED,
    payload: error.slice()
  });
}