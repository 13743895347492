import React, { Component } from 'react';

const AlertContainer = (props) => {
  const { error, id, className, style, message } = props

  let hasError = false;
  let elementError = '';

  if (error) {
    error.map((item) => {
      if (item.key === id) {
        hasError = true;
        return elementError = item.value;
      }
    });
  }

  if (!elementError && !message) {
    return (<div />)
  }

  if (className.indexOf('alert-success') !== -1) {
    return (
      <div className={"alert " + className} style={style} role="alert">
        {message}
      </div>
    )
  }

  if (className.indexOf('alert-danger') !== -1) {
    return (
      <div className={"alert " + className} style={style} role="alert">
        {elementError}
      </div>
    )
  }
  return <div />

};

export { AlertContainer }
