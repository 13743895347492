import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import {
  apiCreateUser,
  clearDataSignup,
  setSignupState,
  validateOnBlurSignup,
} from './CreateUserActions'

// Components
import { AlertContainer } from 'src/components';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { ModalContainer } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';
import {TypeUsers} from "../backOffice/BackOfficeManager";

class CreateUserContainer extends Component {

  state = {
    isModalShown: false,
  }

  componentDidMount() {
    this._clearDataSignup();
  }
  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.backOffice'), path: Routing.backOffice },
      { name: strings('viewName.createUser'), path: Routing.nowhere },
    ]
    return (
      <>
        <HeaderContainer title={strings('viewName.backOffice')} breadcrumbList={breadcrumbList} />
        <ModalContainer
          closeAction={() => this._toggleModal()}
          icon='check'
          message={strings('createUserView.modal.message')}
          modalId='confirmSignInResponse'
          isModalShown={this.state.isModalShown}
          primaryAction={() => this._toggleModal()}
          primaryButtonText={strings('createUserView.modal.primaryButton')}
          title={strings('createUserView.modal.title')}
        />
        <section className="container wrapper wrapper--content d-flex pt-5 pb-5 justify-content-center">
          <div className="col-12 col-lg-9 mb-4 mb-md-0">
            <div className="card pl-5 pr-5 pb-3 pt-2">
              {this.renderSignupForm()}
            </div>
          </div>
        </section>
      </>
    )
  }

  renderSignupForm() {
    const
      { backOfficeOrgData, email, error, isLoadingGetCode, mfa, organization, phone, username, surnames } = this.props;

    return (
      <form id="signUpForm" onSubmit={(e) => this._apiCreateUser(e)} action="" className="w-100 mt-5">
        <AlertContainer
          error={error}
          id={'request'}
          className={'alert-danger'}
        />
        <div className="row pb-4">
          <div className="col-12 pb-5">
            <span className="title-mid d-block">{strings('createUserView.userData')}</span>
          </div>
          { /** NAME */}
          <div className="col-12 col-sm-6">
            <TextInput
              title={strings('createUserView.form.name')}
              id="username"
              name="username"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={username}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          { /** SURNAMES */}
          <div className="col-12 col-sm-6">
            <TextInput
              title={strings('createUserView.form.surname')}
              id="surnames"
              name="surnames"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={surnames}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          { /** EMAIL */}
          <div className="col-12 col-sm-6">
            <TextInput
              title={strings('createUserView.form.email')}
              id="email"
              name="email"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={email}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          { /** PHONE */}
          <div className="col-12 col-sm-6">
            <TextInput
              title={strings('createUserView.form.phone')}
              id="phone"
              name="phone"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={phone}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          { /** ORGANIZATION */}
          <div className="col-12 col-sm-6">
            <select
              required
              id={'organization'}
              className="form-group"
              aria-label="Default select example"
              value={organization}
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
            >
              <option value=''>{strings('createUserView.form.chooseOrganization')}</option>

              {backOfficeOrgData.map((org, idx) => {
                return (
                  <option value={org.id_sap}>{org.name}</option>
                )
              })}
            </select>
          </div>
          <div className="row col-12">

            { /** MFA RADIO INPUTS */}
            <div className="col-12 col-md-6 mt-4 mb-3">
              <div >
                <span className="title-mid d-block mb-3">{strings('createUserView.form.authenticate')} </span> </div>
              <div className="mb-3 d-flex">
                <div className="form-check mb-2 mr-5 mr-md-3 mr-xl-5">
                  <input
                    required
                    className="form-check-input"
                    type="radio"
                    name={'mfa'}
                    id={'mfa-1'}
                    value={''}
                    onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="mfa-1">
                    {strings('createUserView.form.simpleAuth')}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    required
                    className="form-check-input"
                    type="radio"
                    name={'mfa'}
                    id={'mfa-2'}
                    value={'mfa'}
                    onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="mfa-2">
                    {strings('createUserView.form.bySMS')}
                  </label>
                </div>
              </div>
            </div>

            { /** ADMIN RADIO INPUTS */}
            <div className="col-12 col-md-6 mt-4 mb-3 pl-md-4 pr-0">
              <div >
                <span className="title-mid d-block mb-3 pl-md-2">{strings('createUserView.form.rolUser')}</span>
              </div>
              <div className="mb-3 pl-md-2 ">
                <div className="form-check mb-2 mr-5">
                  <input
                    required
                    className="form-check-input"
                    type="radio"
                    name={'admin'}
                    id={'admin-1'}
                    value={TypeUsers.ASSISTANT}
                    onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Tarjetas">
                    {strings('createUserView.form.assistant')}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    required
                    className="form-check-input"
                    type="radio"
                    name={'admin'}
                    id={'admin-2'}
                    value={TypeUsers.ADMIN}
                    onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Facturas">
                    {strings('createUserView.form.admin')}
                  </label>
                </div>

                <div className="form-check">
                  <input
                      required
                      className="form-check-input"
                      type="radio"
                      name={'admin'}
                      id={'admin-3'}
                      value={TypeUsers.SUPER_ADMIN}
                      onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Facturas">
                    {strings('createUserView.form.superAdministrator')}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group d-flex mt-4 justify-content-center">
            <button type="submit" className="btn-primary">
              {isLoadingGetCode ? <Loading /> : strings('signupView.button.createAccount')}
            </button>
          </div>
        </div>
      </form>
    )
  }

  /* PRIVATE METHOD */

  _apiCreateUser(e) {
    e.preventDefault();
    this.props.apiCreateUser(() => this._toggleModal());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearCognitoResponse() {
    this.props.clearCognitoResponse();
  }

  _clearDataSignup() {
    this.props.clearDataSignup();
  }

  _focusNextInput(e) {
    const { maxLength, name, value } = e.target;
    const [fieldName, fieldIndex] = name.split('-');
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextInput = document.querySelector(`input[name=code-${parseInt(fieldIndex, 10) + 1}]`)
        if (nextInput !== null) {
          nextInput.focus();
        }
      }
    }
  }

  _handleInputChange(e) {
    this._setMaxInputNumber(e);
    this._focusNextInput(e);
    this._handleValueChange(e.target.id, e.target.value)
  };

  _handleValueChange = (prop, value) => {
    this.props.setSignupState({ prop, value });
  };

  _handleViewChange = () => {
    const { history } = this.props;
    history.push(Routing.backOffice);
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurSignup(inputType)
  }

  _setMaxInputNumber(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  _toggleModal() {
    const confirmSignInResponse = document.getElementById('confirmSignInResponse');
    if (confirmSignInResponse.classList.contains('invisible')) {
      confirmSignInResponse.classList.remove('invisible')
      this.setState({ isModalShown: true })
    } else {
      confirmSignInResponse.classList.add('invisible')
      this.setState({ isModalShown: false })
      this._handleViewChange();
    }
  }
}

const mapStateToProps = ({ CreateUserReducer, BackOfficeReducer }) => {
  const {
    cognitoResponse, confirmationCode, email, error, errorRequest, isLoadingConfirmation, isLoadingGetCode, logoutMessage, mfa, organization, phone, username, requestMessage, surnames
  } = CreateUserReducer;
  const { backOfficeOrgData } = BackOfficeReducer;

  return {
    backOfficeOrgData, cognitoResponse, confirmationCode, email, error, errorRequest, isLoadingConfirmation, isLoadingGetCode, logoutMessage, mfa, organization, phone, username, requestMessage, surnames
  }
}

const mapStateToPropsAction = {
  apiCreateUser,
  clearDataSignup,
  setSignupState,
  validateOnBlurSignup,
};
export default connect(mapStateToProps, mapStateToPropsAction)(CreateUserContainer);
