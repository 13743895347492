import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import configureStore from './services/store/store';
import Router from 'src/modules/routing/Router';
import 'src/App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {initTranslations} from './resources/locales/i18n';
const { persistor, store } = configureStore();
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import './resources/styles/_login.sass';
import './resources/styles/main.css';
import {getAssets} from "./resources/assets/assets";
Amplify.configure(awsconfig);
//https://docs.amplify.aws/lib/auth/getting-started/q/platform/js#create-authentication-service

class App extends Component  {

  state = {
    response: null,
    isLoadingApp: false
  }

  async componentDidMount() {
    this.setState({isLoadingApp: true})
    await initTranslations();
    await getAssets();

    this.setState({isLoadingApp: false})
  }

  render(){
    const {isLoadingApp} = this.state;

    if (isLoadingApp){
      return null
    }

    return (
      <Provider store={ store }>
        <PersistGate
          persistor={ persistor }>
          <Router/>
        </PersistGate>
      </Provider>
    )
  }
}

export default App;

