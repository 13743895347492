import React, { Component } from 'react';
import { connect } from 'react-redux';
import { strings } from '../../../resources/locales/i18n.js';
import { AlertContainer } from 'src/components';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Link } from 'react-router-dom';
import { Loading } from 'src/components';
import { TextInput } from 'src/components';
import Routing from '../../routing/Routing';
import {
	changeUserPassword,
	clearDataRememberPassword,
	getValidationCode,
	setRememberPasswordState,
	validateOnBlurRememberPasswordUsername,
	validateOnBlurRememberPasswordValidationCode,
} from './RememberPasswordActions.js';



class RememberPasswordContainer extends Component {

	componentDidMount() {
		this._clearDataRememberPassword();
	}

	render() {
		const { codeSent, codeSentMessage, username } = this.props;

		return (
			<>
				<HeaderContainer />
				<section className="container wrapper wrapper--content pt-5 pb-5" style={{ left: "0px" }}>
					<div className="row mb-4">
						<div className="col-12">
							<div className="p-5">
								{codeSent && codeSentMessage ? this.renderModalMessage() : <div />}
								{this.renderEmailForm()}
								{username && codeSent ? this.renderCodeForm() : <div />}

								<div className="pt-5 divider">
									<div className="row d-flex justify-content-center">
										<div className="col-12 mb-4 text-center">
											<span className="title-mid d-block">{strings('rememberPasswordView.needMoreHelp')}</span>
										</div>
										<div className="col-12 col-md-6">
											<div className="form-group d-flex justify-content-center">
												<Link to={Routing.contact} className="btn-secundary w-75">{strings('login.contactButton')}</Link>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>
			</>

		);
	}

  renderCodeForm() {
    const { code, codeSentMessage, error, isLoading, password, repeatPassword } = this.props;
    return (
      <>
        <form id="changePassword" onSubmit={(e) => this._changeUserPassword(e)} action="" className="m-auto mt-3 mb-5">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-6">
              <TextInput
                title={strings('rememberPasswordView.enterYourCode')}
                id="code"
                name="code"
                type="text"
                error={error}
                placeholder=""
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                value={code}
                onBlur={(e) => this._validateOnBlurValidationCode(e.target.id)}
                onKeyDown={(e) => this._callTabFunctionValidationCode(e, e.target.id)}
              />
              <TextInput
                title={strings('placeholder.passwordNew')}
                id="password"
                name="password"
                type="password"
                error={error}
                placeholder=""
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                value={password}
                onBlur={(e) => this._validateOnBlurValidationCode(e.target.id)}
                onKeyDown={(e) => this._callTabFunctionValidationCode(e, e.target.id)}
              />
              <TextInput
                title={strings('placeholder.passwordRepeat')}
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                error={error}
                placeholder=""
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                value={repeatPassword}
                onBlur={(e) => this._validateOnBlurValidationCode(e.target.id)}
                onKeyDown={(e) => this._callTabFunctionValidationCode(e, e.target.id)}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <button type="submit" disabled={codeSentMessage} className={"btn-primary w-100 " + (codeSentMessage ? "btn-primary-disabled" : '')}>
                  {isLoading ? <Loading /> : strings('rememberPasswordView.changePasswordButton')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  }

  renderEmailForm() {
    const { codeSent, error, isLoading, username } = this.props;
    return (
      <>
        <form id="getValidationCode" onSubmit={(e) => this._getValidationCode(e)} action="" className="m-auto mt-3 mb-5">
          <div className="row">
            <div className="col-12 mb-4 text-center">
              <span className="title-mid d-block">{strings('rememberPasswordView.recoverPassword')}</span>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-6">
              <AlertContainer
                error={error}
                id={'request'}
                className={'alert-danger'}
              />
              <TextInput
                title={strings('rememberPasswordView.enterYourEmail')}
                id="username"
                name="username"
                type="text"
                error={error}
                placeholder=""
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                value={username}
                onBlur={(e) => this._validateOnBlurUsername(e.target.id)}
                onKeyDown={(e) => this._callTabFunctionUsername(e, e.target.id)}
                readOnly={username && codeSent}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-12 col-md-6">
              {username && codeSent ? <div /> : <div className="form-group">
                <button type="submit" className="btn-primary w-100">
                  {isLoading ? <Loading /> : strings('button.continue')}
                </button>
              </div>}
            </div>
          </div>
        </form>
      </>
    )
  }

  renderModalMessage() {
    const { codeSentMessage } = this.props;
    return (
      <div className='w-100 h-100 position-relative d-flex justify-content-center' style={{ zIndex: 4 }}>
        <div className="modal-dialog position-absolute" >
          <div className="modal-content p-2">
            <div className="modal-header border-0">
              <h5 className="modal-title">{codeSentMessage}</h5>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button type="button" className="btn btn-primary" onClick={() => {
                this._closeModalAndClearCodeSentMessage({ prop: 'codeSentMessage', value: '' })
              }}>{strings('rememberPasswordView.understood')}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

	/* PRIVATE METHOD */
	_callTabFunctionUsername(e, inputType) {
		if (e.keyCode === 9) {
			this._validateOnBlurUsername(inputType)
		}
	}

	_callTabFunctionValidationCode(e, inputType) {
		if (e.keyCode === 9) {
			this._validateOnBlurValidationCode(inputType)
		}
	}

	_changeUserPassword(e) {
		e.preventDefault();
		this.props.changeUserPassword(() => this._handleViewChange())
	}

	_clearDataRememberPassword() {
		this.props.clearDataRememberPassword();
	}

	_closeModalAndClearCodeSentMessage(prop, value) {
		this.props.setRememberPasswordState({ prop: 'codeSentMessage', value: '' })
	}

	_getValidationCode(e) {
		e.preventDefault();
		this.props.getValidationCode()
	}

	_handleValueChange = (prop, value) => {
		this.props.setRememberPasswordState({ prop, value });
	}

	_handleViewChange = () => {
		const { history } = this.props;
		history.push(Routing.app);
	};

	_validateOnBlurUsername(inputType) {
		this.props.validateOnBlurRememberPasswordUsername(inputType)
	}

	_validateOnBlurValidationCode(inputType) {
		this.props.validateOnBlurRememberPasswordValidationCode(inputType)
	}

}


const mapStateToProps = ({ RememberPasswordReducer }) => {
	const { code, codeSent, codeSentMessage, error, isLoading, password, username, repeatPassword } = RememberPasswordReducer;
	return { code, codeSent, codeSentMessage, error, isLoading, password, username, repeatPassword }
}


const mapStateToPropsAction = {
	changeUserPassword,
	clearDataRememberPassword,
	getValidationCode,
	setRememberPasswordState,
	validateOnBlurRememberPasswordUsername,
	validateOnBlurRememberPasswordValidationCode,
};

export default connect(mapStateToProps, mapStateToPropsAction)(RememberPasswordContainer);
