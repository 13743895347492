import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { getDashboardInfo } from '../../services/api/API';

export const apiGetDashboardInfo = () => async (dispatch) => {
  dispatch(setDashboardInfoDataState({ prop: 'isLoadingDashboardInfo', value: true }));
  await dispatch(getDashboardInfo(
    (tag, response) => {
      if (isDev()) console.log('apiGetDashboardInfo - Error: ', response);
      dispatch({
        type: Types.GET_DASHBOARD_INFO_FAILED,
        payload: response
      });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetDashboardInfo - Success: ', response);
      dispatch({
        type: Types.GET_DASHBOARD_INFO_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setDashboardInfoDataState({ prop: 'isLoadingDashboardInfo', value: false }));
};

export const clearDashboardInfo = () => ({
  type: Types.CLEAR_DASHBOARD_INFO,
});

export const setDashboardInfoDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_DASHBOARD_INFO,
  payload: { prop, value }
});