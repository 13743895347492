import Types from "./Types";
import { getMomentNextYear } from "../../utils/Utils.js";
const INITIAL_STATE = {
  confirmationRequest: '',
  errorRequest: '',
  isLoadingPurchaseCards: false,
  purchaseCardsData: [
    {
      alcohol: false,
      amount: '',
      cosmetics: false,
      endDate: '',
      isRechargeable: '',
      magazines: false,
      quantity: '',
      reference: '',
      restrictions: [],
      tobacco: false,
    },
  ]
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR PURCHASE CARDS **/
    case Types.CLEAR_DATA_PURCHASE_CARDS:
      return {
        ...INITIAL_STATE,
        purchaseCardsData: [
          {
            alcohol: false,
            amount: '',
            cosmetics: false,
            endDate: '',
            isRechargeable: '',
            magazines: false,
            quantity: '',
            reference: '',
            restrictions: [],
            tobacco: false,
          },
        ]
      };

    /** FORM **/
    case Types.FORM_PURCHASE_CARDS_FAILED:
      return { ...state, error: action.payload };

    case Types.FORM_PURCHASE_CARDS_SUCCESS:
      return { ...state };

    /** UPDATE STATE **/
    case Types.SET_DATA_PURCHASE_CARDS:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
