import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


// Actions
import {
  apiCompleteNewPassword,
  clearDataNewPassword,
  setNewPasswordState,
  validateOnBlurNewPassword
} from './NewPasswordActions';
import { apiPostLoginWithCode, setLoginState } from '../login/LoginActions';

// Components
import { AlertContainer } from '../../../components';
import { Loading } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import { localAssets } from '../../../resources/assets/assets.js';
import { strings } from '../../../resources/locales/i18n.js';
import Routing from '../../routing/Routing';


class NewPasswordContainer extends Component {

  componentDidMount() {
    this._clearDataNewPassword();
  }

  render() {
    const { username, requestMessage } = this.props;

    if (!username || requestMessage) {
      const { history } = this.props;
      history.push(Routing.login)
    }

    return (
      <>
        <section className="container-fluid wrapper login" >
          <div className="row h-100">
            <div className="col-12 col-md-6 login-form d-flex align-items-start justify-content-center py-5">
              <div className="login-form--wrapper d-flex flex-column align-items-start px-4">
                <img src={localAssets.imgLogoHiperdino.default} alt="logo de Hiperdino" className="mb-4" />
                <div className="login-form--wrapper d-flex align-items-center ">
                  <span className="title-big mb-4">
                    {strings('global.title')}
                  </span>
                </div>
                {this.renderCodeModal()}
                {this.renderCreateNewPasswordForm()}
                <div className="new-add">
                  <span className="title-mid d-block mb-4">
                    {strings('login.organizationRegister')}
                  </span>
                  <p>
                    {strings('login.organizationDescription')}
                  </p>
                  <Link to={Routing.contact} className="btn-secundary w-75">
                    {strings('login.contactButton')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 login-img" />
          </div>
        </section >
      </>

    );
  }
  renderCodeModal() {
    const { error, isLoadingLogin } = this.props;
    const { code1, code2, code3, code4, code5, code6 } = this.props;
    let hasError = false;
    let hasErrorRequest = false;
    let elementError = '';

    if (error) {
      error.map((item) => {
        if (item.key === 'code') {
          hasError = true;
          return elementError = item;
        }
        if (item.key === 'request') {
          hasError = true;
          return elementError = item;
        }
      });
    }

    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="codeModal">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ opacity: 1 }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this._clearCodeLogin() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form id="codeForm" onSubmit={(e) => { this._createWholeCode(); this._apiPostLoginWithCode(e) }} action="">

                <h5 className="title-mid text-center p-1" id="exampleModalLabel">{strings('login.enterYourCode')}</h5>
                <div className="modal-body d-flex justify-content-center row">
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code1"
                    maxLength="1"
                    name="code-1"
                    onChange={(e) => { this._handleCodeInputChange(e) }}
                    placeholder=""
                    value={code1}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code2"
                    maxLength="1"
                    name="code-2"
                    onChange={(e) => { this._handleCodeInputChange(e) }}
                    placeholder=""
                    value={code2}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code3"
                    maxLength="1"
                    name="code-3"
                    onChange={(e) => { this._handleCodeInputChange(e) }}
                    placeholder=""
                    value={code3}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code4"
                    maxLength="1"
                    name="code-4"
                    onChange={(e) => { this._handleCodeInputChange(e) }}
                    placeholder=""
                    value={code4}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code5"
                    maxLength="1"
                    name="code-5"
                    onChange={(e) => { this._handleCodeInputChange(e) }}
                    placeholder=""
                    value={code5}
                  />
                  <input type="code"
                    className="form-control col-3 col-sm-1 text-center m-2"
                    id="code6"
                    maxLength="1"
                    name="code-6"
                    onChange={(e) => { this._handleCodeInputChange(e) }}
                    placeholder=""
                    value={code6}
                  />
                </div>
                {hasError && elementError ? <div style={{ fontSize: "12px", color: "#DE4150", textAlign: 'center' }}>{elementError.value}</div> : null}

                <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
                  <button type="submit" className="btn btn-primary mb-3">
                    {isLoadingLogin ? <Loading /> : strings('button.continue')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{ opacity: .9, zIndex: -1 }} />
      </div>
    )
  }

  renderCreateNewPasswordForm() {
    const { newPassword, error, isLoadingNewPassword, username, requestMessage } = this.props;

    return (
      <form id="newPasswordForm" onSubmit={(e) => this._apiCompleteNewPassword(e)} action="" className="w-100 mt-5">
        <span className="title-mid d-block mb-4">
          {strings('createPasswordView.createNewPassword')}
        </span>
        <div className="row pb-4">
          <div className="col-12">
            <p>{strings('createPasswordView.createNewPasswordInstructions')}</p>
          </div>

        </div>

        <AlertContainer
          error={error}
          id={'request'}
          className={requestMessage ? 'alert-success' : 'alert-danger'}
          message={requestMessage}
        />

        <TextInput
          title={strings('createPasswordView.form.email')}
          id="username"
          name="username"
          type="text"
          error={error}
          placeholder=""
          onChange={(e) => null}
          value={username}
          onBlur={(e) => null}
          onKeyDown={(e) => null}
          readOnly
        />

        <TextInput
          title={strings('createPasswordView.form.newPassword')}
          id="newPassword"
          name="newPassword"
          type="password"
          error={error}
          placeholder=''
          onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
          value={newPassword}
          onBlur={(e) => this._validateOnBlur(e.target.id)}
          onKeyDown={(e) => this._callTabFunction(e)}
        />

        <div className="w-100 ml-0 mt-5 row justify-content-between align-items-center">
          <a onClick={(e) => this._goBack()} className="col-12 col-sm-4 p-0 mb-4 mb-sm-0 title-lite" style={{ cursor: 'pointer' }}>
            {strings('createPasswordView.form.goBack')}
          </a>
          <button type="submit" className="btn-primary col-12 col-sm-5 p-0" disabled={!username}>
            {isLoadingNewPassword ? <Loading /> : strings('createPasswordView.form.generatePassword')}
          </button>
        </div>

      </form>
    )
  }

  /* PRIVATE METHOD */
  _apiCompleteNewPassword(e) {
    e.preventDefault();
    this.props.apiCompleteNewPassword(() => this._handleViewChange(), () => this._toggleModal());
  }

  _apiPostLoginWithCode(e) {
    e.preventDefault();
    this.props.apiPostLoginWithCode(() => this._handleViewChange());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearDataNewPassword() {
    this.props.clearDataNewPassword();
  }

  _createWholeCode() {
    const { code1, code2, code3, code4, code5, code6 } = this.props;
    const fullCode = code1 + code2 + code3 + code4 + code5 + code6;
    this._handleLoginValueChange('code', fullCode)
  }

  _focusNextInput(e) {
    const { maxLength, name, value } = e.target;
    const [fieldName, fieldIndex] = name.split('-');
    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 6) {
        const nextInput = document.querySelector(`input[name=code-${parseInt(fieldIndex, 10) + 1}]`)
        if (nextInput !== null) {
          nextInput.focus();
        }
      }
    }
  }

  _goBack() {
    const { history } = this.props;
    history.push(Routing.login)
  }

  _handleCodeInputChange = (e) => {
    this._setMaxInputNumber(e);
    this._focusNextInput(e);
    this._handleLoginValueChange(e.target.id, e.target.value)
  };

  _handleLoginValueChange = (prop, value) => {
    this.props.setLoginState({ prop, value });
  };

  _handleValueChange = (prop, value) => {
    this.props.setNewPasswordState({ prop, value });
  };

  _handleViewChange = () => {
    const { history } = this.props;
    history.push(Routing.dashboard);
  };

  _setMaxInputNumber(e) {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }

  _toggleModal() {
    const codeModal = document.getElementById('codeModal');
    if (codeModal.classList.contains('invisible')) {
      codeModal.classList.remove('invisible')
    } else {
      codeModal.classList.add('invisible')
    }
  }

  _validateOnBlur(inputType) {
    this.props.validateOnBlurNewPassword(inputType)
  }

}

const mapStateToProps = ({ NewPasswordReducer, LoginReducer }) => {
  const { code1, code2, code3, code4, code5, code6, isLoadingLogin, username } = LoginReducer;
  const {
    newPassword,
    error,
    isLoadingNewPassword,
    requestMessage,
  } = NewPasswordReducer;


  return {
    code1, code2, code3, code4, code5, code6, newPassword, error, isLoadingLogin, isLoadingNewPassword, requestMessage, username,
  }
}


const mapStateToPropsAction = {
  apiCompleteNewPassword,
  apiPostLoginWithCode,
  clearDataNewPassword,
  setLoginState,
  setNewPasswordState,
  validateOnBlurNewPassword,
};

export default connect(mapStateToProps, mapStateToPropsAction)(NewPasswordContainer);
