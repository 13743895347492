
export default class Types {
  /** CLEAR COSTUMER_SUPPORT **/
  static CLEAR_DATA_COSTUMER_SUPPORT = 'CLEAR_DATA_COSTUMER_SUPPORT';

  /** FORM COSTUMER SUPPORT **/
  static FORM_COSTUMER_SUPPORT_FAILED = 'FORM_COSTUMER_SUPPORT_FAILED';
  static FORM_COSTUMER_SUPPORT_SUCCESS = 'FORM_COSTUMER_SUPPORT_SUCCESS';

  /** POST EMAIL COSTUMER SUPPORT **/
  static POST_EMAIL_COSTUMER_SUPPORT_FAILED = 'POST_EMAIL_COSTUMER_SUPPORT_FAILED';
  static POST_EMAIL_COSTUMER_SUPPORT_SUCCESS = 'POST_EMAIL_COSTUMER_SUPPORT_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_COSTUMER_SUPPORT = 'SET_DATA_COSTUMER_SUPPORT';
}
