import React, {  } from 'react';
import Loader from 'react-loader-spinner'
const Loading = (props) => {

  const {type, color, height, width } = props
  return(
    <Loader
      type={type}
      color={color}
      height={height}
      width={width}
    />
  )
}

Loading.defaultProps = {
  type:'Oval',
  color:'white',
  height:25,
  width:25
};

export {Loading}
