import Types from "./Types";

const INITIAL_STATE = {
  confirmationRequest: '',
  error: [],
  errorRequest: '',
  excelFile: null,
  isLoadingRechargeCards: false,
  rechargeCardsData: [
    {
      cardId: '',
      rechargeAmount: '',
      endDate: '',
    },
  ]
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR RECHARGE CARDS **/
    case Types.CLEAR_DATA_RECHARGE_CARDS:
      return {
        ...INITIAL_STATE,
        error: [],
        rechargeCardsData: [
          {
            cardId: '',
            rechargeAmount: '',
            endDate: '',
          },
        ]
      };

    /** FORM **/
    case Types.FORM_RECHARGE_CARDS_FAILED:
      return { ...state, error: action.payload };

    case Types.FORM_RECHARGE_CARDS_SUCCESS:
      return { ...state };

    /** UPDATE STATE **/
    case Types.SET_DATA_RECHARGE_CARDS:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
