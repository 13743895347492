import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { postPurchaseCards } from '../../services/api/API';
import { strings } from '../../resources/locales/i18n';
import PurchaseCardsManager from './PurchaseCardsManager'
import { getMomentDateFormat } from '../../utils/Utils.js'

export const apiPostPurchaseCards = (toggleModal) => async (dispatch, getState) => {
  // -- Transform data
  const { purchaseCardsData } = getState().PurchaseCardsReducer;

  const postPurchaseCardsData = purchaseCardsData.map((item, idx) => {

    const isRechargeableBoolean = (() => {
      switch (item.isRechargeable) {
        case "true": case true: return true;
        case "false": case null: return false;
      }
    })()
    const formattedEndDate = getMomentDateFormat(item.endDate, 'L')
    return item = {
      amount: item.amount,
      endDate: formattedEndDate,
      isRechargeable: isRechargeableBoolean,
      quantity: item.quantity,
      reference: item.reference,
      restrictions: item.restrictions
    }
  })

  // -- Request
  dispatch(setPurchaseCardsDataState({ prop: 'isLoadingPurchaseCards', value: true }));
  await dispatch(postPurchaseCards(
    postPurchaseCardsData,
    (tag, response) => {
      if (isDev()) console.log('apiFormPurchaseCards - Error: ', response);
      dispatch(responseError(response, toggleModal));
    },
    (tag, response) => {
      if (isDev()) console.log('apiFormPurchaseCards - Success: ', response);
      dispatch(responseSuccess(response, toggleModal));
    }
  ))
  dispatch(setPurchaseCardsDataState({ prop: 'isLoadingPurchaseCards', value: false }));
};

export const clearDataPurchaseCards = () => ({
  type: Types.CLEAR_DATA_PURCHASE_CARDS,
});

export const setPurchaseCardsDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_PURCHASE_CARDS,
  payload: { prop, value }
});
export const setPurchaseCardsElementDataState = ({ prop, value }, indexPosition) => async (dispatch, getState) => {
  const { purchaseCardsData } = getState().PurchaseCardsReducer;
  const clonePurchaseCardsData = purchaseCardsData.map((item, idx) => {
    if (idx === indexPosition) {
      return {
        ...item,
        [prop]: value
      }
    }
    return item
  });
  dispatch(setPurchaseCardsDataState({ prop: 'purchaseCardsData', value: clonePurchaseCardsData }));
}

export const responseError = (error, toggleModal) => async (dispatch) => {
  dispatch(setPurchaseCardsDataState({
    prop: 'errorRequest',
    value: error.message
  }));
  toggleModal()
};

export const responseSuccess = (response, toggleModal) => async (dispatch) => {
  dispatch(setPurchaseCardsDataState({
    prop: 'confirmationRequest',
    value: response.message
  }));
  toggleModal();
}