import { strings } from '../../resources/locales/i18n.js';
import { getMomentDateFormat } from '../../utils/Utils';

export const DocumentTypeStatus = {
  BILL: "invoice",
  ANNEXED: "annexed"
}

export const DocumentSearchTypeStatus = {
  BILLS: "invoice",
  CARDS: "card"
}

export const DocumentAmountTypeStatus = {
  ALL_AMOUNTS: "ALL_AMOUNTS",
  AMOUNT: "AMOUNT"
}

export default class DocumentsManager {

  static getAmount = (data) => {
    return data && data.amount ? data.amount : '';
  };

  static getDate = (data) => {
    let formattedDate = ''
    if(data && data.date) {
      formattedDate = getMomentDateFormat(data.date, 'L')
    }
    return data && data.date ?
    formattedDate : '';
  };

  static getDescription = (data) => {
    return data && data.description ?
      data.description : '';
  };

  static getDownloadUrl = (data) => {
    return data && data.downloadUrl ?
      data.downloadUrl : '';
  };

  static getLoading = (data) => {
    return data && data.isLoading ?
      data.isLoading : false;
  };

  static getStatus = (data) => {
    let status = ''
    if (data.status === "Correcta"){
      status = strings('documentsView.status.correct')
    }
    if (data.status === "Incorrecta"){
      status = strings('documentsView.status.incorrect')
    }
    return data && data.status && status.length > 0 ?
      status : data.status;
  };

}
