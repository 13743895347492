import {formatImageToSvg} from "../styles/js/custom";
import {isDev} from "../../utils/Utils";

let singleton  = {};

const localSvg = [
  {key: "icArrowBread",       value: require('./icons/ic_arrow_bread.svg').default},
  {key: "icArrowNext",        value: require('./icons/ic_arrow_next.svg').default},
  {key: "icArrowPrev",        value: require('./icons/ic_arrow_prev.svg').default},
  {key: "icArrowSelect",      value: require('./icons/ic_arrow_select.svg').default},
  {key: "icQuery",            value: require('./icons/ic_query.svg').default},
  {key: "icRemove",           value: require('./icons/ic_remove.svg').default},
  {key: "icOrderOnline",      value: require('./icons/ic_order_online.svg').default},
  {key: "icOrderTicket",      value: require('./icons/ic_order_ticket.svg').default},
]

const localAssets = {
  icOrderOnline:            require('./icons/ic_order_online.png').default,
  icShop:                   require('./icons/ic_shop.png'),
  imgLogin:                 require('./images/portatil-tarjeta-recortada.jpg'),
  imgLogoHiperdino:         require('./images/logo.png'),
  imgLogoTicket:            require('./images/img-logo-ticket.jpg'),
  imgOnboardingBgFirst:     require('./images/ob-bg-bienvenida.png'),
  imgOnboardingBgSecond:    require('./images/ob-bg-ventajas.png'),
  imgOnboardingBgThird:     require('./images/ob-bg-tarjetas.png'),
  imgOnboardingBgFourth:    require('./images/ob-bg-tickets.png'),
  imgOnboardingBgFifth:     require('./images/ob-bg-compra-responsable.png'),
  imgOnboardingIcFirst:     require('./images/ob-ic-bienvenida.png'),
  imgOnboardingIcSecond:    require('./images/ob-ic-cliente.png'),
  imgOnboardingIcThird:     require('./images/ob-ic-tarjetas.png'),
  imgOnboardingIcFourth:    require('./images/ob-ic-tickets.png'),
  imgOnboardingIcFifth:     require('./images/ob-ic-compra-responsable.png'),
  imgPlaceholder:           require('./images/placeholder.png'),
  imgShopCard:              require('./images/img-shop-card.jpg'),
  imgSidebarAtc:            require('./images/portal_clientes_contacto.jpg'),
  imgIconError:             require('./images/icon-error.png'),
};

/** ********* **/
/** SINGLETON **/
/** ********* **/
const getAsset = (svg) => {
  if (!singleton || singleton === {} || !singleton[svg]) {
    if (isDev()) console.log("No singleton - getAssets()");
    getAssets()
  }

  return singleton[svg];
};

const  getAssets = async() => {
  await getIcons(localSvg)
};


const getIcons = async(assetsArray) => {
  return await Promise.all(
      assetsArray.map(async (item) => {
        return {[item.key]: await formatImageToSvg(`<img src=${item.value} class="svg"/>`)}
      })
  ).then( source => {
    source.map((item) => {
      singleton = {...singleton, ...item}
    })
  })
};

export { localAssets, getAsset, getAssets };
