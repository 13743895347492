import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import { apiPostDocuments, apiPostDocumentsDownload, clearDataDocuments, setDocumentsDataState } from './DocumentsActions.js';
import DocumentsManager, { DocumentAmountTypeStatus, DocumentSearchTypeStatus, DocumentTypeStatus } from './DocumentsManager';

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading, ModalContainer, PaginatorContainer, SelectorContainer } from '../../components';

// Resources
import { changeCurrentLimit, changeCurrentPage } from '../../utils/Utils.js'
import { formatToNumberWithCommas, getMomentNow, getMomentYearAgo, parseDecimal } from '../../utils/Utils.js';
import moment from 'moment';
import Routing from '../routing/Routing.jsx'
import { strings } from '../../resources/locales/i18n';

class DocumentsContainer extends Component {

	state = {
    isModalShown: false,
  }

	componentDidMount() {
		this._clearDataDocuments()

		const formQueries = this.props.location.query; // redirect from Notifications
		const formSearchQuery = this.props.location.search; // redirect from Login

		if (formQueries) {
			this._setFormQueries(formQueries);
			this._apiPostDocuments();
		}
		if (formSearchQuery) {
			this._setFormSearch(formSearchQuery);
			this._apiPostDocuments();
		}

	}

	render() {
		const breadcrumbList = [
			{ name: strings('viewName.home'), path: Routing.app },
			{ name: strings('viewName.documents'), path: '#' }
		]

		return (
			<>
				<HeaderContainer title={strings('viewName.documents')} breadcrumbList={breadcrumbList} />
				<ModalContainer
          closeAction={() => this._toggleModal()}
          // icon='error'
					// iconStyle={{color: 'red'}}
          message={strings('documentsView.modal.message')}
          modalId='documentDownloadResponse'
          isModalShown={this.state.isModalShown}
          primaryAction={() => this._toggleModal()}
          primaryButtonText={strings('button.accept')}
          title={strings('documentsView.modal.title')}
					titleStyle={{color: 'red'}}
        />
				{this.renderForm()}
				<div id="tableResult">
					{this.renderListDocuments()}
				</div>
			</>
		)
	}

	renderForm() {
		const { dateStart, dateEnd, documentQuantityType, documentSearchType, documentType, idReference, isLoadingPostDocuments } = this.props;

		return (
			<section className="container wrapper wrapper--content pt-5 pb-2">
				<div className="row mb-4">
					<div className="col-12">
						<div className="card px-5 pt-5 pb-0">
							<form id="DocumentsForm" onSubmit={(e) => this._apiPostDocuments(e)} action="" className="w-100 mt-3 mb-5">
								<div className="row mb-3">

									{ /** DOCUMENT TYPE */}
									<div className="col-12 col-sm-4 mb-3">
										<select
											required
											id={'documentType'}
											className="form-group"
											aria-label="Default select example"
											value={documentType}
											onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
										>
											<option value=''>{strings('documentsView.documentType.title')}</option>
											<option value={DocumentTypeStatus.BILL}>{strings('documentsView.documentType.bill')}</option>
											<option value={DocumentTypeStatus.ANNEXED}>{strings('documentsView.documentType.annex')}</option>
										</select>
									</div>
								</div>
								<div className="row mb-3">

									{ /** SEARCH BY RADIO INPUTS */}
									<div className="col-12 mb-3">
										<div >
											<span className="title-mid d-block mb-3">{strings('documentsView.searchBy.title')}</span>
										</div>
										<div className="mb-3 d-flex">
											<div className="form-check mb-2 mr-5">
												<input
													className="form-check-input"
													type="radio"
													name={'documentSearchType'}
													id={'documentSearchType-1'}
													value={DocumentSearchTypeStatus.CARDS}
													onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
													checked={documentSearchType === DocumentSearchTypeStatus.CARDS}
												/>
												<label className="form-check-label" htmlFor="documentSearchType-1">
													{strings('documentsView.searchBy.cards')}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													name={'documentSearchType'}
													id={'documentSearchType-2'}
													value={DocumentSearchTypeStatus.BILLS}
													onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
													checked={documentSearchType === DocumentSearchTypeStatus.BILLS}
												/>
												<label className="form-check-label" htmlFor="documentSearchType-2">
													{strings('documentsView.searchBy.bills')}
												</label>
											</div>
										</div>
									</div>

									{ /** DOCUMENT ID */}
									<div className="col-12 col-md-10 mb-3">
										<div className="form-group form-group--label">
											<label htmlFor="user">{strings('documentsView.documentId')}</label>
											<input
												required={documentSearchType === DocumentSearchTypeStatus.CARDS}
												id={"idReference"}
												className="form-control"
												type="text"
												placeholder=""
												value={idReference}
												onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
											/>
											<small style={{ lineHeight: 1 }}>{documentSearchType === DocumentSearchTypeStatus.CARDS ? strings('documentsView.documentIdDescriptionObligatory') : strings('documentsView.documentIdDescriptionOptional')}</small>
										</div>
									</div>
								</div>

								<div className="row mb-3">

									{ /** DATE PICKER */}
									<div className="row col-12 mb-3">

										{/* START */}
										<div className="form-group col-12 col-sm-6 col-md-5">
											<div className="title-mid mb-1">{strings('documentsView.date.from')}</div>
											<div style={{ position: 'relative' }}>
												<span className="material-icons icon-calendar">event</span>
												<input
													required
													id={"dateStart"}
													className="form-control datepicker"
													type="date"
													placeholder=""
													value={dateStart}
													onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
													min={getMomentYearAgo('yyyy-MM-DD')}
													max={dateEnd ? dateEnd : getMomentNow("yyyy-MM-DD")}
												/>
											</div>
										</div>

										{/* END */}
										<div className="form-group col-sm-6 col-md-5">
											<div className="title-mid mb-1">{strings('documentsView.date.to')}</div>
											<div style={{ position: 'relative' }}>
												<span className="material-icons icon-calendar">event</span>
												<input
													required={dateStart}
													id={"dateEnd"}
													className="form-control datepicker"
													type="date"
													placeholder=""
													value={dateEnd}
													onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
													min={dateStart}
													max={dateStart ?
														this._compareDateStartToEnd(dateStart, "yyyy-MM-DD", 1, 'Y') :
														getMomentNow("yyyy-MM-DD")}
												/>
											</div>
										</div>
									</div>

									{ /** DOCUMENT QUANTITY TYPE RADIO INPUT */}
									<div className="col-12 col-md-5 p-0">
										<div className="col-12">
											<span className="title-mid d-block mb-3">	{strings('documentsView.searchBy.title')}</span>
										</div>
										<div className="col-12 d-flex">
											<div className="form-check mb-2 mr-5">
												<input
													required
													className="form-check-input"
													type="radio"
													name={"documentQuantityType"}
													id={"documentQuantityType-1"}
													value={DocumentAmountTypeStatus.ALL_AMOUNTS}
													onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
													checked={documentQuantityType === DocumentAmountTypeStatus.ALL_AMOUNTS}
												/>
												<label className="form-check-label" htmlFor={"documentQuantityType-1"}>
													{strings('documentsView.allAmounts')}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													name={"documentQuantityType"}
													id={"documentQuantityType-2"}
													value={DocumentAmountTypeStatus.AMOUNT}
													onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
													checked={documentQuantityType === DocumentAmountTypeStatus.AMOUNT}
												/>
												<label className="form-check-label" htmlFor={"documentQuantityType-2"}>
													{strings('documentsView.singleAmount')}
												</label>
											</div>
										</div>
										{this.renderComparison()}
									</div>
								</div>

								{ /** BUTTON */}
								<div className="row mt-3">
									<div className="col-12">
										<div className="form-group d-flex justify-content-left">
											<button type="submit" className="btn-primary">{isLoadingPostDocuments ?
												<Loading /> : strings('documentsView.generateReport')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		)
	}

	renderComparison() {
		const { documentQuantityType, documentMaxQuantity, documentMinQuantity } = this.props;
		const minValueForMaxQuantity = parseInt(documentMinQuantity) + 0.01
		if (documentQuantityType === DocumentAmountTypeStatus.AMOUNT) {
			return (
				<div className="row col-12 mb-3">
					{ /** COMPARISON MIN QUANTITY */}
					<div className="col-12 col-sm-5">
						<div className="title-mid mb-1">{strings('documentsView.date.from')}</div>
						<div className="form-group">
							<input
								required
								id={"documentMinQuantity"}
								className="form-control"
								type="number"
								placeholder="15,00"
								value={documentMinQuantity}
								min="0"
								step="0.01"
								max={documentMaxQuantity}
								onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
								onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value)}
							/>
						</div>
					</div>
					{ /** COMPARISON MAX QUANTITY */}
					<div className="col-12 col-sm-5">
						<div className="title-mid mb-1">{strings('documentsView.date.to')}</div>
						<div className="form-group">
							<input
								required
								id={"documentMaxQuantity"}
								className="form-control"
								type="number"
								placeholder="15,00"
								value={documentMaxQuantity}
								min={minValueForMaxQuantity}
								step="0.01"
								onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
								onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value)}
							/>
						</div>
					</div>
				</div>
			)
		}

		return <div />
	}

	renderItemDocument() {
		const { dataDocuments, documentsCurrentPage, documentsLimit } = this.props
		if (!dataDocuments) {
			return <div />
		}
		return (
			dataDocuments.map((item, idx) => {
				const amount = DocumentsManager.getAmount(item);
				const amountFormatted = formatToNumberWithCommas(amount);
				const date = DocumentsManager.getDate(item);
				const description = DocumentsManager.getDescription(item);
				const downloadUrl = DocumentsManager.getDownloadUrl(item);
				const status = DocumentsManager.getStatus(item);
				const isLoading = DocumentsManager.getLoading(item);

				if (idx >= documentsLimit * (documentsCurrentPage - 1) && idx < documentsLimit * documentsCurrentPage) {
					return (
						<tr key={idx}>
							<th className="align-middle">{description}</th>
							<td className="align-middle">{date}</td>
							<td className="align-middle">{amountFormatted}</td>
							<td className="align-middle">{status}</td>
							<td className="align-middle">
								<a className="btn-secundary" style={{ textDecoration: 'none', cursor: 'pointer', minWidth: 150 }} onClick={(e) => this._apiPostDocumentsDownload(e, downloadUrl, description)}>
									{isLoading ?
										<Loading color={'#77bd1e'} />
										:
										<>
											{strings('documentsView.download')}
											<span className="material-icons p-1">download</span>
										</>
									}

								</a>
							</td>
						</tr >
					)
				}
				return;
			})
		)
	}

	renderListDocuments() {
		const { documentsTotalResults, documentsCurrentPage, documentsLimit, documentsPagesNum,  error } = this.props
		const id = 'documents';

		if (documentsTotalResults === null && !error) {
			return <div />
		}

		if (documentsTotalResults === null && error && error.length > 1) {
			return <div id='noResults' className="container wrapper wrapper--content">
				<div className="title-big my-4 pb-5">{error}</div>
			</div>
		}

		if (documentsTotalResults === 0) {
			return <div id='noResults' className="container wrapper wrapper--content">
				<div className="title-big my-4 pb-5">{strings('purchaseCardResults.noResults')}</div>
			</div>
		}

		return (
			<section className="container wrapper wrapper--content pb-5">
				<div className="row module-table">
					<div className="col-12 mb-md-0">
						<div className="row mb-4">
							<div className="col-6">
								{documentsTotalResults} {strings('documentsView.results')}
							</div>
						</div>
						<div className="card p-0">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>{strings('title.concept')}</th>
											<th>{strings('title.date')}</th>
											<th>{strings('title.amount')}</th>
											<th>{strings('title.status')}</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{this.renderItemDocument()}
									</tbody>
								</table>
								<div className='d-flex justify-content-between align-items-center'>
									<SelectorContainer
										id={id}
										changeCurrentLimit={changeCurrentLimit}
										apiGetData={() => this.props.apiPostDocuments()}
										resultsQuantity={documentsLimit}
										setDataProps={({ prop, value }) => this.props.setDocumentsDataState({ prop, value })}
										totalResults={documentsTotalResults}
									/>
									<PaginatorContainer
										id={id}
										changeCurrentPage={changeCurrentPage}
										currentPage={documentsCurrentPage}
										// apiGetData={() => this.props.apiPostDocuments()}
										pagesNum={documentsPagesNum}
										setDataProps={({ prop, value }) => this.props.setDocumentsDataState({ prop, value })}
									/>
									<div className='col-4'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}

	/* PRIVATE METHOD */
	_apiPostDocuments(e) {
		if (e) { e.preventDefault() }
		this.props.apiPostDocuments(() => this.props.setDocumentsDataState({ prop: 'documentsCurrentPage', value: 1 }));
	}

	_apiPostDocumentsDownload(e, downloadUrl, name) {
		e.preventDefault();
		this.props.apiPostDocumentsDownload(downloadUrl, name, () => this._toggleModal());
	}

	_clearDataDocuments() {
		this.props.clearDataDocuments();
	}

	_compareDateStartToEnd = (date, format, numberDays, type) => {
		const dateUser = moment(date).add(numberDays, type).format("yyyyMMDD");
		const dateNow = getMomentNow("yyyyMMDD");

		if (dateNow > dateUser) {
			// date is past
			return moment(date).add(numberDays, type).format(format);
		}
		// date is future
		return getMomentNow(format)
	};

	_handleValueChange = (prop, value) => {
		this.props.setDocumentsDataState({ prop, value });
	};

	_handleValueDecimalChange = (prop, value) => {
		this.props.setDocumentsDataState({ prop, value: parseDecimal(value) });
	};

	_setFormQueries = (formQueries) => {
		const dateStart = formQueries.dateStart
		const dateEnd = formQueries.dateEnd
		const documentQuantityType = formQueries.documentQuantityType
		const documentSearchType = formQueries.documentSearchType
		const documentType = formQueries.documentType
		const idReference = formQueries.idReference
		this.props.setDocumentsDataState({ prop: 'dateStart', value: dateStart });
		this.props.setDocumentsDataState({ prop: 'dateEnd', value: dateEnd });
		this.props.setDocumentsDataState({ prop: 'documentQuantityType', value: documentQuantityType });
		this.props.setDocumentsDataState({ prop: 'documentSearchType', value: documentSearchType });
		this.props.setDocumentsDataState({ prop: 'documentType', value: documentType });
		this.props.setDocumentsDataState({ prop: 'idReference', value: idReference });
	}

	_setFormSearch = (formSearch) => {
		// formSearch = "?s=1234-12-12&e=1234-12-12"
		const getDate = (varName) => formSearch.substr(formSearch.indexOf(varName) + 2, 10)
		const dateStart = getDate('s')
		const dateEnd = getDate('e')
		this.props.setDocumentsDataState({ prop: 'dateStart', value: dateStart });
		this.props.setDocumentsDataState({ prop: 'dateEnd', value: dateEnd });
		this.props.setDocumentsDataState({ prop: 'documentQuantityType', value: DocumentAmountTypeStatus.ALL_AMOUNTS });
		this.props.setDocumentsDataState({ prop: 'documentType', value: DocumentTypeStatus.BILL });
	}

	_toggleModal() {
    const documentDownloadResponse = document.getElementById('documentDownloadResponse');
    if (documentDownloadResponse.classList.contains('invisible')) {
      documentDownloadResponse.classList.remove('invisible')
      this.setState({ isModalShown: true })
    } else {
      documentDownloadResponse.classList.add('invisible')
      this.setState({ isModalShown: false });
    }
  }

}

const mapStateToProps = ({ DocumentsReducer }) => {
	const {
		dateStart, dateEnd, dataDocuments, documentMinQuantity,
		documentQuantityType, documentMaxQuantity,
		documentSearchType, documentType, idReference,
		isLoadingPostDocuments, isLoadingPostDocumentsDownload, documentsTotalResults,
		documentsCurrentPage,
		documentsLimit, documentsPagesNum
	} = DocumentsReducer;

	return {
		dateStart, dateEnd, dataDocuments, documentMinQuantity,
		documentQuantityType, documentMaxQuantity,
		documentSearchType, documentType, idReference,
		isLoadingPostDocuments, isLoadingPostDocumentsDownload, documentsTotalResults,
		documentsCurrentPage,
		documentsLimit, documentsPagesNum
	};
};

const mapStateToPropsAction = {
	apiPostDocuments,
	clearDataDocuments,
	apiPostDocumentsDownload,
	setDocumentsDataState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(DocumentsContainer)
