import Types from "./Types";
import FormValidationManager from '../../../utils/managers/FormValidationManager';
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import awsconfig from '../../../aws-exports.js';
import { strings } from '../../../resources/locales/i18n.js';
import { isDev } from '../../../utils/Utils';
import { clearDataLogin, showMessageAfterRedirectInLogin } from '../login/LoginActions.js';

export const changeUserPassword = (redirectSuccess) => async (dispatch, getState) => {
  const { code, password, repeatPassword, username } = getState().RememberPasswordReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formRememberPasswordValidationCode({ code, password, repeatPassword });
  if (error !== null && error.length > 1) {
    return dispatch({
      type: Types.REMEMBER_PASSWORD_FAILED,
      payload: error
    });
  }
  dispatch(setRememberPasswordState({ prop: 'errorRequest', value: '' }));

  // -- 2. Request Code
  dispatch(setRememberPasswordState({ prop: 'isLoading', value: true }));
  await getUser(username.trim()).confirmPassword(code, password, {
    onSuccess: data => {
      if (isDev()) console.log('successful password change');
      dispatch(redirectToLogin(redirectSuccess));
    },
    onFailure: err => {
      if (err) {
        if (isDev()) console.log('onFailure', err);
        dispatch(responseError(err));
      }
    }
  })
  dispatch(setRememberPasswordState({ prop: 'isLoading', value: false }));
};

export const clearDataRememberPassword = () => ({
  type: Types.CLEAR_DATA_REMEMBER_PASSWORD,
});

export const getUser = (username) => {
  const poolData = {
    UserPoolId: awsconfig.aws_user_pools_id,
    ClientId: awsconfig.aws_user_pools_web_client_id
  };
  const UserPool = new CognitoUserPool(poolData);
  return new CognitoUser({
    Username: username.toLowerCase(),
    Pool: UserPool
  });
}

export const getValidationCode = () => async (dispatch, getState) => {
  const { username } = getState().RememberPasswordReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formRememberPasswordUsername({ username });
  if (error !== null && error.length > 1) {
    return dispatch({
      type: Types.REMEMBER_PASSWORD_FAILED,
      payload: error
    });
  }
  dispatch(setRememberPasswordState({ prop: 'errorRequest', value: '' }));

  // -- 2. Request
  dispatch(setRememberPasswordState({ prop: 'isLoading', value: true }));
  setTimeout(() => {

    getUser(username.trim()).forgotPassword({
      onSuccess: data => { if (isDev()) console.log('onSuccess', data) },
      onFailure: err => {
        if (isDev()) { console.log('onFailure', err) }
        dispatch(responseError(err));
      },
      inputVerificationCode: data => {
        if (isDev()) { console.log('inputCode', data) }
        dispatch(setRememberPasswordState({
          prop: 'error', value: []
        }));
        dispatch(responseSuccess())
      }
    })
    // TO QUICKLY CHECK OF MODAL COMMENT getUser().forgotPassword and use next dispatch:
    // dispatch(responseSuccess())
    dispatch(setRememberPasswordState({ prop: 'isLoading', value: false }));
  }, 800)
};

export const redirectToLogin = (redirectSuccess) => async (dispatch) => {
  dispatch(clearDataRememberPassword());
  redirectSuccess();
  dispatch(showMessageAfterRedirectInLogin({ prop: 'logoutMessage', value: strings('rememberPasswordView.passwordRestored') }));
}

export const responseError = (error) => async (dispatch) => {
  if (error && error.message && error.code) {
    let valueMessage = ''
    if (error.code === "UserNotFoundException") {
      valueMessage = strings('rememberPasswordView.emailNotFound');
    } else if (error.code === "LimitExceededException") {
      valueMessage = strings('rememberPasswordView.limitExceeded');
    } else if (error.code === "CodeMismatchException") {
      valueMessage = strings('rememberPasswordView.codeNotValid');
    } else if (error.code === "NotAuthorizedException") {
      valueMessage = strings('rememberPasswordView.notAuthorizedException');
    } else {
      valueMessage = error.message;
    }

    dispatch(setRememberPasswordState({
      prop: 'error', value: [{
        key: "request",
        value: valueMessage
      }]
    }));
  }
};

export const responseSuccess = () => async (dispatch) => {
  // dispatch(clearDataRememberPassword());
  dispatch(setRememberPasswordState({ prop: 'codeSent', value: 'true' }))
  dispatch(setRememberPasswordState({ prop: 'codeSentMessage', value: strings('rememberPasswordView.codeSentMessage') }));
}

export const setRememberPasswordState = ({ prop, value }) => ({
  type: Types.SET_REMEMBER_PASSWORD_STATE,
  payload: { prop, value }
})

export const validateOnBlurRememberPasswordUsername = (inputType) => async (dispatch, getState) => {
  const { error, username } = getState().RememberPasswordReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formRememberPasswordUsername({ username });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }

  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError) }
  }

  return dispatch({
    type: Types.REMEMBER_PASSWORD_FAILED,
    payload: error.slice()
  });
}

export const validateOnBlurRememberPasswordValidationCode = (inputType) => async (dispatch, getState) => {
  const { code, error, password, repeatPassword } = getState().RememberPasswordReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formRememberPasswordValidationCode({ code, password, repeatPassword });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }

  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError) }
  }

  return dispatch({
    type: Types.REMEMBER_PASSWORD_FAILED,
    payload: error.slice()
  });
}
