import Types from "./Types";

const INITIAL_STATE = {
  error: [],
  errorRequest: '',
  dateStart: '',
  dateEnd: '',
  documentMaxQuantity: '',
  documentMinQuantity: '',
  documentQuantityType: '',
  idReference: '',
  isLoadingResponsibleConsumption: false,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR RESPONSIBLE CONSUMPTION **/
    case Types.CLEAR_DATA_RESPONSIBLE_CONSUMPTION:
      return { ...INITIAL_STATE };

    /** GET RESPONSIBLE CONSUMPTION **/
    case Types.GET_RESPONSIBLE_CONSUMPTION_FAILED:
      return { ...state, errorRequest: action.payload};

      /** POST RESPONSIBLE CONSUMPTION **/
    case Types.POST_RESPONSIBLE_CONSUMPTION_FAILED:
      return { ...state, error: action.payload};

    case Types.POST_RESPONSIBLE_CONSUMPTION_SUCCESS:
      return { ...state };

    /** UPDATE STATE RESPONSIBLE CONSUMPTION **/
    case Types.SET_DATA_RESPONSIBLE_CONSUMPTION:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
