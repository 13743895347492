export default class Types {
  /** CLEAR **/
  static CLEAR_DATA_EDIT_ORG  = 'CLEAR_DATA_EDIT_ORG';

  /** POST EDIT_ORG **/
  static EDIT_ORG_FAILED      = 'EDIT_ORG_FAILED';
  static EDIT_ORG_SUCCESS     = 'EDIT_ORG_SUCCESS';

  /** UPDATE STATE **/
  static SET_EDIT_ORG_STATE   = 'SET_EDIT_ORG_STATE';
}
