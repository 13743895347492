import React, { Component } from 'react';

const ModalContainer = (props) => {
  const {
    closeAction,
    icon,
    iconStyle,
    isModalShown,
    message,
    modalId,
    primaryAction,
    primaryButtonText,
    secondaryAction,
    secondaryButtonText,
    style,
    title,
    titleStyle
  } = props

  const animationBgStyle = isModalShown ? {
    opacity: 0.8
  } : {}
  const animationModalStyle = isModalShown ? {
    marginTop: -50,
    opacity: 1
  } : {}

  return (
    <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id={modalId}>
      <div className="position-absolute">
        <div className="modal-dialog mb-5" role="document" style={{ marginTop: -180, opacity: 0, transition: 'opacity 0.4s ease-out, margin-top 0.4s ease-out', ...animationModalStyle }}>
          <div className="modal-content">
            <div className="modal-header" style={{ borderBottom: 'none' }}>
              <button type="button" className="close" aria-label="Close" onClick={() => closeAction()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center row px-5">
              <div className="row justify-content-center">
                <div className="col-12 mb-4 text-center">
                  {icon ?
                    <span className="material-icons icon-thanks" style={iconStyle}>
                      {icon}
                    </span>
                  : <span /> }
                  <span className="title-big d-block mb-3" style={titleStyle}>{title}</span>
                  <p className='px-5'>{message}</p>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
              {secondaryAction ?
                <button type="button" className="btn btn-out-lg mb-3" onClick={() => secondaryAction()}>
                  {secondaryButtonText}
                </button>
                : <div />
              }
              <button type="button" className="btn btn-primary mb-3" onClick={() => primaryAction()}>
                {primaryButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute w-100 h-100 bg-dark" style={{
        opacity: 0, zIndex: -1,
        transition: 'opacity 0.4s ease-out',
        ...animationBgStyle
      }} />
    </div>
  )

};


export { ModalContainer }
