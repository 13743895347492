import React from 'react';
import {strings} from '../../../resources/locales/i18n';


const PaperTicketPaymentDetails = (props) => {
  const {paymentDetailText} = props

  return(
    <div className={"mt-3"}>
      {paymentDetailText.split('\n').map(str => {
        if (str === ""){
          return <p className={'textPaperTicket'}><br/></p>
        }
        return  <p className={'textPaperTicket'}>{str}</p>
      })}
    </div>
  )
};

export default PaperTicketPaymentDetails
