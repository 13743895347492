import { strings } from '../../resources/locales/i18n.js';
import { getMomentDateFormat } from '../../utils/Utils.js'

export class TypeUsers {
  static ADMIN = 'Admin';
  static ASSISTANT = 'Assistant';
  static SUPER_ADMIN = 'SuperAdmin';
}

export default class BackOfficeManager {

  static getOrgName(user, orgData) {
    let orgName = '';
    if (user && user.family_name && orgData) {

      orgData.filter(org => {
        if (org.id_sap === user.family_name) {
          orgName = org.name
        }
      })
    }
    return user && user.family_name ?
      orgName : null;
  }

  static getPhoneNumber(data) {
    let E164Phone = '';
    let phone = '';
    if (data && data.phone_number) {
      E164Phone = data.phone_number;
      phone = E164Phone.replace('+34', '')
    }
    return data && data.phone_number ?
      phone : null;
  }

  static getProfileParse(data) {

    if (data && data.profile) {
      if (data.profile === TypeUsers.SUPER_ADMIN){
        return strings('createUserView.form.superAdministrator')
      }

      if (data.profile === TypeUsers.ADMIN){
        return strings('createUserView.form.admin')
      }

      return strings('createUserView.form.assistant')
    }

    return strings('createUserView.form.assistant')

  }

  static getRequestCardsNum(request) {
    let numCards = '';
    if (request && typeof request.numCards === 'number') {
      numCards = request.numCards === 0 ? strings('backOfficeView.requestsTable.excel') : request.numCards;
    }
    return request &&  typeof request.numCards === 'number' ? numCards : null
  }

  static getRequestDate(request) {
    let date = '';
    if (request && request.fecha) {
      date = getMomentDateFormat(request.fecha, 'L')
    }
    return request && request.fecha ? date : null
  }

  static getRequestEmail(request) {
    return request && request.email ? request.email : null
  }

  static getRequestOrderNumber(request) {
    return request && request.order_number ? request.order_number : null
  }

  static getRequestOrgName(request, orgData) {
    let orgName = '';
    if (request && request.id_sap && orgData) {

      orgData.filter(org => {
        if (org.id_sap === request.id_sap) {
          orgName = org.name
        }
      })
    }
    return request && request.id_sap ?
      orgName : null;
  }

  static getRequestType(request) {
    return request && request.isBuy ? strings('backOfficeView.requestsTable.buy') : strings('backOfficeView.requestsTable.recharge')
  }

};

