import Types from "./Types";

const INITIAL_STATE = {
  isMenuOpen: false
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case Types.CLEAR_MENU_STATE:
      return { ...INITIAL_STATE };

    /** UPDATE STATE DOCUMENTS **/
    case Types.SET_MENU_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };


    default:
      return state;
  }
}
