import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'


import { apiPostEmail, clearDataCostumerSupport, setSupportCostumerDataState, validateOnBlurCostumerSupport } from './CostumerSupportActions';

import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';
import { TextInput } from 'src/components';
import { localAssets } from '../../resources/assets/assets';
import { ConsultTypeOptions } from './CostumerSupportManager'

class CostumerSupportContainer extends Component {

  componentDidMount() {
    this._clearDataCostumerSupport();
  }
  render() {
    const userToken = this._getUserToken();

    const breadcrumbList = userToken ? [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.costumerSupport'), path: '#' },
    ] : [''];
    const title = userToken ? strings('viewName.costumerSupport') : strings('viewName.contact')

    return (
      <>
        <HeaderContainer title={title} breadcrumbList={breadcrumbList} />

        <section className="container wrapper py-5">
          <div className="row">
            {this.renderForm()}
            {this.renderAside()}
            {this.renderThanksModal()}
          </div>
        </section>
      </>
    )
  }

  renderAside() {
    return (
      <div className="col-12 col-lg-3 mt-5 mt-lg-0">
        <div className="card h-auto">
          <div className="sidebar-img" />
          <div className="sidebar-text pb-5">
            <div className="p-4">
              <span className="title-mid d-block">{strings('costumerSupportView.form.writeYourConsult')}</span>
            </div>
            <div className="px-4">
              {/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni ad suscipit fugiat delectus
                  tiae quaerat.</p> */}
              <p className="mb-2">{strings('costumerSupportView.aside.contactTelephone')}</p>
              <p className="text-mid normal mb-2">{strings('costumerSupportView.aside.number')}</p>
              <p className="m-0">{strings('costumerSupportView.aside.schedule')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderForm() {
    const userToken = this._getUserToken();
    const { cif, company, consult, consultType, email, error, isLoadingCostumerSupport, legalConsent, username, surnames, telephone } = this.props;
    return (
      <div className="col-12 col-lg-9 mb-4 mb-md-0">
        <div className="card p-4 p-sm-5">

          <form id="contactForm" onSubmit={(e) => this._apiPostEmail(e)} name="contactForm" action="" className="w-100 mt-3 mb-5">

            <div className="row pb-4">
              <div className="col-12">
                <p>{strings('costumerSupportView.sendUsAMessage')}</p>
              </div>
            </div>

            <div className="row pb-4">
              <div className="col-12 pb-3">
                <span className="title-mid d-block">{strings('costumerSupportView.form.title')}</span>
              </div>
              { /** USERNAME */}
              <div className="col-12 col-sm-6">
                <TextInput
                  title={strings('costumerSupportView.form.name')}
                  id="username"
                  name="username"
                  type="text"
                  error={error}
                  placeholder=""
                  onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                  value={username}
                  onBlur={(e) => this._validateOnBlur(e.target.id)}
                  onKeyDown={(e) => this._callTabFunction(e)}
                />
              </div>
              { /** SURNAMES */}
              <div className="col-12 col-sm-6">
                <TextInput
                  title={strings('costumerSupportView.form.surname')}
                  id="surnames"
                  name="surnames"
                  type="text"
                  error={error}
                  placeholder=""
                  onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                  value={surnames}
                  onBlur={(e) => this._validateOnBlur(e.target.id)}
                  onKeyDown={(e) => this._callTabFunction(e)}
                />
              </div>
              { /** COMPANY */}
              <div className="col-12 col-sm-6">
                <TextInput
                  title={strings('costumerSupportView.form.company')}
                  id="company"
                  name="company"
                  type="text"
                  error={error}
                  placeholder=""
                  onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                  value={company}
                  onBlur={(e) => this._validateOnBlur(e.target.id)}
                  onKeyDown={(e) => this._callTabFunction(e)}
                />
              </div>
              { /** TELEPHONE */}
              <div className="col-12 col-sm-6">
                <TextInput
                  title={strings('costumerSupportView.form.telephone')}
                  id="telephone"
                  name="telephone"
                  type="text"
                  error={error}
                  placeholder=""
                  onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                  value={telephone}
                  onBlur={(e) => this._validateOnBlur(e.target.id)}
                  onKeyDown={(e) => this._callTabFunction(e)}
                />
              </div>
              { /** EMAIL */}
              <div className="col-12 col-sm-6">
                <TextInput
                  title={strings('costumerSupportView.form.email')}
                  id="email"
                  name="email"
                  type="text"
                  error={error}
                  placeholder=""
                  onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                  value={email}
                  onBlur={(e) => this._validateOnBlur(e.target.id)}
                  onKeyDown={(e) => this._callTabFunction(e)}
                />
              </div>
              { /** CIF */}
              {userToken ? <div /> :
                <div className="col-12 col-sm-6">
                  <TextInput
                    title={strings('costumerSupportView.form.cif')}
                    id="cif"
                    name="cif"
                    type="text"
                    error={error}
                    placeholder=""
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={cif}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                  />
                </div>
              }
            </div>
            {userToken ?
              <div className="row pb-4">
                <div className="form-group">
                  <div className="col-12 pb-3">
                    <span className="title-mid d-block">{strings('costumerSupportView.form.consultType')}</span>
                  </div>
                  <div className="col-12 col-sm-6">
                    { /** CONSULT TYPE */}
                    <select
                      required
                      id="consultType"
                      aria-label="Default select example"
                      value={consultType}
                      onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    >
                      <option value="">{strings('costumerSupportView.form.select.selectAnOption')}</option>
                      <option value={ConsultTypeOptions.CARDS_INFO}>{strings('costumerSupportView.form.select.cardsInfo')}</option>
                      <option value={ConsultTypeOptions.CARDS_USES}>{strings('costumerSupportView.form.select.cardsUses')}</option>
                      <option value={ConsultTypeOptions.CONSULT_BALANCE}>{strings('costumerSupportView.form.select.consultBalance')}</option>
                      <option value={ConsultTypeOptions.CANCEL_CARD}>{strings('costumerSupportView.form.select.incidence.cancelCard')}</option>
                      <option value={ConsultTypeOptions.ACTIVE_CARD}>{strings('costumerSupportView.form.select.incidence.activeCard')}</option>
                      <option value={ConsultTypeOptions.UNSUBSCRIBE}>{strings('costumerSupportView.form.select.unsubscribe')}</option>
                    </select>
                  </div>
                </div>
              </div>
              : <div />}
            <div className="row">
              <div className="col-12 pb-3">
                <span className="title-mid d-block">{strings('costumerSupportView.form.writeYourConsult')}</span>
              </div>
              <div className="col-12">
                <div className="form-floating form-group">
                  { /** CONSULT */}
                  <TextInput
                    title={strings('costumerSupportView.form.consult')}
                    id="consult"
                    name="consult"
                    type="text"
                    error={error}
                    placeholder={strings('costumerSupportView.form.writeYourConsult')}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={consult}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                    inputType="textarea"
                  />
                </div>
              </div>
            </div>

            <div className="row pb-4">
              <div className="col-12">
                <div className="layer-legal">
                  <p className="m-0">{strings('costumerSupportView.form.layerLegal1')} <a href="mailto:dpd@grupodinosol.es." style={{fontStyle: 'italic', fontWeight:600}}>dpd@grupodinosol.es.</a> {strings('costumerSupportView.form.layerLegal2')} <Link to={Routing.policy} style={{fontWeight:600}} target="_blank" rel="noopener noreferrer">{strings('costumerSupportView.form.layerLegalLink')}</Link> </p>
                </div>
                <div className="layer-legal mt-2">
                  <p className="m-0"><Link to={Routing.legal} style={{fontWeight:600}} target="_blank" rel="noopener noreferrer">{strings('costumerSupportView.form.legalNotice')}</Link> </p>
                </div>
              </div>
            </div>
            { /** GIVE CONSENT */}
            <div className="row">
              <div className="col-12">
                <div className="form-check">
                  <input type="checkbox"
                    required
                    id="legalConsent"
                    name="legalConsent"
                    className="form-check-input"
                    value={legalConsent}
                    checked={legalConsent}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    {strings('costumerSupportView.form.giveConsent')}
                  </label>
                </div>
              </div>
            </div>
            { /** BUTTON */}
            <div className="row pt-4">
              <div className="col-12">
                <div className="form-group d-flex justify-content-start">
                  <button type="submit" className="btn btn-primary">{isLoadingCostumerSupport ?
                    <Loading /> : strings('button.send')}</button>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div >
    )
  }

  renderThanksModal() {
    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="thanksModal">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ opacity: 1 }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this._clearDataCostumerSupport() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body d-flex justify-content-center px-5">
                <div className="row justify-content-center">
                  <div className="col-12 mb-4 text-center">
                    <span className="material-icons icon-thanks">
                      mark_chat_read
                      </span>
                    <span className="title-big d-block mb-3">{strings('thanksView.thankYou')}</span>
                    <p>{strings('thanksView.willContactYou')}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }} />
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{ opacity: .8, zIndex: -1 }} />
      </div>
    )
  }

  /* PRIVATE METHOD */
  _apiPostEmail(e) {
    e.preventDefault();
    this.props.apiPostEmail(() => this._handleViewChange())
      .then(() => { this._scrollToFirstInvalidInputIfError() });
  }
  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }
  _clearDataCostumerSupport() {
    this.props.clearDataCostumerSupport();
  }

  _getUserToken() {
    const { accessToken } = this.props;
    return accessToken;
  }

  _handleValueChange = (prop, value) => {
    this.props.setSupportCostumerDataState({ prop, value });
  };

  _handleViewChange = () => {
    const userToken = this._getUserToken();

    if (userToken) {
      const { history } = this.props;
      history.push(Routing.thanks);
    } else {
      this._toggleModal();
    }
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurCostumerSupport(inputType)
  }

  _scrollToFirstInvalidInputIfError() {
    const { error } = this.props;
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'})
      firstError.focus({preventScroll:true})
    }
  }

  _toggleModal() {
    const thanksModal = document.getElementById('thanksModal');
    if (thanksModal.classList.contains('invisible')) {
      thanksModal.classList.remove('invisible')
    } else {
      thanksModal.classList.add('invisible')
    }
  }

}

const mapStateToProps = ({ CostumerSupportReducer, UserReducer }) => {
  const { cif, company, consult, consultType, email, error, isLoadingCostumerSupport, legalConsent, username, surnames, telephone } = CostumerSupportReducer;
  const { accessToken } = UserReducer;
  return {
    accessToken, cif, company, consult, consultType, email, error, isLoadingCostumerSupport, legalConsent, username, surnames, telephone
  }
}

const mapStateToPropsAction = {
  apiPostEmail,
  clearDataCostumerSupport,
  setSupportCostumerDataState,
  validateOnBlurCostumerSupport,
};

export default connect(mapStateToProps, mapStateToPropsAction)(CostumerSupportContainer);
