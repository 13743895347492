import Languages from 'react-languages';
import I18n from 'i18n-js';
import en from './en';
import es from './es';
import moment from 'moment';
import 'moment/min/locales';


// Default locale
I18n.locale = Languages.substring(0, 2) //detectBrowserLanguage().substring(0, 2)

// Enable fallback if you want `en-US` and `en-GB` to fallback to `en`
// I18n.fallbacks = true;

// Available languages
I18n.translations = {
  en,
  es,
};

const getLocale = (start, end) => {
  if (typeof start !== 'undefined' && typeof end !== 'undefined') {
    return Languages.substring(start, end); //detectBrowserLanguage().substring(start, end);
  }
  return Languages.substring(0, 2)  //detectBrowserLanguage().substring(0, 2);
};

const initTranslations = () => {
  const locale = getLocale();
  if (locale) moment.locale(locale.substring(0, 2));
  return I18n.translations
};

/**
 * The method we'll use instead of a regular string
 * @param name
 * @param params
 */
const strings = (name, params = {}) => I18n.t(name, params);

export { I18n, getLocale, initTranslations, strings };
