import React, {Component} from 'react'

import {strings} from '../../resources/locales/i18n.js';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import Routing from '../routing/Routing.jsx';

class ThanksContainer extends Component {

  state = {
    progress: 100
  }

  componentDidMount() {
    this._countDown();
  }
  componentWillUnmount() {
    this._destroyCountDown();
  }

  render() {
    return (
      <>
        <HeaderContainer />
        <div className="progress rounded-0">
          <div
            className={`progress-bar`}
            style={{ width: `${this.state.progress}%`, background: '#77BD1D' }}
            role="progressbar" aria-valuenow={this.state.progress}
            aria-valuemin="0" aria-valuemax="100"
          />
          <h1>
          </h1>
        </div>
        <section className="container wrapper wrapper--content w-75 pt-5 pb-5" style={{ left: 0 }}>
          <div className="row mt-5 justify-content-center">
            <div className="col-12 col-md-6 mb-4 text-center">
              <span className="material-icons icon-thanks">
                mark_chat_read
            </span>
              <span className="title-big d-block mb-3">{strings('thanksView.thankYou')}</span>
              <p>{strings('thanksView.willContactYou')}</p>
            </div>
          </div>
        </section>
      </>
    )
  }

  /** PRIVATE METHOD **/

  _countDown() {
    let counter = 100;
    setTimeout(() => {
      const idInterval = setInterval(() => {
        counter--;
        this.setState({ progress: counter })
        if (counter === 0) {
          const { history } = this.props;
          history.push(Routing.dashboard);
          clearInterval(this.state.idInterval)

        }
      }, 100);
      this.setState({ idInterval: idInterval })
    }, 600);
  }

  _destroyCountDown() {
    clearInterval(this.state.idInterval)
  }
}

export default ThanksContainer;
