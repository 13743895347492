import React from 'react';
import PropTypes from 'prop-types';
import {strings} from '../../../resources/locales/i18n';
import PaperTicketManager from '../../paperTicket/PaperTicketManager';

let totalPromotion = 0;
let totalPromotionString = "";

const PaperTicketPromotion = (props) => {
  const { bpText, documentText, promotions, image, qrAccessToken } = props;

  totalPromotion = 0;
  totalPromotionString = "";

  return (
    <div>

      {promotions.length !== 0 ? promotion(promotions) : <div/> }
      <div className={'d-flex flex-column justify-content-center align-items-center'}>
        {/*<img style={{height: 150, width: 150}}
          src={"data:image/jpeg;base64, data:image/png;base64, g+tYY2D8x7F6sFqDpxSdBkejbeACehhMJzBgfHuJfui++Z766XhGsBXCENbJWkYvMPqUoClWXNu8gwjPabTlLmcabHc2hCApXzg2rF8tiLRTQwwgMoj/apoyKByAKVa5IrlAjkjW6DfeeM6QSwaEwQUYLe/LY8FUhJ/FwQ0umyU="}
        />*/}
        {/*<Image
          source={{
            uri : image,
            headers: {
              Cookie: `hiperdino-auth = ${qrAccessToken}`
            } }}
          style={styles.imageQr}
        />*/}
        <p className={'textPaperTicketTitle'}>{documentText}</p>
      </div>

      <div className={'d-flex flex-column justify-content-center align-items-center mt-3'}>
        {bpText.split('\n').map(str => <p className={'textPaperTicket'}>{str}</p>)}
      </div>

    </div>
  )
};

const promotion = (promotion) => {

  return(
    <div>
      {/* PROMOTION */}
      <div className={'d-flex align-items-center w-100 borderBottom mt-3'}>
        <p className={'textPaperTicketTitle'}>{strings('paperTicket.appliedPromotion').toUpperCase()}</p>
      </div>

      {/* ITEMS */}
      {promotion.map((item, index) => {return(renderItemPromotion(item, index))})}

      <div className={"d-flex flex-column w-100 mt-4"}>
        <div className={"d-flex justify-content-end w-100 flex-row"}>
          <div style={{width: '75%'}}>
            <h4 className={"textPaperTicket text-right"}>
              {strings('paperTicket.totalPromotion').toUpperCase()}
            </h4>
          </div>

          <div style={{width: '25%'}}>
            <h4 className={"textPaperTicket text-right"}>
              {calculatePromotions(promotion)}
            </h4>
          </div>

        </div>
      </div>
    </div>
  )
};

const calculatePromotions = (promotions) => {

  let totalPromotion = 0;
  promotions.map((item) => {
    let amount = PaperTicketManager.getItemAmount(item);
    let amountFloat = amount.replace(",", ".");
    totalPromotion= totalPromotion + parseFloat(amountFloat);
  });

  return  totalPromotion.toFixed(2).replace(".", ",");
};

const renderItemPromotion = (item) => {
  const description = PaperTicketManager.getItemDescription(item);
  const amount      = PaperTicketManager.getItemAmount(item);

  return(
    <div>
      {/* ITEM PROMOTION */}
      <div className={"d-flex"}>
        <div style={{flex:0.90}}>
          <p className={'textPaperTicket'}>{description}</p>
        </div>

        <div className={"d-flex justify-content-end"} style={{flex:0.10}}>
          <p className={'textPaperTicket'}>{amount}</p>
        </div>
      </div>

    </div>
  )
};


PaperTicketPromotion.propTypes = {
  image:       PropTypes.string,
};

export default PaperTicketPromotion
