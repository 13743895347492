import React, { Component } from 'react';
import { connect } from 'react-redux';

// Action
import { apiGetCardDetails } from '../cards/CardsActions.js'
import { apiGetCardTransactions, apiGetTransactionsDownload, setCardTransactionsDataState } from './CardTransactionsActions.js'

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from '../../components';

// Resources
import CardsManager from '../cards/CardsManager.js'
import { getMomentDateFormat } from '../../utils/Utils.js'
import Routing from '../routing/Routing.jsx'
import { strings } from '../../resources/locales/i18n';
import {getAsset, localAssets} from "../../resources/assets/assets";
import {ImageSvg} from "../../components/ImageSvg";


class CardTransactionsContainer extends Component {

  componentDidMount() {
    this._apiGetCardDetails()
    this._apiGetCardTransactions()
  }

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.cardList'), path: Routing.cardList },
      { name: strings('viewName.cardDetail'), path: '#' }
    ]

    return (
      <>
        <HeaderContainer title={strings('viewName.cardDetail')} breadcrumbList={breadcrumbList} />
        {this.renderCardData()}
        <div id='tableResult'>
          {this.renderResultsAndTable()}
        </div>
      </>
    )
  }


  renderCardData() {
    const { dataIdCard, isLoadingCardDetails, isLoadingGetTransactions, isLoadingPostTranslationDownload, totalResults } = this.props;
    const currentExpenditure = CardsManager.getTotalExpense(dataIdCard)
    const date = getMomentDateFormat(CardsManager.getInitDate(dataIdCard), 'LL')
    const status = CardsManager.getStatus(dataIdCard)
    const uuid = CardsManager.getUuid(dataIdCard)

    if (isLoadingCardDetails || isLoadingGetTransactions) {
      return (
        <div className={'d-flex justify-content-center align-items-center'} style={{ height: 100 }}>
          <Loading color={'#006138'} height={35} width={35} />
        </div>
      )
    }

    if (!dataIdCard || dataIdCard.length === 0) {
      return <div className="container wrapper wrapper--content">
        <div className="title-big my-4 py-5">{strings('transactionsView.cardNotFound')} {uuid}</div>
      </div>
    }
    return <section className="container wrapper wrapper--hero pt-5 pb-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <p className="m-0">{strings('transactionsView.cardNumber')} <span>{uuid}</span></p>
          <p className="m-0">{strings('transactionsView.cardType')} <span>{status}</span></p>
          <p>{strings('transactionsView.dischargeDate')} <span>{date}</span></p>
          <p className="text-mid normal">{strings('transactionsView.currentExpenditure')} <span className="text-mid corp">{currentExpenditure} €</span></p>
        </div>
        <div className="col-12 col-md-3 ml-auto">
          {totalResults ?
            <a onClick={() => this._apiGetTransactionsDownload()} className="btn-secundary">
              {isLoadingPostTranslationDownload ? <Loading color={'#77bd1e'} /> :
                <>
                  {strings("transactionsView.downloadTransactions")}
                  <span className="material-icons p-1">download</span>
                </>}
            </a>
            :
            <div />
          }
        </div>
      </div>
    </section>
  }

  renderItemTransaction() {
    const { cardTransactions, isLoadingCardDetails } = this.props;

    if (!cardTransactions) {
      return <div />
    }

    if (!isLoadingCardDetails) {
      return (
        cardTransactions.map((item, idx) => {
          const amount = CardsManager.getTotalTicketAmount(item);
          const centerId = CardsManager.getCenterId(item);
          const centerName = CardsManager.getCenterName(item);
          const date = getMomentDateFormat(CardsManager.getTransactionDate(item), 'LL');
          const ticketId = CardsManager.getTicketId(item);
          const isOnline = CardsManager.getIsOnline(item);

          return (
            <tr key={idx}>
              <td className='align-middle'>
                {isOnline ?
                  <img style={{width: "35px", marginLeft: "9px"}} className="img-fluid" src={localAssets.icOrderOnline} />
                  :
                  <ImageSvg className="img-fluid" height={50} width={50} src={getAsset("icOrderTicket")} />
                }

              </td>
              <td className="align-middle">{date}</td>
              <td className="align-middle">{centerName ? centerName : centerId}</td>
              <td className="align-middle">{amount} €</td>
              <td className="align-middle">
                <td className="align-middle">
                  {ticketId ? <a onClick={() => this._handleItemSelect(item, ticketId)} className="btn-out">{strings('button.consult')}</a>
                    : <div> {strings('transactionsView.noDigitalTicket')}</div>
                  }
                </td>
              </td>
            </tr>
          )
        })
      )
    }
  }

  renderResultsAndTable() {
    const { dataIdCard, isLoadingCardDetails, isLoadingGetTransactions, totalResults } = this.props;
    const uuid = CardsManager.getUuid(dataIdCard);

    if (!dataIdCard || isLoadingCardDetails) {
      return <div />
    }

    if (isLoadingGetTransactions) {
      return <Loading color={'#77bd1e'} />
    }

    if (!totalResults || totalResults === 0) {
      return (
        <section className="container wrapper wrapper--content pt-5 pb-5">
          <div className="row mb-4">
            <div className="col-10">
              {strings('transactionsView.transactionNotFound')} {uuid}
            </div>
          </div>
        </section>
      )
    }
    return (
      <section className="container wrapper wrapper--content pt-5 pb-5">
        <div className="row mb-4">
          <div className="col-6">
            {totalResults} {strings('transactionsView.transactions')}
          </div>
        </div>
        <div className="row module-table">
          <div className="col-12 mb-md-0">
            <div className="card p-0">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th/>
                      <th>{strings('title.date')}</th>
                      <th>{strings('title.center')}</th>
                      <th>{strings('title.expense')}</th>
                      <th/>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderItemTransaction()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>)
  }


  /** PRIVATE METHOD **/
  _apiGetCardDetails() {
    const { idParams } = this.props
    this.props.apiGetCardDetails(idParams);
  }

  _apiGetCardTransactions() {
    const { idParams } = this.props
    this.props.apiGetCardTransactions(idParams);
  }

  _apiGetTransactionsDownload() {
    const { idParams } = this.props
    this.props.apiGetTransactionsDownload(idParams);
  }

  _handleItemSelect = (value, ticketId) => {
    this._handleValueChange('transactionsItemSelect', value);
    const { history } = this.props;
    history.push(`/ticket-digital/${ticketId}`);
  };

  _handleValueChange = (prop, value) => {
    this.props.setCardTransactionsDataState({ prop, value });
  };

}

const mapStateToProps = ({ CardsReducer, CardTransactionsReducer }) => {
  const { dataIdCard, isLoadingCardDetails } = CardsReducer;
  const {
    cardTransactions,
    cardsTransactionsCurrentPage,
    cardsTransactionsLimit,
    isLoadingGetTransactions,
    isLoadingPostTranslationDownload,
    pagesNum,
    totalResults
  } = CardTransactionsReducer;
  return {
    cardTransactions,
    cardsTransactionsCurrentPage,
    cardsTransactionsLimit,
    dataIdCard,
    isLoadingCardDetails,
    isLoadingGetTransactions,
    isLoadingPostTranslationDownload,
    pagesNum,
    totalResults
  };
};

const mapStateToPropsAction = {
  apiGetCardDetails,
  apiGetCardTransactions,
  apiGetTransactionsDownload,
  setCardTransactionsDataState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(CardTransactionsContainer);
