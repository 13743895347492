class Routing {

  /** APP **/
  static app = '/';

  /** Auth Scene **/
  static authScene               = '/authScene';
  static confirmation            = '/confirmar-cuenta';
  static login                   = '/login';
  static maintenancePage         = '/mantenimiento';
  static newPassword             = '/crear-contraseña';
  static register                = '/register';
  static rememberPassword        = '/rememberPassword';
  static rememberPasswordMessage = '/rememberPasswordMessage';

  /** Cards **/
  static cardDetail              = '/tarjetas-compra/:id';
  static cardList                = '/tarjetas-compra';

  /** Contact **/
  static contact                 = '/contacto';

  /** Costume Support **/
  static costumerSupport         = '/atencion-al-cliente';

  /** Home - Dashboard **/
  static dashboard               = '/dashboard'

  /** Digital Ticket **/
  static digitalTicket           = '/ticket-digital/:id';

  /** Documents **/
  static documents               = '/facturas-anexos';

  /** Legal **/
  static legal                   = '/aviso-legal';

  /** Notifications **/
  static notifications           = '/notificaciones';

  /** No where **/
  static nowhere                 = '/#';

  /** Pin Cards **/
  static cardsPin                   = '/tarjertas-pin';

  /** Policy **/
  static policy                  = '/politica-de-privacidad';

  /** Purchase Cards **/
  static purchaseCards           = '/compra-de-tarjetas';

  /** Recharge Cards **/
  static rechargeCards           = '/recarga-de-tarjetas';

  /** Responsible Consumption Download **/
  static responsibleConsumption  = '/informe-consumo';

  /** Thanks **/
  static thanks                  = '/gracias';

  /** User Profile **/
  static userProfile             = '/perfil-de-usuario';


  /** Back Office Routes **/

  /** Home **/
  static backOffice              = '/backoffice'

  /** Create User **/
  static createUser              = `${this.backOffice}/crear-usuario`

  /** Create Orf **/
  static createOrg               = `${this.backOffice}/crear-organización`

  /** Edit Org **/
  static editOrg                 = `${this.backOffice}/editar-organización`

  /** Edit User **/
  static editUser                = `${this.backOffice}/editar-usuario`
}
export default Routing
