import { strings } from '../../resources/locales/i18n.js';

export const RechargeableStatus = {
  NO: false,
  SELECT: '',
  YES: true,
}

export const RestrictionsOptions = [
  { value: strings('purchaseCardsView.form.restrictions.alcohol'), label: strings('purchaseCardsView.form.restrictions.alcohol') },
  { value: strings('purchaseCardsView.form.restrictions.tobacco'), label: strings('purchaseCardsView.form.restrictions.tobacco') },
  { value: strings('purchaseCardsView.form.restrictions.cosmetics'), label: strings('purchaseCardsView.form.restrictions.cosmetics') },
  { value: strings('purchaseCardsView.form.restrictions.telephony'), label: strings('purchaseCardsView.form.restrictions.telephony') },
  { value: strings('purchaseCardsView.form.restrictions.digitalPlatforms'), label: strings('purchaseCardsView.form.restrictions.digitalPlatforms') },
  { value: strings('purchaseCardsView.form.restrictions.pastries'), label: strings('purchaseCardsView.form.restrictions.pastries') },
  { value: strings('purchaseCardsView.form.restrictions.toys'), label: strings('purchaseCardsView.form.restrictions.toys') },
  { value: strings('purchaseCardsView.form.restrictions.candies'), label: strings('purchaseCardsView.form.restrictions.candies') },
  { value: strings('purchaseCardsView.form.restrictions.softDrink'), label: strings('purchaseCardsView.form.restrictions.softDrink') },
  { value: strings( 'purchaseCardsView.form.restrictions.colonies'), label: strings('purchaseCardsView.form.restrictions.colonies') },
  { value: strings('purchaseCardsView.form.restrictions.perfumes'), label: strings('purchaseCardsView.form.restrictions.perfumes') },
];

export default class PurchaseCardsManager {
}