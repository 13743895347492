import React, { Component } from 'react'

import { strings } from '../../resources/locales/i18n.js';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';

class PolicyContainer extends Component {
  render() {
    return (
      <>
        <HeaderContainer />
        <section className="container wrapper wrapper--content pt-5 pb-5 col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8" style={{ left: 0 }}>
          <div className="card p-5 d-flex justify-content-between">
            <div className="input-top--left d-flex flex-column">
              <div className="title-mid normal text-center my-5">{strings('policyView.title')}</div>
              <span className="normal">{strings('policyView.intro')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.responsible.title')}</span>
              <span className="normal">{strings('policyView.responsible.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.purpose.title')}</span>
              <span className="normal">{strings('policyView.purpose.text')}</span>
              <ul className='pt-4'>
                <li className="mb-2"> <span className="normal">{strings('policyView.purpose.listFirst')}</span> </li>
                <li> <span className="normal">{strings('policyView.purpose.listSecond')}</span> </li>
              </ul>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.conservation.title')}</span>
              <span className="normal">{strings('policyView.conservation.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.legitimation.title')}</span>
              <span className="normal">{strings('policyView.legitimation.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.recipients.title')}</span>
              <span className="normal">{strings('policyView.recipients.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.rights.title')}</span>
              <span className="normal">{strings('policyView.rights.textFirst')}</span>
              <ul className='pt-4'>
                <li> <span className="normal">{strings('policyView.rights.listFirst')}</span> </li>
                <li> <span className="normal">{strings('policyView.rights.listSecond')}</span> </li>
                <li> <span className="normal">{strings('policyView.rights.listThird')}</span> </li>
                <li> <span className="normal">{strings('policyView.rights.listFourth')}</span> </li>
                <li> <span className="normal">{strings('policyView.rights.listFifth')}</span> </li>
              </ul>
              <span className="normal">{strings('policyView.rights.textSecond')} <a href='mailto:dpd@grupodinosol.es'>dpd@grupodinosol.es</a></span>
              <span className="normal mt-2">{strings('policyView.rights.textThird')} <a href='www.agpd.es'>(www.agpd.es)</a></span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.security.title')}</span>
              <span className="normal">{strings('policyView.security.text')}</span>

              <span className="title-lite normal mt-4 mb-2">{strings('policyView.links.title')}</span>
              <span className="normal">{strings('policyView.links.text')}</span>
            </div>
          </div>
        </section>
      </>
    )
  }

  /** PRIVATE METHOD **/

}

export default PolicyContainer;
