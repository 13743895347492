
export default class Types {
  /** CLEAR RESPONSIBLE CONSUMPTION **/
  static CLEAR_DATA_RESPONSIBLE_CONSUMPTION = 'CLEAR_RESPONSIBLE_CONSUMPTION';

  /** GET RESPONSIBLE CONSUMPTION **/
  static GET_RESPONSIBLE_CONSUMPTION_FAILED = 'GET_RESPONSIBLE_CONSUMPTION_FAILED';

  /** POST RESPONSIBLE CONSUMPTION **/
  static POST_RESPONSIBLE_CONSUMPTION_FAILED = 'POST_RESPONSIBLE_CONSUMPTION_FAILED';
  static POST_RESPONSIBLE_CONSUMPTION_SUCCESS = 'POST_RESPONSIBLE_CONSUMPTION_SUCCESS';

  /** UPDATE STATE **/
  static SET_DATA_RESPONSIBLE_CONSUMPTION = 'SET_DATA_RESPONSIBLE_CONSUMPTION';
}
