export default class Types {
  /** CLEAR **/
  static CLEAR_DATA_EDIT_USER  = 'CLEAR_DATA_EDIT_USER'; 
  static CLEAR_ERROR_EDIT_USER = 'CLEAR_ERROR_EDIT_USER';

  /** POST EDIT_USER **/
  static EDIT_USER_FAILED      = 'EDIT_USER_FAILED';
  static EDIT_USER_SUCCESS     = 'EDIT_USER_SUCCESS';

  /** UPDATE STATE **/
  static SET_EDIT_USER_STATE   = 'SET_EDIT_USER_STATE';
}
