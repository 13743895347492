import { strings } from '../../resources/locales/i18n.js';
import { getMomentDateFormat } from '../../utils/Utils'


export default class NotificationsManager {

  static getNotificationType = (data) => {
    let type = ''
    if (data && data.type) {
      if (data.type === 'document') {
        type = strings('notificationsView.type.document')
      }
      if (data.type === 'email') {
        type = strings('notificationsView.type.email')
      }
    }
    return type;
  }

  static getNotificationLastNoticeDate = (data) => {
    const formattedDate = data && data.lastNoticeDate ? getMomentDateFormat(data.lastNoticeDate, 'LLLL') : ''
    return formattedDate
  }
  static getNotificationDocumentDate = (data) => {
    const formattedDate = data && data.documentDate ? getMomentDateFormat(data.documentDate, 'L') : ''
    return formattedDate
  }

  static getNotificationUnread = (data) => {
    return data && data.unread
  }
  static getNotificationId = (data) => {
    return data && data.type
  }
}

