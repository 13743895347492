import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import {
  apiDeleteNotification,
  apiGetNotificationsInfo,
  clearNotificationsInfo,
  apiPutNotificationsInfo
} from './NotificationsActions.js';

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';


// Resources
import { getMomentDateFormat } from '../../utils/Utils.js'
import NotificationsManager from './NotificationsManager'
import Routing from '../routing/Routing'
import { strings } from '../../resources/locales/i18n.js';

class NotificationsContainer extends Component {
  state = {
    limit: 15
  }

  componentDidMount() {
    // this._clearNotificationsInfo();
    // this._apiPutNotificationsInfo();
    this._apiGetNotificationsInfo()
  }

  componentWillUnmount() {
    // this._apiPutNotificationsInfo();
    // this._clearNotificationsInfo();
  }

  render() {
    const {
      isLoadingNotificationsInfo,
      newNotifications
    } = this.props;

    // const newNotifications = 8;

    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.notifications'), path: '#' },
    ];
    const title = strings('viewName.notifications');
    return (
      <>
        <HeaderContainer title={title} breadcrumbList={breadcrumbList} />
        <div className="container wrapper wrapper--content d-flex flex-column justify-content-center pb-5 mt-4" style={{ maxWidth: 700 }}>
          {this.renderNotificationsList()}
          {newNotifications > this.state.limit ?
            this.renderShowMoreButton()
            : <div />}
        </div >

      </>
    )

  };

  renderDocumentNotification(item, index) {
    const formParams = {
      dateEnd: item.documentDate,
      dateStart: item.documentDate,
      documentMaxQuantity: "",
      documentMinQuantity: "",
      documentQuantityType: "ALL_AMOUNTS",
      documentSearchType: "invoice",
      documentType: "invoice",
      idReference: ""
    }
    const lastNoticeDate = NotificationsManager.getNotificationLastNoticeDate(item)
    const documentDate = NotificationsManager.getNotificationDocumentDate(item)

    return (
      // <tr key={item.id}>
      //   <th className="align-middle" style={{ textTransform: 'none' }}>{lastNoticeDate}</th>
      //   <td className="align-middle text-center">{type}</td>
      //   <td className="align-middle d-flex justify-content-center">
      //     <button className='btn btn-action' onClick={() => this._toggleOrgModal('org.id_sap')}>
      //       <span className="material-icons">delete</span>
      //     </button>
      //   </td>
      // </tr>

      // <div key={index} className="my-1" role="document" style={item.unread ? { opacity: 1 } : { opacity: .4 }}>
      //   <div className="modal-content">
      //     <div className="modal-header d-flex justify-content-start align-items-start">
      //       <span className="material-icons icon-notifications"> circle_notifications </span>
      //       <div className="ml-3 d-flex flex-column justify-content-end">
      //         <h5 className="">{strings('notificationsView.newDocument')}</h5>
      //         <small>{formattedDate}</small>
      //       </div>
      //       <button type="button" className="align-self-center ml-auto dropdown" onClick={() => this._toggleDropdown(item.id)}>
      //         <span class="material-icons"> more_vert </span>
      //         <div id={'dropdown-' + item.id} class="dropdown-content">
      //           <div>
      //             <Link to={{ pathname: Routing.documents, query: formParams }} className='btn btn-delete'>Ir a facturas</Link>
      //           </div>
      //           <div>
      //             <button onClick={(e) => this._apiPutNotificationsInfo(e, item.id)} className='btn btn-delete'>Marcar como {item.unread ? 'no' : ''} leída</button>
      //           </div>
      //           <div>
      //             <button onClick={() => this._apiDeleteNotification(item.id)} className='btn btn-delete'>Eliminar</button>
      //           </div>
      //         </div>
      //       </button>
      //     </div>
      <div key={index} className="mb-1 " role="document">
        <div className="modal-content " style={item.unread ? { backgroundColor: 'white' } : { backgroundColor: '#f1f1f1' }}>
          <div className="modal-header d-flex justify-content-start align-items-start py-2">

            <span style={item.unread ? { opacity: 1 } : { opacity: .4 }} className="material-icons icon-notifications"> circle_notifications </span>
            <div className="ml-3 d-flex flex-column justify-content-end">
              <p className="m-0">{strings('notificationsView.newDocument')} {documentDate}</p>
              <small>{lastNoticeDate}</small>
            </div>
            <div className='d-flex flex-column flex-sm-row ml-auto'>

              <Link to={{ pathname: Routing.documents, query: formParams }} className='btn btn-actions'>
                <div className="tool bottom">
                  <span class="material-icons-outlined opacity-hover"> file_present </span>
                  <small className='tiptext'> Ir a facturas </small>
                </div>
              </Link>

              <button style={{ boxShadow: 'none' }} onClick={(e) => this._apiPutNotificationsInfo(e, item.id)} className='btn btn-actions tool bottom'>
                {item.isLoadingPut ? <div style={{ marginTop: -8 }}><Loading color={'#006138'} height={20} width={20} /></div> :
                  <div className="tool bottom">
                    <span className="material-icons opacity-hover">{item.unread ? 'email' : 'drafts'}</span>
                    <small className='tiptext'> Marcar como {item.unread ? '' : 'no'} leída</small>
                  </div>
                }
              </button>

              <button style={{ boxShadow: 'none' }} onClick={() => this._apiDeleteNotification(item.id)} className='btn btn-actions tool bottom'>
                {item.isLoadingDelete ? <div style={{ marginTop: -8 }}><Loading color={'#006138'} height={20} width={20} /></div> :
                  <div className="tool bottom">
                    <span className="material-icons opacity-hover">delete</span>
                    <small className='tiptext'> Eliminar </small>
                  </div>
                }
              </button>

            </div>
          </div>
          {/* <div className="modal-body d-flex justify-content-start align-items-start py-2">
            <span className="material-icons icon-thanks invisible"> circle_notifications </span>
            <Link to={{ pathname: Routing.documents, query: formParams }} className="btn btn-delete" style={{ maxWidth: 200 }}> Ir a facturas </Link>
            <button id="addButtonTarjeta" className="btn btn-delete" style={{ cursor: 'pointer', textDecoration: 'none', boxShadow: 'none' }} onClick={(e) => this._apiPutNotificationsInfo(e, item.id)}>Marcar como {item.unread ? 'no' : ''} leída</button>
          </div> */}
        </div>
      </div >
    )
  }

  renderEmailNotification(item) {
    return (
      <div className="" role="document" style={{ opacity: 1 }}>
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-start align-items-center">
            <span className="material-icons icon-thanks">email</span>
            <div className="ml-3 d-flex flex-column">
              <span className="title-big">{strings('notificationsView.newNotifications')}</span>
              <small>jueves, 15 de abril de 2021 14:45</small>
            </div>
            <Link to={Routing.createOrg} id="addButtonTarjeta" className="link-text d-flex align-items-center ml-auto" style={{ cursor: 'pointer', textDecoration: 'none' }}><span className="material-icons mr-1">check</span>Marcar como leída</Link>
          </div>
          <div className="modal-body d-flex justify-content-start">
            <span className="material-icons icon-thanks invisible">
              circle_notifications
                      </span>
            <div className="ml-3 d-flex flex-column">
              <h5 className="mt-3">Tienes nuevos mensajes en la factura nº 0420002790 </h5>
              <Link to={{ pathname: Routing.backOffice }} className="btn btn-primary my-4" style={{ maxWidth: 200 }}>
                Ir a facturas
                      </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNotificationsList() {
    const {
      isLoadingNotificationsInfo,
      newNotifications,
      notificationsData
    } = this.props;

    // const newNotifications = 8;
    // const notificationsData = [
    //   {
    //     "documentDate": '2021-04-23',
    //     "lastNoticeDate": "2021-04-15 12:02:38",
    //     "type": "document",
    //     "unread": true,
    //   },
    //   {
    //     "documentDate": '2021-04-23',
    //     "lastNoticeDate": "2021-04-15 12:02:38",
    //     "type": "document",
    //     "unread": true,
    //   },
    //   {
    //     "documentDate": '2021-04-23',
    //     "lastNoticeDate": "2021-04-15 12:02:38",
    //     "type": "document",
    //     "unread": true,
    //   },
    //   {
    //     "documentDate": '2021-04-23',
    //     "lastNoticeDate": "2021-04-15 12:02:38",
    //     "type": "document",
    //     "unread": true,
    //   },
    //   {
    //     "documentDate": '2021-04-23',
    //     "lastNoticeDate": "2021-04-15 12:02:38",
    //     "type": "document",
    //     "unread": true,
    //   },
    //   {
    //     "documentDate": '2021-04-23',
    //     "lastNoticeDate": "2021-04-15 12:02:38",
    //     "type": "document",
    //     "unread": true,
    //   },
    // ]

    return (
      <>
        {notificationsData.map((item, idx) => {
          if (idx > this.state.limit - 1) {
            return;
          }
          return (
            <div>
              {
                item.type === 'message' ?
                  this.renderEmailNotification(item, idx)
                  : this.renderDocumentNotification(item, idx)
              }
            </div>
          )
        })}
      </>
    )
  }

  renderShowMoreButton() {
    return (
      <button className='btn btn-primary my-4' onClick={(e) => this.setState({ limit: this.state.limit + this.state.limit })}>{strings('notificationsView.showMore')}</button>
    )
  }

  _apiDeleteNotification(id) {
    this.props.apiDeleteNotification(id, () => this._apiGetNotificationsInfo())
  }

  _apiGetNotificationsInfo(e) {
    // e.preventDefault();
    this.props.apiGetNotificationsInfo();
  }

  _apiPutNotificationsInfo(e, id) {
    // e.preventDefault();
    this.props.apiPutNotificationsInfo(id, () => this._apiGetNotificationsInfo());
  }

  _clearNotificationsInfo() {
    this.props.clearNotificationsInfo();
  }

}

const mapStateToProps = ({ NotificationsReducer }) => {
  const { isLoadingNotificationsInfo, newNotifications, notificationsData } = NotificationsReducer;
  return {
    isLoadingNotificationsInfo, newNotifications, notificationsData
  }
}


const mapStateToPropsAction = {
  apiDeleteNotification,
  apiGetNotificationsInfo,
  apiPutNotificationsInfo,
  clearNotificationsInfo,
};

export default connect(mapStateToProps, mapStateToPropsAction)(NotificationsContainer);