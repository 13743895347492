import React from 'react';
import PropTypes from 'prop-types';

// Resources
import {strings} from '../../../resources/locales/i18n';
import PaperTicketManager from '../../paperTicket/PaperTicketManager';
import CardsManager from '../../cards/CardsManager';


const PaperTicketItem = (props) => {
  const { items, itemCount, grandTotal, exchangeAmount, paymentMethods, subtotal, discountAmount, giftCard, shippingAmount,shippingDescription } = props;

  let toReturn = parseFloat(grandTotal.replace(',', '.')) + parseFloat(exchangeAmount.replace(',', '.'));
  toReturn = toReturn.toFixed(2).replace('.', ',');



  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>

      {/* HEADER */}
      <div className={'d-flex justify-content-center align-items-center w-100 borderBottom mt-3'}>
        <div className={'d-flex align-items-start w-100'}>
          <p className={'textPaperTicketTitle'} >{strings('paperTicket.article').toUpperCase()}</p>
        </div>

        <div className={'d-flex justify-content-end w-100'}>
          <p className={'textPaperTicket'}>{strings('paperTicket.amount').toUpperCase()}</p>
        </div>
      </div>

      {/* ITEMS */}
      {
        items && items.length > 0 ? items.map((item, index) => { return(renderItemsShop(item, index))}) : <div/>
      }


      {/* TOTAL ITEMS */}
      <div className={'d-flex justify-content-center align-items-center w-100 borderBottom mt-3'}>
        <div className={'d-flex align-items-start w-100'}>
          <p className={"textPaperTicketTitle"}>{strings('paperTicket.totalQty')} {itemCount}</p>
        </div>
      </div>

      {/* SUBTOTAL */}
      {discountAmount.substring(0, 4) === "0,00" || discountAmount === ''  ? <div/>
        : <div className={"d-flex flex-column w-100"}>
            <div className={"d-flex justify-content-end w-100 flex-row"}>
              <div style={{width: '75%'}}>
                <p className={"textPaperTicket text-right"}>{strings('paperTicket.subtotal').toUpperCase()}</p>
              </div>

              <div style={{width: '25%'}}>
                <p className={"textPaperTicket text-right"}>{subtotal}</p>
              </div>
            </div>


          {/* DISCOUNT */}
          <div className={"d-flex justify-content-end w-100 flex-row"}>
            <div style={{width: '75%'}}>
              <p className={"textPaperTicket text-right"}>
                {strings('paperTicket.discount').toUpperCase()}
              </p>
            </div>

            <div style={{width: '25%'}}>
              <p className={"textPaperTicket text-right"}>
                - {discountAmount}
              </p>
            </div>
          </div>

        </div>
      }

      {/* SHIPPING AMOUNT */}
      {shippingDescription ? <div className={"d-flex justify-content-end w-100 flex-row"}>
        <div style={{width: '75%'}}>
          <p className={"textPaperTicket text-right"}>{shippingDescription.toUpperCase()}:</p>
        </div>

        <div style={{width: '25%'}}>
          <p className={"textPaperTicket text-right"}>{shippingAmount}</p>
        </div>
      </div> : <div/>}



      {/* TOTAL PRICE */}
      <div className={"d-flex flex-column w-100 mt-4"}>
        <div className={"d-flex justify-content-end w-100 flex-row"}>
          <div style={{width: '75%'}}>
            <h4 className={"textPaperTicket text-right"}>
              {strings('paperTicket.grandTotal').toUpperCase()}
            </h4>
          </div>

          <div style={{width: '25%'}}>
            <h4 className={"textPaperTicket text-right"}>
              {grandTotal}
            </h4>
          </div>
        </div>

        {parseFloat(exchangeAmount) !== 0 ?

          <div>
            <div>
              <p className={"textPaperTicket"}>
                {strings('paperTicket.totalDelivered').toUpperCase()}
              </p>
            </div>

          <div>
            <p className={"textPaperTicket"}>
              {toReturn}
            </p>
          </div>
        </div>
          :
        <div/>}

        {parseFloat(exchangeAmount) !== 0 ?

          <div>
            <div>
              <p className={"textPaperTicket"}>
                {strings('paperTicket.toReturn').toUpperCase()}
              </p>
            </div>

            <div>
              <p className={"textPaperTicket"}>
                {exchangeAmount}
              </p>
            </div>
          </div>
          :
          <div/>
        }

      </div>

      <div className={'d-flex justify-content-center align-items-center w-100 borderBottom mt-3'}>
        <div className={'d-flex align-items-start w-100'}>
          <p className={"textPaperTicketTitle"}>{strings('paperTicket.detailsPayment')}</p>
        </div>
      </div>

      {/* ITEMS */}
      {paymentMethods && paymentMethods.length !== 0 && paymentMethods.map((item, index) => { return(renderItemsPayment(item, index))})}
    </div>
  )
};

const renderItemsGiftCard = (item) =>{
  if (!item){
    return <div/>
  }

  const balance = PaperTicketManager.getBalance(item);
  const numberCard = PaperTicketManager.getNumberCard(item);


  return(
    <div className={'d-flex flex-column justify-content-start align-items-start w-100'} id={numberCard}>
      <p className={'textPaperTicket'}>{`${strings('paperTicket.numberCard')} ${numberCard}`}</p>
      <p className={'textPaperTicket'}>{`${strings("paperTicket.balance")} ${balance}`}</p>
    </div>
  )

}

const renderItemsShop = (item, index) => {
  const name     = PaperTicketManager.getItemName(item);
  const qty      = PaperTicketManager.getItemQty(item);
  const price    = PaperTicketManager.getItemPrice(item);
  const rowTotal = PaperTicketManager.getItemRowTotal(item);

  return(
  <div className={"d-flex w-100 my-0 py-0"} id={index}>
    <div className={"d-flex"} style={{width: '85%'}}>
      <p className={"textPaperTicket mb-0"}>{name}&nbsp;</p>
      {parseFloat(qty) !== 1 ?
        <p className={"textPaperTicket"}>{qty} {strings('paperTicket.x')} {price}</p>
        :
        <div/>
      }
    </div>

    <div className={"d-flex justify-content-end"} id={index} style={{width: '15%'}}>
      <p className={"textPaperTicket"}>{rowTotal}</p>
    </div>
  </div>
  )
};

const renderItemsPayment  = (item, index) => {
  const description = PaperTicketManager.getItemDescription(item);
  const amount      = PaperTicketManager.getItemAmount(item);

  return(
    <div className={"d-flex flex-column w-100"} id={index}>
      <div className={"d-flex justify-content-end w-100 flex-row"}>
        <div style={{width: '75%'}}>
          <p className={"textPaperTicket text-right"}>{description}</p>
        </div>

        <div style={{width: '25%'}}>
          <p className={"textPaperTicket text-right"}>{amount}</p>
        </div>
      </div>
      {item.giftCardDetailsObject ? renderItemsGiftCard(item.giftCardDetailsObject) : <div/> }
    </div>
  )
};

PaperTicketItem.propTypes = {
  address:     PropTypes.string,
  business:    PropTypes.string,
  cif:         PropTypes.string,
  image:       PropTypes.string,
  phone:       PropTypes.string,
};

export default PaperTicketItem
