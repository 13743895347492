import Types from "./Types";

const INITIAL_STATE = {
  backOfficeOrgData: null,
  backOfficeRequestsData: null,
  backOfficeUsersData: null,
  isLoadingBackOfficeDeleteOrg: false,
  isLoadingBackOfficeDeleteUser: false,
  isLoadingBackOfficeOrgInfo: false,
  isLoadingBackOfficeRequestsInfo: false,
  isLoadingBackOfficeUsersInfo: false,
  isLoadingGetOrgDownload: false,
  isLoadingGetRequestsDownload: false,
  isLoadingGetUsersDownload: false,
  orgCurrentPage: 1,
  orgLimit: 200,
  orgPagesNum: '',
  orgTotalResults: null,
  requestMessage: [],
  requestsCurrentPage: 1,
  requestsLimit: 25,
  requestsPagesNum: '',
  usersCurrentPage: 1,
  usersLimit: 25,
  usersPagesNum: '',
  usersTotalResults: null
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR BACK_OFFICE INFO **/
    case Types.CLEAR_BACK_OFFICE_INFO:
      return { ...INITIAL_STATE, requestMessage: [] };

    /** DELETE BACK_OFFICE REQUEST MESSAGE **/
    case Types.DELETE_BACK_OFFICE_REQUEST_MESSAGE:
      return { ...state, requestMessage: [] };

    /** DELETE BACK_OFFICE USER **/
    case Types.DELETE_BACK_OFFICE_USER_FAILED:
      return { ...state, requestMessage: action.payload };

    case Types.DELETE_BACK_OFFICE_USER_SUCCESS:
      return { ...state, requestMessage: action.payload };

    /** DELETE BACK_OFFICE ORG **/
    case Types.DELETE_BACK_OFFICE_ORG_FAILED:
      return { ...state, requestMessage: action.payload };

    case Types.DELETE_BACK_OFFICE_ORG_SUCCESS:
      return { ...state, requestMessage: action.payload };

    /** GET BACK_OFFICE ORG DOWNLOAD**/
    case Types.GET_BACK_OFFICE_ORG_DOWNLOAD_FAILED:
      return { ...state };

    /** GET BACK_OFFICE REQUESTS DOWNLOAD**/
    case Types.GET_BACK_OFFICE_REQUESTS_DOWNLOAD_FAILED:
      return { ...state };

    /** GET BACK_OFFICE USERS DOWNLOAD**/
    case Types.GET_BACK_OFFICE_USERS_DOWNLOAD_FAILED:
      return { ...state };

    /** GET BACK_OFFICE ORG INFO**/
    case Types.GET_BACK_OFFICE_ORG_INFO_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_BACK_OFFICE_ORG_INFO_SUCCESS:
      return {
        ...state,
        backOfficeOrgData: action.payload.orgs,
        orgPagesNum: action.payload.numPages,
        orgTotalResults: action.payload.totalResults,
      };

    /** GET BACK_OFFICE REQUESTS INFO**/
    case Types.GET_BACK_OFFICE_REQUESTS_INFO_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_BACK_OFFICE_REQUESTS_INFO_SUCCESS:
      return {
        ...state,
        backOfficeRequestsData: action.payload.orderList,
        requestsPagesNum: action.payload.numPages,
        requestsTotalResults: action.payload.totalResults,
      };

    /** GET BACK_OFFICE USERS INFO**/
    case Types.GET_BACK_OFFICE_USERS_INFO_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_BACK_OFFICE_USERS_INFO_SUCCESS:
      const dataUsers = action.payload.Users;
      const dataUsersLength = dataUsers.length;

      return {
        ...state,
        backOfficeUsersData: dataUsers,
        usersPagesNum: action.payload.numPages,
        usersTotalResults: action.payload.totalResults,
      };

    /** UPDATE STATE **/
    case Types.SET_DATA_BACK_OFFICE_INFO:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
