import React, { Component } from 'react';

const PaginatorContainer = (props) => {
  const { changeCurrentPage, currentPage, id, apiGetData, pagesNum, setDataProps } = props;
  // const currentPageP = 1;
  const currentIsFirstPage = currentPage === 1;
  const currentIsLastPage = currentPage === pagesNum;

  const _apiGetData = () => apiGetData ?  apiGetData() : null

  if (!pagesNum || pagesNum === 1) {
    return <div />
  }

  return (
    <nav className="d-flex justify-content-center py-3 align-items-center col-4">
      <ul className="pagination m-0">
        <li className="page-item disabled" style={currentIsFirstPage ? { visibility: 'hidden' } : {}} onClick={(e) => { changeCurrentPage(setDataProps, (currentPage - 1), id); _apiGetData() }}>
          <a className="page-link" tabIndex="-1" aria-disabled="true">
            <span className="material-icons">
              navigate_before
                  </span>
          </a>
        </li>
        {currentIsFirstPage ?
          <>
            <li className="page-item active"><a className="page-link" >{currentPage}</a></li>
            <li onClick={(e) => { changeCurrentPage(setDataProps, (currentPage + 1), id); _apiGetData() }} className="page-item"><a className="page-link" >{currentPage + 1}</a></li>
            {
              pagesNum >= 3 ?
                <li onClick={(e) => { changeCurrentPage(setDataProps, (currentPage + 2), id); _apiGetData() }} className="page-item"><a className="page-link" >{currentPage + 2}</a></li>
                : <div />
            }
          </>
          : <span />
        }
        {currentIsLastPage ?
          <>
            {
              currentPage - 2 <= 0 ?
              <div />
              : <li onClick={(e) => { changeCurrentPage(setDataProps, (currentPage - 2), id); _apiGetData() }} className="page-item"><a className="page-link" >{currentPage - 2}</a></li>
            }
            <li onClick={(e) => { changeCurrentPage(setDataProps, (currentPage - 1), id); _apiGetData() }} className="page-item"><a className="page-link" >{currentPage - 1}</a></li>
            <li className="page-item active"><a className="page-link" >{currentPage}</a></li>
          </>
          : <span />
        }
        {!currentIsFirstPage && !currentIsLastPage ?
          <>
            <li onClick={(e) => { changeCurrentPage(setDataProps, (currentPage - 1), id); _apiGetData() }} className="page-item"><a className="page-link" >{currentPage - 1}</a></li>
            <li className="page-item active"><a className="page-link" >{currentPage}</a></li>
            <li onClick={(e) => { changeCurrentPage(setDataProps, (currentPage + 1), id); _apiGetData() }} className="page-item"><a className="page-link" >{currentPage + 1}</a></li>
          </>
          :
          <span />
        }
        <li className="page-item" style={currentIsLastPage ? { visibility: 'hidden' } : {}} onClick={(e) => { changeCurrentPage(setDataProps, (currentPage + 1), id); _apiGetData() }}>
          <a className="page-link" >
            <span className="material-icons">
              navigate_next
                  </span>
          </a>
        </li>
      </ul>
    </nav>
  )
};



export { PaginatorContainer }


