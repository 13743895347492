// Resources
import { strings } from '../../resources/locales/i18n';

// Utils
import {
  isChecked,
  isValidCardCVV,
  isValidCardExpirationDate,
  isValidCardNumber, isValidDni,
  isValidCifOrDni,
  isValidCode,
  isValidCompany,
  isValidConsult,
  isValidEmail,
  isValidIdCard,
  isValidIdSap,
  isValidName,
  isValidObject,
  isValidPassword,
  isValidPasswordCoincidence,
  isValidPhone,
  isValidPostalCode,
  isValidSurnames,
  isValidString
} from './FormValidation';

export default class FormValidationManager {

  static formAdminCreateOrg = (props) => {
    const { idSap } = props;
    const error = [];

    if (!isValidIdSap(idSap)) {
      error.push({ key: 'idSap', value: strings('createOrgView.error.invalidIdSap') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formAdminCreateUser = (props) => {
    const { email, phone, username } = props;
    const error = [];

    if (!isValidName(username.trim())) {
      error.push({ key: 'username', value: strings('form.error.invalidName') });
    }

    if (!isValidEmail(email.trim())) {
      error.push({ key: 'email', value: strings('login.error.invalidEmailFormat') });
    }

    if (!isValidPhone(phone)) {
      error.push({ key: 'phone', value: strings('costumerSupportView.error.invalidPhone') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formAdminSignup = (props) => {
    const { username, password, phone } = props;
    const error = [];

    if (!isValidEmail(username.trim())) {
      error.push({ key: 'username', value: strings('login.error.invalidEmailFormat') });
    }

    if (!isValidPassword(password)) {
      error.push({ key: 'password', value: strings('login.error.invalidPasswordLength') });
    }

    if (!isValidPhone(phone)) {
      error.push({ key: 'phone', value: strings('costumerSupportView.error.invalidPhone') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formConfirmationCode = (props) => {
    const { confirmationCode } = props;
    const error = [];
    if( !confirmationCode || !isValidCode(confirmationCode) ){
      error.push({ key: 'confirmationCode', value: strings('login.error.invalidCode') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formContactUs= (props) => {
    const { cif, company, consult, email, username, surnames, telephone } = props;
    const error = [];

    if (!isValidName(username)) {
      error.push({ key: 'username', value: strings('costumerSupportView.error.invalidUsername') });
    }
    if (!isValidSurnames(surnames)) {
      error.push({ key: 'surnames', value: strings('costumerSupportView.error.invalidSurnames') });
    }
    if (!isValidCompany(company)) {
      error.push({ key: 'company', value: strings('costumerSupportView.error.invalidCompany') });
    }
    if (!isValidPhone(telephone)) {
      error.push({ key: 'telephone', value: strings('costumerSupportView.error.invalidPhone') });
    }
    if (!isValidEmail(email.trim())) {
      error.push({ key: 'email', value: strings('costumerSupportView.error.invalidEmailFormat') });
    }
    if (!isValidCifOrDni(cif)) {
      error.push({ key: 'cif', value: strings('costumerSupportView.error.invalidCif') });
    }
    if (!isValidConsult(consult.trim())) {
      error.push({ key: 'consult', value: strings('costumerSupportView.error.invalidConsult') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formCostumerSupport = (props) => {
    const { company, consult, email, username, surnames, telephone } = props;
    const error = [];

    if (!isValidName(username)) {
      error.push({ key: 'username', value: strings('costumerSupportView.error.invalidUsername') });
    }
    if (!isValidSurnames(surnames)) {
      error.push({ key: 'surnames', value: strings('costumerSupportView.error.invalidSurnames') });
    }
    if (!isValidCompany(company)) {
      error.push({ key: 'company', value: strings('costumerSupportView.error.invalidCompany') });
    }
    if (!isValidPhone(telephone)) {
      error.push({ key: 'telephone', value: strings('costumerSupportView.error.invalidPhone') });
    }
    if (!isValidEmail(email.trim())) {
      error.push({ key: 'email', value: strings('costumerSupportView.error.invalidEmailFormat') });
    }
    if (!isValidConsult(consult.trim())) {
      error.push({ key: 'consult', value: strings('costumerSupportView.error.invalidConsult') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formEditPassword = (props) => {
    const { currentPassword, newPassword } = props;
    const error = [];

    if (!isValidPassword(currentPassword)) {
      error.push({ key: 'currentPassword', value: strings('login.error.invalidPasswordLength') });
    }

    if (!isValidPassword(newPassword)) {
      error.push({ key: 'newPassword', value: strings('login.error.invalidPasswordLength') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formEditUser = (props) => {
    const { email, phone, username } = props;
    const error = [];

    if (!isValidEmail(email.trim())) {
      error.push({ key: 'email', value: strings('login.error.invalidEmailFormat') });
    }

    if (!isValidName(username.trim())) {
      error.push({ key: 'username', value: strings('form.error.invalidName') });
    }

    if (!isValidPhone(phone)) {
      error.push({ key: 'phone', value: strings('costumerSupportView.error.invalidPhone') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formLogin = (props) => {
    const { username, password } = props;
    const error = [];

    if (!isValidEmail(username.trim())) {
      error.push({ key: 'username', value: strings('login.error.invalidEmailFormat') });
    }

    if (!isValidPassword(password)) {
      error.push({ key: 'password', value: strings('login.error.invalidPasswordLength') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formLoginCode = (props) => {
    const { code } = props;
    const error = [];
    if( !code || !isValidCode(code) ){
      error.push({ key: 'code', value: strings('login.error.invalidCode') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formNewPassword = (props) => {
    const { newPassword } = props;
    const error = [];
    if( !newPassword || !isValidPassword(newPassword) ){
      error.push({ key: 'newPassword', value: strings('login.error.invalidPasswordLength') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formRechargeCards = (props) => {
    const { index, inputCardIdToVerify } = props;
    const error = [];

    if (!isValidIdCard(inputCardIdToVerify)) {
      error.push({ key: `cardId-${index}`, value: strings('rechargeCardsView.invalidCardId') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formRememberPasswordUsername = (props) => {
    const { username } = props;
    const error = [];

    if (!isValidEmail(username.trim())) {
      error.push({ key: 'username', value: strings('login.error.invalidEmailFormat') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formRememberPasswordValidationCode = (props) => {
    const { code, password, repeatPassword } = props;
    const error = [];

    if (!isValidCode(code)) {
      error.push({ key: 'code', value: strings('rememberPasswordView.errorCode') });
    }

    if (!isValidPassword(password)) {
      error.push({ key: 'password', value: strings('login.error.invalidPasswordLength') });
    }

    if (!isValidPasswordCoincidence(password, repeatPassword)) {
      error.push({ key: 'repeatPassword', value: strings('form.error.invalidPasswordCoincidence') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };
}
