import { strings } from '../../resources/locales/i18n.js';

export const CardUsesStatus = {
  CANCELED: "CAN",
  ENABLE: "ACT",
  REDEEMED: "RED",
  PENDING: "PDT"
}

export const CardBillingStatus = {
  BALANCE: "RC",
  SPENDING: "RP"
}

export default class CardsManager {

  static getBalance = (data) => {
    const balance = CardsManager.getBalanceInitial(data) - CardsManager.getTotalExpense(data);
    return (Math.round(balance * 100) / 100).toFixed(2)
  };

  static getBalanceInitial = (data) => {
    return data && data.tal_importe ?
      (Math.round(data.tal_importe * 100) / 100).toFixed(2) : 0;
  };

  static getCenterId = (data) => {
    return data && data.talh_tienda_id ?
      data.talh_tienda_id : '';
  };

  static getCenterName = (data) => {
    return data && data.nombre ?
      data.nombre : '';
  };

  static getInitDate = (data) => {
    return data && data.tal_fec_vigor_ini ?
      data.tal_fec_vigor_ini : '';
  };

  static getStatus = (data) => {
    let status = '';
    if (data && data.tal_estado) {
      if (data.tal_estado === CardUsesStatus.CANCELED) {
        status = strings('formFilters.usesStatus.canceled')
      }
      if (data.tal_estado === CardUsesStatus.ENABLE) {
        status = strings('formFilters.usesStatus.enabled')
      }
      if (data.tal_estado === CardUsesStatus.REDEEMED) {
        status = strings('formFilters.usesStatus.redeemed')
      }
      if (data.tal_estado === CardUsesStatus.PENDING) {
        status = strings('formFilters.usesStatus.pending')
      }
    }
    return data && data.tal_estado && status ? status : ''
  }

  static getIsOnline = (data) => {
    return data && data.isOnline ? data.isOnline : false
  }


  static getTransactionDate = (data) => {
    return data && data.talh_fec_trans ?
      data.talh_fec_trans : '';
  };

  static getTicketId = (data) => {
    return data && data.ticketczz ? data.ticketczz.replace('/','') : ''
  }

  static getTotalExpense = (data) => {
    return data && data.tal_importe_red ?
      (Math.round(data.tal_importe_red * 100) / 100).toFixed(2) : 0;
  };

  static getTotalTicketAmount = (data) => {
    return data && data.talh_total_ticket ?
      (Math.round(data.talh_total_ticket * 100) / 100).toFixed(2) : 0;
  };

  static getUuid = (data) => {
    return data && data.tal_ean_id ?
      data.tal_ean_id.slice(-13) : '';
  };

}
