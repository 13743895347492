import Types from "./Types";
import FormValidationManager from '../../../utils/managers/FormValidationManager.js';
import { isDev } from '../../../utils/Utils.js';
import { Auth } from 'aws-amplify';
import { responseSuccess } from '../login/LoginActions'

// Services

export const apiCompleteNewPassword = (redirectSuccess, openCodeModal) => async (dispatch, getState) => {
  const { cognitoUser } = getState().LoginReducer;
  const { newPassword } = getState().NewPasswordReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formNewPassword({ newPassword });
  if (error) {
    return dispatch({
      type: Types.NEW_PASSWORD_FAILED,
      payload: error
    });
  }
  dispatch(setNewPasswordState({ prop: 'error', value: [] }));

  // -- 2. Request
  dispatch(setNewPasswordState({ prop: 'isLoadingNewPassword', value: true }));
  await Auth.completeNewPassword(
    cognitoUser,               // the Cognito User Object
    newPassword,       // the new password
  ).then(user => {
    if (isDev()) console.log(user)
    dispatch(setNewPasswordState({ prop: 'isFirstTime', value: true }));
    if (user.challengeName === "SMS_MFA") {
      if (isDev()) console.log('correct credentials, code sent');
      return openCodeModal();
    }
    dispatch(responseSuccess(user, redirectSuccess));
    // at this time the user is logged in if no MFA required
  }).catch(e => {
    if (isDev()) console.log(e)
    dispatch(responseError(e));
  });
  dispatch(setNewPasswordState({ prop: 'isLoadingNewPassword', value: false }));
}


export const clearDataNewPassword = () => ({
  type: Types.CLEAR_DATA_NEW_PASSWORD,
});

// export const responseSuccess = (response, openSuccessModal) => async (dispatch) => {
//   dispatch(clearDataNewPassword());
//   openSuccessModal();
// }
export const responseError = (error) => async (dispatch) => {
  if (isDev()) console.log(error)
  if (error && error.message) {
    let value = error.message;
    dispatch(setNewPasswordState({
      prop: 'error',
      value: [{
        key: "request",
        value: value
      }]
    }));
  }
};

export const setNewPasswordState = ({ prop, value }) => ({
  type: Types.SET_NEW_PASSWORD_STATE,
  payload: { prop, value }
});
export const validateOnBlurNewPassword = (inputType) => async (dispatch, getState) => {
  const { newPassword, error } = getState().NewPasswordReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formNewPassword({ newPassword, inputType });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.NEW_PASSWORD_FAILED,
    payload: error.slice()
  });
}
