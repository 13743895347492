import Types from "./Types";
import FormValidationManager from '../../utils/managers/FormValidationManager.js';
import { isDev } from '../../utils/Utils.js';
import { getOrg, postCreateOrg } from "../../services/api/API";
import { strings } from '../../resources/locales/i18n'


// Services

export const apiGetOrg = (activeCreateMode) => async (dispatch, getState) => {
  const { idSap } = getState().CreateOrgReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formAdminCreateOrg({ idSap });
  if (error) {
    // Scroll to and focus first error input
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' })
      firstError.focus({ preventScroll: true })
    }
    return dispatch({
      type: Types.CREATE_ORG_FAILED,
      payload: error
    });
  }
  dispatch(setCreateOrgState({ prop: 'error', value: [] }));

  // -- 2. Request
  dispatch(setCreateOrgState({ prop: 'isLoadingCreateOrg', value: true }));
  await dispatch(getOrg(
    (tag, response) => {
      if (isDev()) console.log('apiBackOfficeGetOrg - Error: ', response);
      dispatch({ type: Types.CREATE_ORG_FAILED });
      dispatch(getOrgResponseError(strings('createOrgView.error.couldNoConnect')));
    },
    (tag, response) => {
      if (isDev()) console.log('apiBackOfficeGetOrg - Success: ', response);
      if (response && response.nombre === '') {
        dispatch({ type: Types.GET_ORG_SUCCESS, payload: response });
        return dispatch(getOrgResponseError(strings('createOrgView.error.noExists')));
      }
      dispatch({ type: Types.GET_ORG_SUCCESS, payload: response });
      activeCreateMode();
    },
  ))
  dispatch(setCreateOrgState({ prop: 'isLoadingCreateOrg', value: false }));
};

export const apiPostCreateOrg = (openSuccessModal, closeCreateMode) => async (dispatch, getState) => {
  const { idSap, name } = getState().CreateOrgReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formAdminCreateOrg({ idSap });
  if (error) {
    // Scroll to and focus first error input
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' })
      firstError.focus({ preventScroll: true })
    }
    return dispatch({
      type: Types.CREATE_ORG_FAILED,
      payload: error
    });
  }
  dispatch(setCreateOrgState({ prop: 'error', value: [] }));

  // -- 2. Request
  const newOrgInfo = {
    id_sap: idSap ? idSap : null,
    name: name ? name : null,
  }

  dispatch(setCreateOrgState({ prop: 'isLoadingCreateOrg', value: true }));
  await dispatch(postCreateOrg(
    newOrgInfo,
    (tag, response) => {
      if (isDev()) console.log('apiBackOfficeCreateOrg - Error: ', response);
      dispatch({ type: Types.CREATE_ORG_FAILED });
      dispatch(responseError(response));
    },
    (tag, response) => {
      if (isDev()) console.log('apiBackOfficeCreateOrg - Success: ', response);
      dispatch({ type: Types.CREATE_ORG_SUCCESS });
      dispatch(responseSuccess(response, openSuccessModal));
      closeCreateMode();
    },
  ))
  dispatch(setCreateOrgState({ prop: 'isLoadingCreateOrg', value: false }));
};

export const clearDataCreateOrg = () => ({
  type: Types.CLEAR_DATA_CREATE_ORG,
});

export const responseError = (error) => async (dispatch) => {
  let errorMessage = strings('error.somethingWentWrong');
  if (isDev()) console.log(error)
  if (error && error.message) {
    errorMessage = error.message;
  };
  dispatch(setCreateOrgState({ prop: 'error', value: [{ key: "request", value: errorMessage }] }))
};

export const getOrgResponseError = (errorMessage) => async (dispatch) => {
  if (errorMessage) {
    if (isDev()) console.log(errorMessage)
    dispatch(setCreateOrgState({ prop: 'error', value: [{ key: "request", value: errorMessage }] }));
  }
};

export const responseSuccess = (response, openSuccessModal) => async (dispatch) => {
  dispatch(clearDataCreateOrg());
  openSuccessModal();
}

export const setCreateOrgState = ({ prop, value }) => ({
  type: Types.SET_CREATE_ORG_STATE,
  payload: { prop, value }
});

export const validateOnBlurCreateOrg = (inputType) => async (dispatch, getState) => {
  const { error, idSap } = getState().CreateOrgReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formAdminCreateOrg({ idSap });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.CREATE_ORG_FAILED,
    payload: error.slice()
  });
}
