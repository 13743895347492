import React, { Component } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import Routing from './Routing';
import PrivateRoute from './PrivateRouter';

import UserManager from '../user/UserManager'

// Views
import DrawerContainer                  from 'src/modules/navegation/DrawerContainer.jsx'
import BackOfficeContainer              from "../backOffice/BackOfficeContainer";
import CardContainer                    from 'src/modules/cards/CardsContainer';
import CardDetailContainer              from 'src/modules/cardTransactions/CardTransactionsContainer';
import ConfirmAccountContainer          from '../auth/confirmAccount/ConfirmAccount';
import CostumerSupportContainer         from '../costumerSupport/CostumerSupportContainer';
import CreateOrgContainer               from '../createOrg/CreateOrgContainer';
import CreateUserContainer              from '../createUser/CreateUserContainer';
import DashboardContainer               from '../dashboard/DashboardContainer.jsx';
import DocumentsContainer               from '../documents/DocumentsContainer';
import EditOrgContainer                 from '../editOrg/EditOrgContainer';
import EditUserContainer                from '../editUser/EditUserContainer';
import LoginContainer                   from 'src/modules/auth/login/LoginContainer';
import MaintenancePageContainer         from './MaintenancePageContainer';
import NewPasswordContainer             from 'src/modules/auth/newPassword/NewPasswordContainer'  ;
import NotificationsContainer           from '../notifications/NotificationsContainer';
import RememberPasswordContainer        from 'src/modules/auth/rememberPassword/RememberPasswordContainer';
import PaperTicketContainer             from '../paperTicket/PaperTicketContainer';
import LegalContainer                   from '../legal/LegalContainer' ;
import PolicyContainer                  from '../policy/PolicyContainer' ;
import PurchaseCardsContainer           from '../purchaseCards/PurchaseCardsContainer';
import RechargeCardsContainer           from '../rechargeCards/RechargeCardsContainer';
import ResponsibleConsumptionContainer  from '../responsibleConsumption/ResponsibleConsumption';
import ThanksContainer                  from '../thanks/ThanksContainer';
import ScrollToTop                      from '../../utils/ScrollToTop';
import UserProfileContainer             from '../userProfile/UserProfileContainer';
import RegisterContainer                from '../auth/register/RegisterContainer';
import {isDev}                          from '../../utils/Utils';
import CardsPinContainer from '../cardsPin/CardsPinContainer';
import {TypeUsers} from "../backOffice/BackOfficeManager";
import {apiPostSearch, setCardsDataState} from "../cards/CardsActions";
import {setUserInfo} from "../user/UserAction";

//{isDev() ? <PrivateRoute exact path={Routing.register} redirect={Routing.dashboard} component={RegisterContainer} accessToken={!accessToken}/> :<div/>}
class RouterComponent extends Component {

  componentDidMount() {
    this.props.setUserInfo();
  }

  render() {
    const { accessToken, userInfo } = this.props;
    const isSuperAdmin = UserManager.getUserProfile(userInfo) === TypeUsers.SUPER_ADMIN;
    const maintenance = false;
    return (
      <BrowserRouter>
        {accessToken && accessToken.length && !maintenance ? <Route component={DrawerContainer} /> : <div />}
        <ScrollToTop>
          <Switch>

            // AUTH
            {!maintenance ? <Route exact path={Routing.rememberPassword} component={RememberPasswordContainer} /> : null}
            <PrivateRoute exact path={Routing.login} redirect={Routing.dashboard} component={LoginContainer} accessToken={!accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.confirmation} redirect={Routing.login} component={ConfirmAccountContainer} accessToken={!accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.newPassword} redirect={Routing.login} component={NewPasswordContainer} accessToken={!accessToken} maintenance={maintenance} props={this.props}/>
            // HOME
            <PrivateRoute exact path={Routing.app} redirect={accessToken ? Routing.dashboard : Routing.login} accessToken={false} maintenance={maintenance} props={this.props}/>
            // USER ACTIONS
            <PrivateRoute exact path={Routing.cardDetail} component={CardDetailContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.cardList} component={CardContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.dashboard} component={DashboardContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.digitalTicket} component={PaperTicketContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.documents} component={DocumentsContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.notifications} component={NotificationsContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.purchaseCards} component={PurchaseCardsContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.rechargeCards} component={RechargeCardsContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.responsibleConsumption} component={ResponsibleConsumptionContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.thanks} component={ThanksContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.userProfile} component={UserProfileContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.cardsPin} component={CardsPinContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            // CONTACT
            <PrivateRoute exact path={Routing.contact} component={CostumerSupportContainer} accessToken={!accessToken} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.costumerSupport} component={CostumerSupportContainer} accessToken={accessToken} maintenance={maintenance} props={this.props}/>
            // LEGALS
            {!maintenance ? <Route exact path={Routing.legal} component={LegalContainer} props={this.props}/> : null}
            {!maintenance ? <Route exact path={Routing.policy} component={PolicyContainer} props={this.props}/> : null}

            // BACK OFFICE
            <PrivateRoute exact path={Routing.backOffice} component={BackOfficeContainer} accessToken={accessToken && isSuperAdmin} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.createUser} component={CreateUserContainer} accessToken={accessToken && isSuperAdmin} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.createOrg} component={CreateOrgContainer} accessToken={accessToken && isSuperAdmin} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.editUser} component={EditUserContainer} accessToken={accessToken && isSuperAdmin} maintenance={maintenance} props={this.props}/>
            <PrivateRoute exact path={Routing.editOrg} component={EditOrgContainer} accessToken={accessToken && isSuperAdmin} maintenance={maintenance} props={this.props}/>

            // MAINTENANCE PAGE
            <Route path={Routing.maintenancePage}>
              {maintenance ? <MaintenancePageContainer/> : <Redirect to={Routing.login}/>}
            </Route>

            //PAGE NOT FOUND
            <Route path='*'>
              {maintenance ? <Redirect to={Routing.maintenancePage}/> : <Redirect to={Routing.login}/> }
            </Route>

          </Switch>
        </ScrollToTop>

      </BrowserRouter>
    );
  }

}

const mapStateToProps = ({ UserReducer }) => {
  const { accessToken, userInfo } = UserReducer;
  return {
    accessToken, userInfo
  };
};

const mapStateToPropsAction = {
  setUserInfo
};

export default connect(mapStateToProps, mapStateToPropsAction)(RouterComponent)
