import Types from "./Types";
import FormValidationManager from '../../utils/managers/FormValidationManager.js';
import { isDev } from '../../utils/Utils.js';
import { Auth } from 'aws-amplify';
import { strings } from "../../resources/locales/i18n";
import { postEditUser } from "../../services/api/API";


// Services

export const apiPostEditUser = (openSuccessModal) => async (dispatch, getState) => {
  const { email, phone, username  } = getState().EditUserReducer;

  // --- 1. Validate form
  const error = await FormValidationManager.formEditUser({ email, phone, username });
  if (error) {
    // Scroll to and focus first error input
    if (error.length > 0 && error[0].key !== 'request') {
      const firstError = document.getElementById(error[0].key);
      const yOffset = -350;
      const y = firstError.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' })
      firstError.focus({ preventScroll: true })
    }
    return dispatch({
      type: Types.EDIT_USER_FAILED,
      payload: error
    });
  }
  dispatch(setEditUserState({ prop: 'error', value: [] }));

  // -- 2. Request
  dispatch(setEditUserState({ prop: 'isLoadingEditUser', value: true }));
  await dispatch(postEditUser(
    (tag, response) => {
      if (isDev()) console.log('apiDeleteBackOfficeOrg - Error: ', response);
      dispatch({
        type: Types.EDIT_USER_FAILED,
        payload: response
      });
    },
    (tag, response) => {
      if (isDev()) console.log('apiDeleteBackOfficeOrg - Success: ', response);
      dispatch({
        type: Types.EDIT_USER_SUCCESS,
      });
      dispatch(responseSuccess(openSuccessModal))
    }
  ));
  dispatch(setEditUserState({ prop: 'isLoadingEditUser', value: false }));
}

export const clearErrorEditUser = () => ({
  type: Types.CLEAR_ERROR_EDIT_USER,
});

export const clearDataEditUser = () => ({
  type: Types.CLEAR_DATA_EDIT_USER,
});

export const responseError = (error) => async (dispatch) => {
  if (error) {
    if (isDev()) console.log(error)
    dispatch(setEditUserState({
      prop: 'requestMessage',
      value: ''
    }));
    dispatch(setEditUserState({
      prop: 'error',
      value: [{
        key: "request",
        value: error.message
      }]
    }));
  }
};

export const responseSuccess = (openSuccessModal) => async (dispatch) => {
  // dispatch(clearDataEditUser());
  openSuccessModal();
}

export const setEditUserState = ({ prop, value }) => ({
  type: Types.SET_EDIT_USER_STATE,
  payload: { prop, value }
});

export const validateOnBlurEditUser = (inputType) => async (dispatch, getState) => {
  const { email, error, phone, username } = getState().EditUserReducer;
  let hasError = false
  let errorItem = null
  let indexError = null

  // --- 1. Validate form
  const errorForm = await FormValidationManager.formEditUser({ email, phone, username });

  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }
  // Search the same error in arr
  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })

  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.EDIT_USER_FAILED,
    payload: error.slice()
  });
}
