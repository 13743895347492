import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearMenuState, setMenuState } from '../drawer/DrawerActions.js'

import { strings } from '../../resources/locales/i18n.js';
import { localAssets } from '../../resources/assets/assets.js';
import { Link } from 'react-router-dom';
import Routing from '../routing/Routing.jsx'
import UserManager from '../user/UserManager'
import {TypeUsers} from "../backOffice/BackOfficeManager";
import {apiPostEditUser, setEditUserState} from "../editUser/EditUserActions";
import EditUserManager from "../editUser/EditUserManager";
import {setUserInfo} from "../user/UserAction";
import {apiRefreshToken} from "../auth/login/LoginActions";
import {apiGetDashboardInfo} from "../dashboard/DashboardActions";

class HeaderContainer extends Component {
  render() {
    const { title } = this.props;
    const body = document.getElementsByTagName("body")[0];
    return (
      // top fixed
      <div className="top">
        {this.renderTopLogoHeader(body)}
        {title ? this.renderBreadcrumb() : <div />}
      </div>
    )
  }
  renderTopLogoHeader(body) {
    const { accessToken, backOfficeOrgData, userInfo } = this.props;
    const organization = UserManager.getUserOrgName(userInfo, backOfficeOrgData);
    const familyName = UserManager.getUserFamilyName(userInfo);
    const profileUser = UserManager.getUserProfile(userInfo);

    return (
      <>
        {/* hero */}
        <div className="top-hero container">
          <div className="row d-flex align-items-center justify-content-between h-100">
            <Link to={Routing.app}>
              <div className="col-sm-12 mt-sm-4 col-md-4 mt-md-0"><img src={localAssets.imgLogoHiperdino.default} alt="Logo de Hiperdino" /></div>
            </Link>
            <div className="col-sm-12 col-md-8 pt-md-3 text-right d-none d-sm-block">
              <span className="title-big">{strings('global.title')}<br />
                {/* <div className='title-lite' style={{marginTop: 8}}>{organization}</div> */}
                {accessToken && profileUser !== TypeUsers.SUPER_ADMIN  ? <div className='title-lite' style={{ marginTop: 8 }}>{organization}</div> : <div />}

                {accessToken && profileUser === TypeUsers.SUPER_ADMIN ?
                    <div className="d-flex justify-content-end align-items-end mt-2">
                      <div className="col-4 col-lg-4">
                        <select
                            style={{height: '30px', fontSize: '18px', marginLeft: '20px'}}
                            required
                            id={'organization'}
                            value={familyName}
                            onChange={(e) => this._setUserInfo(e.target.value)}
                        >
                          {backOfficeOrgData && backOfficeOrgData.length !==0 && backOfficeOrgData.map((org, idx) => {
                            return (
                                <option style={{size: '2px'}} value={org.id_sap}>{org.name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    :
                    <div/>
                }

              </span>
            </div>
          </div>
          <div className="nav-mobile">
            <a className="btn-hamburger justify-content-center align-items-center" onClick={() => {
              this._openMenu();
            }
            }>
              <span className="material-icons">menu</span>
            </a>
          </div>
        </div>
      </>
    )
  }
  renderBreadcrumb() {
    const { breadcrumbList, title } = this.props
    let backUrl = Routing.app
    if (breadcrumbList && breadcrumbList.length > 1) {
      backUrl = breadcrumbList[breadcrumbList.length - 2].path;
    }

    return (
      <>
        {/* bread + title */}
        <div className="top-breadcrumb container">
          <div className="row top-wrapper--breadcrumb d-flex align-items-center">
            <div className="col-8">
              <div className="row">
                <div className="col-12">
                  <div className="">
                    <ul className="mb-0">
                      {breadcrumbList ? breadcrumbList.map((item, idx) => {
                        if (breadcrumbList.length - 1 === idx) {
                          return <p key={idx}>{item.name}</p>
                        }
                        return <li key={idx}><Link to={item.path}>{item.name}</Link></li>
                      }) : <div />}
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <span className="title-mid">{title}</span>
                </div>
              </div>
            </div>
            {breadcrumbList.length === 1 ? <div /> :
              <div className="col-4 text-right">
                <Link to={backUrl} className="link-text d-flex align-items-center justify-content-end">
                  <span className="material-icons">keyboard_arrow_left</span>{strings('header.back')}
                </Link>
              </div>
            }
          </div>
        </div>
      </>
    )
  }

  _setUserInfo = async (value) => {
    const {userInfo} = this.props;
    const userAttributes = UserManager.getUserAttributes(userInfo);
    const email = EditUserManager.getUserEmail(userAttributes);
    const fullName = EditUserManager.getUserFullName(userAttributes);
    const isAdmin = EditUserManager.getUserAdminState(userAttributes);
    const mfa = EditUserManager.getUserMfa(userAttributes);
    const phone = EditUserManager.getUserPhone(userAttributes);
    const userId = EditUserManager.getUserId(userInfo);

    this.props.setEditUserState({prop: 'admin', value: isAdmin});
    this.props.setEditUserState({prop: 'userId', value: userId});
    this.props.setEditUserState({prop: 'username', value: fullName ? fullName : ''});
    this.props.setEditUserState({prop: 'email', value: email});
    this.props.setEditUserState({prop: 'mfa', value: mfa});
    this.props.setEditUserState({prop: 'organization', value: value});
    this.props.setEditUserState({prop: 'phone', value: phone});

    await this.props.apiPostEditUser(() => {});
    await this.props.setUserInfo()
    await this.props.apiRefreshToken(null, () => {
      if (window.location.pathname === Routing.dashboard) {
        this.props.apiGetDashboardInfo()
      }
    });


  }


  _openMenu() {
    const body = document.getElementsByTagName("body")[0]
    if (!body.classList.contains('menu-on')) {
      body.classList.add('menu-on')
      this.props.setMenuState({ prop: 'isMenuOpen', value: true })
    }
  }
}
  const mapStateToProps = ({ DrawerReducer, UserReducer, BackOfficeReducer }) => {
  const { accessToken } = UserReducer;
  const { isMenuOpen } = DrawerReducer;
  const { userInfo } = UserReducer;
  const { backOfficeOrgData } = BackOfficeReducer;
  return {
    accessToken,
    backOfficeOrgData,
    isMenuOpen,
    userInfo
  }
}

const mapStateToPropsAction = {
  apiGetDashboardInfo,
  apiPostEditUser,
  apiRefreshToken,
  clearMenuState,
  setUserInfo,
  setMenuState,
  setEditUserState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(HeaderContainer);
