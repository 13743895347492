import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { deleteNotification, getNotificationsInfo, putNotifications } from '../../services/api/API';

export const apiDeleteNotification = (id, getNotifications) => async (dispatch, getState) => {
  const { notificationsData } = getState().NotificationsReducer;
  if (notificationsData) {
    const notificationsDataWithItemLoadingTrue = notificationsData.map(notification => {
      if (notification.id === id) {
        notification.isLoadingDelete = true;
      }
      return notification;
    })
    dispatch(setNotificationsInfoDataState({ prop: 'notificationsData', value: notificationsDataWithItemLoadingTrue }));
  }
  dispatch(setNotificationsInfoDataState({ prop: 'isLoadingNotificationsInfo', value: true }));
  await dispatch(deleteNotification(
    id,
    (tag, response) => {
      if (isDev()) console.log('apiDeleteNotificationsInfo - Error: ', response);
      dispatch({ type: Types.DELETE_NOTIFICATIONS_INFO_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiDeleteNotificationsInfo - Success: ', response);
      dispatch({
        type: Types.DELETE_NOTIFICATIONS_INFO_SUCCESS,
        payload: response
      });
      getNotifications();
    }
  ));
  dispatch(setNotificationsInfoDataState({ prop: 'isLoadingNotificationsInfo', value: false }));
};

export const apiGetNotificationsInfo = () => async (dispatch) => {
  dispatch(setNotificationsInfoDataState({ prop: 'isLoadingNotificationsInfo', value: true }));
  await dispatch(getNotificationsInfo(
    (tag, response) => {
      if (isDev()) console.log('apiGetNotificationsInfo - Error: ', response);
      dispatch({ type: Types.GET_NOTIFICATIONS_INFO_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetNotificationsInfo - Success: ', response);
      dispatch({
        type: Types.GET_NOTIFICATIONS_INFO_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setNotificationsInfoDataState({ prop: 'isLoadingNotificationsInfo', value: false }));
};

export const apiPutNotificationsInfo = (id, getNotifications) => async (dispatch, getState) => {
  const { notificationsData } = getState().NotificationsReducer;
  if (notificationsData) {
    const notificationsDataWithItemLoadingTrue = notificationsData.map(notification => {
      if (notification.id === id) {
        notification.isLoadingPut = true;
      }
      return notification;
    })
    dispatch(setNotificationsInfoDataState({ prop: 'notificationsData', value: notificationsDataWithItemLoadingTrue }));
  }
  dispatch(setNotificationsInfoDataState({ prop: 'isLoadingNotificationsInfo', value: true }));

  await dispatch(putNotifications(
    id,
    (tag, response) => {
      if (isDev()) console.log('apiPutNotificationsInfo - Error: ', response);
      dispatch({ type: Types.PUT_NOTIFICATIONS_INFO_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiPutNotificationsInfo - Success: ', response);
      dispatch({
        type: Types.PUT_NOTIFICATIONS_INFO_SUCCESS,
        payload: response
      });
      getNotifications();
    }
  ));
  if (notificationsData) {
    const notificationsDataWithItemLoadingTrue = notificationsData.map(notification => {
      if (notification.id === id) {
        notification.isLoadingPut = false;
      }
      return notification;
    })
    dispatch(setNotificationsInfoDataState({ prop: 'notificationsData', value: notificationsDataWithItemLoadingTrue }));
  }
  dispatch(setNotificationsInfoDataState({ prop: 'isLoadingNotificationsInfo', value: false }));
};

export const clearNotificationsInfo = () => ({
  type: Types.CLEAR_NOTIFICATIONS_INFO,
});

export const setNotificationsInfoDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_NOTIFICATIONS_INFO,
  payload: { prop, value }
});