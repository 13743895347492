import FormValidationManager from '../../utils/managers/FormValidationManager.js';
import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { postEmail } from '../../services/api/API';
import {strings} from '../../resources/locales/i18n';

export const apiPostEmail = (redirectSuccess) => async (dispatch, getState) => {
  const { cif, company, consult, email, username, surnames, telephone } = getState().CostumerSupportReducer;
  const { accessToken } = getState().UserReducer;

  if( accessToken ) {
    // --- 1. Validate form on CostumerSupport page
    const error = await FormValidationManager.formCostumerSupport({ company, consult, email, username, surnames, telephone });
    if (error) {
      return dispatch({
        type: Types.FORM_COSTUMER_SUPPORT_FAILED,
        payload: error
      });
    }
  }

  if( !accessToken ) {
    // --- 1. Validate form on ContactUs page
    const error = await FormValidationManager.formContactUs({ cif, company, consult, email, username, surnames, telephone });
    if (error) {
      return dispatch({
        type: Types.FORM_COSTUMER_SUPPORT_FAILED,
        payload: error
      });
    }
  }

  dispatch(setSupportCostumerDataState({ prop: 'error', value: [] }));
  dispatch(setSupportCostumerDataState({ prop: 'errorRequest', value: '' }));

  // -- 2. Request
  dispatch(setSupportCostumerDataState({ prop: 'isLoadingCostumerSupport', value: true }));
  await dispatch(postEmail(
    (tag, response) => {
      if (isDev()) console.log('apiFormCostumerSupport - Error: ', response);
      dispatch(responseError(response));
    },
    (tag, response) => {
      if (isDev()) console.log('apiFormCostumerSupport - Success: ', response);
      dispatch(responseSuccess(redirectSuccess));
    }
  ))
  dispatch(setSupportCostumerDataState({ prop: 'isLoadingCostumerSupport', value: false }));
};

export const clearDataCostumerSupport = () => ({
  type: Types.CLEAR_DATA_COSTUMER_SUPPORT,
});

export const setSupportCostumerDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_COSTUMER_SUPPORT,
  payload: { prop, value }
});

export const responseError = (error) => async (dispatch) => {
  if (error && error.message) {
    let value = strings('error.server400');
    if (error.code === "CodeMismatchException") {
      value = strings('login.error.codeMismatchException')
    }
    dispatch(setSupportCostumerDataState({
      prop: 'error',
      value: [{
        key: "request",
        value: value
      }]
    }));
  }
};

export const responseSuccess = (redirectSuccess) => async (dispatch) => {
  dispatch(clearDataCostumerSupport());
  redirectSuccess();
}

export const validateOnBlurCostumerSupport = (inputType) => async (dispatch, getState) => {
  const { cif, company, consult, email, error, username, surnames, telephone } = getState().CostumerSupportReducer;
  const { accessToken } = getState().UserReducer;
  let hasError = false
  let errorItem = null
  let indexError = null
  let errorForm = null;

  // --- 1. Validate form on CostumerSupport page
  if( accessToken ) {
  errorForm = await FormValidationManager.formCostumerSupport({ company, consult, email, username, surnames, telephone });
  }
  // --- 2. Validate form on ContactUs page
  if( !accessToken ){
  errorForm = await FormValidationManager.formContactUs({ cif, company, consult, email, username, surnames, telephone });
  }
  // Search the error of inputType
  if (errorForm) {
    errorForm.map((item, index) => {
      if (item.key === inputType) {
        errorItem = item
      }
    })
  }

  error.map((item, index) => {
    if (item.key === inputType) {
      hasError = true;
      indexError = index;
    }
  })
  // Add or delete error of array
  if (errorItem) {
    if (!hasError) { error.push(errorItem) }
  } else {
    if (indexError !== null) { error.splice(indexError, 1) }
  }

  return dispatch({
    type: Types.FORM_COSTUMER_SUPPORT_FAILED,
    payload: error.slice()
  });
}
