export default class Types {
  /** CLEAR **/
  static CLEAR_DATA_NEW_PASSWORD = 'CLEAR_DATA_NEW_PASSWORD';

  /** POST NEW_PASSWORD **/
  static NEW_PASSWORD_FAILED      = 'NEW_PASSWORD_FAILED';
  static NEW_PASSWORD_SUCCESS     = 'NEW_PASSWORD_SUCCESS';

  /** UPDATE STATE **/
  static SET_NEW_PASSWORD_STATE   = 'SET_NEW_PASSWORD_STATE';
}
