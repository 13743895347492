import Types from "./Types";

const INITIAL_STATE = {
  code: '',
  code1: '',
  code2: '',
  code3: '',
  code4: '',
  code5: '',
  code6: '',
  cognitoUser: '',
  error: [],
  isLoadingGetUser: false,
  isLoadingLogin: false,
  logoutMessage: '',
  password: '',
  username: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DATA **/
    case Types.CLEAR_CODE_LOGIN:
      return { ...state, code: '', code1: '', code2: '', code3: '', code4: '', code5: '', code6: '', cognitoUser: ''};

    case Types.CLEAR_DATA_LOGIN:
      return { ...INITIAL_STATE, logoutMessage: state.logoutMessage };

    /** LOGIN **/
    case Types.LOGIN_FAILED:
      return { ...state, error: action.payload };

    case Types.LOGIN_START:
      return { ...state, error: null };

    case Types.LOGIN_SUCCESS:
      return { ...state };


    /** UPDATE STATE **/
    case Types.SET_LOGIN_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
};
