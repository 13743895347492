import FormValidationManager from '../../utils/managers/FormValidationManager.js';
import Types from "./Types";

// Services
import { isDev } from '../../utils/Utils';
import { postResponsibleConsumptionDownload } from '../../services/api/API';

export const apiPostResponsibleConsumptionDownload = (openCodeModal) => async (dispatch, getState) => {

  // Request
  dispatch(setResponsibleConsumptionDataState({ prop: 'isLoadingResponsibleConsumption', value: true }));
  await dispatch(postResponsibleConsumptionDownload(
    (tag, response) => {
      if (isDev()) console.log('apiPostResponsibleConsumptionDownload - Error: ', response);
      dispatch({ type: Types.GET_RESPONSIBLE_CONSUMPTION_FAILED, payload: response.data.message });
      openCodeModal(response.data.message || `Error ${response.status}`)
    },
    (tag, response) => {
      if (isDev()) console.log('apiPostResponsibleConsumptionDownload - Success: ', response);

      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response}`;
      const downloadLink = document.createElement("a");
      const fileName = `Informe_de_consumo`

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      dispatch(clearDataResponsibleConsumption());
    }
  ));
  dispatch(setResponsibleConsumptionDataState({ prop: 'isLoadingResponsibleConsumption', value: false }));
};

export const clearDataResponsibleConsumption = () => ({
  type: Types.CLEAR_DATA_RESPONSIBLE_CONSUMPTION,
});

export const setResponsibleConsumptionDataState = ({ prop, value }) => ({
  type: Types.SET_DATA_RESPONSIBLE_CONSUMPTION,
  payload: { prop, value }
});