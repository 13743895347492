export default class Types {
  /** CLEAR **/
  static CLEAR_DATA_CREATE_ORG  = 'CLEAR_DATA_CREATE_ORG';

  /** GET ORG **/
  static GET_ORG_SUCCESS        = 'GET_ORG_SUCCESS';

  /** POST CREATE_ORG **/
  static CREATE_ORG_FAILED      = 'CREATE_ORG_FAILED';
  static CREATE_ORG_SUCCESS     = 'CREATE_ORG_SUCCESS';

  /** UPDATE STATE **/
  static SET_CREATE_ORG_STATE   = 'SET_CREATE_ORG_STATE';
}
