import React, { Component } from 'react';
import { connect } from 'react-redux';
import { strings } from '../../resources/locales/i18n.js';
import { CardBillingStatus, CardUsesStatus } from './CardsManager';
import { apiPostSearch, setCardsDataState } from './CardsActions';
import { getMomentNow, getMomentYearAgo, parseDecimal } from '../../utils/Utils';
import { Loading } from '../../components';
import moment from 'moment';

class CardsFormContainer extends Component {
  render() {
    const {
      billingStatus, dateActivationStart, dateActivationEnd, dateLastPurchaseStart, dateLastPurchaseEnd, error, isLoadingPostSearch, initialBalance, usesStatus, uuid,
    } = this.props;

    return (
      <form id="CardsForm" onSubmit={(e) => this._apiPostSearch(e)} >
        { /** UUID **/}
        <div className="form-group form-group--label mb-4">
          <label htmlFor="user">{strings('placeholder.cardNumber')}</label>
          <input
            id={"uuid"}
            className="form-control"
            type="text"
            placeholder=""
            value={uuid}
            onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
          />
          <button type="submit" className="btn-search"
          />
        </div>

        { /** BILLING STATUS **/}
        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <span className="title-mid d-block mb-3">{strings('formFilters.billingStatus.title')}</span>
            <select
              id={"billingStatus"}
              className="form-group"
              aria-label="Default select example"
              value={billingStatus}
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
            >
              <option value="">{strings('formFilters.billingStatus.selectAnOption')}</option>
              <option value={CardBillingStatus.BALANCE}>{strings('formFilters.billingStatus.redemptionComplete')}</option>
              <option value={CardBillingStatus.SPENDING}>{strings('formFilters.billingStatus.redemptionPartial')}</option>
            </select>
          </div>
        </div>

        { /** USES STATUS **/}
        <div className="row mb-3">
          <div className="col-12 col-sm-6 mb-3">
            <span className="title-mid d-block mb-3">{strings('formFilters.usesStatus.title')}</span>
            <select
              id={"usesStatus"}
              className="form-group "
              aria-label="Default select example"
              value={usesStatus}
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
            >
              <option value="">{strings('formFilters.billingStatus.selectAnOption')}</option>
              <option value={CardUsesStatus.ENABLE}>{strings('formFilters.usesStatus.enabled')}</option>
              <option value={CardUsesStatus.CANCELED}>{strings('formFilters.usesStatus.canceled')}</option>
              <option value={CardUsesStatus.PENDING}>{strings('formFilters.usesStatus.pending')}</option>
              <option value={CardUsesStatus.REDEEMED}>{strings('formFilters.usesStatus.redeemed')}</option>
            </select>
          </div>


          {/** INITIAL BALANCE **/}
          <div className="col-12 col-sm-6 mb-3">
            <span className="title-mid d-block mb-3">{strings('formFilters.initialBalance')}</span>
            <div className="form-group">
              <input
                id={"initialBalance"}
                className="form-control"
                type="number"
                placeholder=""
                value={initialBalance}
                onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value)}
              />
            </div>
          </div>
        </div>

        {/** DATE LAST PURCHASE **/}
        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <span className="title-mid d-block mb-3">{strings('formFilters.dateLastPurchase')}</span>
            <div className="row">
              {/* START */}
              <div className="form-group col-sm-6">
                <div className="title-lite mb-1">{strings('formFilters.interval.start')}</div>
                <div style={{ position: 'relative' }}>

                  <span className="material-icons icon-calendar">
                    event
              </span>
                  <input
                    required={dateLastPurchaseEnd}
                    id={"dateLastPurchaseStart"}
                    className="form-control datepicker"
                    type="date"
                    placeholder=""
                    value={dateLastPurchaseStart}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    min={getMomentYearAgo('yyyy-MM-DD')}
                    max={getMomentNow("yyyy-MM-DD")}
                  />
                </div>

              </div>
              {/* END */}
              <div className="form-group col-sm-6">
                <div className="title-lite mb-1">{strings('formFilters.interval.end')}</div>
                <div style={{ position: 'relative' }}>
                  <span className="material-icons icon-calendar">
                    event
                </span>
                  <input
                    required={dateLastPurchaseStart}
                    id={"dateLastPurchaseEnd"}
                    className="form-control datepicker"
                    type="date"
                    placeholder=""
                    value={dateLastPurchaseEnd}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    min={dateLastPurchaseStart}
                    max={dateLastPurchaseStart ?
                      this._compareDateStartToEnd(dateLastPurchaseStart, "yyyy-MM-DD", 1, 'Y') :
                      getMomentNow("yyyy-MM-DD")}
                  />
                </div>
              </div>
            </div>

          </div>

          {/** DATE ACTIVATION **/}
          <div className="col-12 col-sm-6 mb-3">

            <span className="title-mid d-block mb-3">{strings('formFilters.dateActivation')}</span>
            <div className="row">
              {/** START **/}
              <div className="form-group col-sm-6">
                <div className="title-lite mb-1">{strings('formFilters.interval.start')}</div>
                <div style={{ position: 'relative' }}>
                  <span className="material-icons icon-calendar">
                    event
                </span>
                  <input
                    required={dateActivationEnd}
                    id={"dateActivationStart"}
                    className="form-control datepicker"
                    type="date"
                    placeholder=""
                    value={dateActivationStart}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    min={getMomentYearAgo('yyyy-MM-DD')}
                    max={getMomentNow("yyyy-MM-DD")}
                  />
                </div>

              </div>
              {/** END **/}
              <div className="form-group col-sm-6">
                <div className="title-lite mb-1">{strings('formFilters.interval.end')}</div>
                <div style={{ position: 'relative' }}>
                  <span className="material-icons icon-calendar">
                    event
                </span>
                  <input
                    required={dateActivationStart}
                    id={"dateActivationEnd"}
                    className="form-control datepicker"
                    type="date"
                    placeholder=""
                    value={dateActivationEnd}
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    min={dateActivationStart}
                    max={dateActivationStart ?
                      this._compareDateStartToEnd(dateActivationStart, "yyyy-MM-DD", 1, 'Y') :
                      getMomentNow("yyyy-MM-DD")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="form-group d-flex justify-content-left">
              <button
                type="submit"
                className="btn-primary">
                {isLoadingPostSearch ?
                  <Loading />
                  :
                  strings('button.search')
                }
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  /* PRIVATE METHOD */
  _apiPostSearch(e) {
    e.preventDefault();
    this.props.apiPostSearch();
  }

  _handleValueChange = (prop, value) => {
    this.props.setCardsDataState({ prop, value });
  };

  _handleValueDecimalChange = (prop, value) => {
    this.props.setCardsDataState({ prop, value: parseDecimal(value) });
  };

  _compareDateStartToEnd = (date, format, numberDays, type) => {
    const dateUser = moment(date).add(numberDays, type).format("yyyyMMDD");
    const dateNow = getMomentNow("yyyyMMDD");

    if (dateNow > dateUser) {
      // date is past
      return moment(date).add(numberDays, type).format(format);
    }
    // date is future
    return getMomentNow(format)
  }
}
const mapStateToProps = ({ CardsReducer }) => {
  const {
    billingQuantity, billingStatus, dateActivationEnd, dateActivationStart, dateLastPurchaseStart, dateLastPurchaseEnd,
    error, isLoadingPostSearch, initialBalance, usesStatus, uuid,
  } = CardsReducer;

  return {
    billingQuantity, billingStatus, dateActivationEnd, dateActivationStart, dateLastPurchaseStart, dateLastPurchaseEnd,
    error, isLoadingPostSearch, initialBalance, usesStatus, uuid
  };
};

const mapStateToPropsAction = {
  apiPostSearch,
  setCardsDataState
};

export default connect(mapStateToProps, mapStateToPropsAction)(CardsFormContainer);
