import Types from "./Types";

const INITIAL_STATE = {
  error: [],
  id: '',
  idSap: '',
  isLoadingEditOrg: false,
  name: '',
  requestMessage: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DATA **/
    case Types.CLEAR_DATA_EDIT_ORG:
      return { ...INITIAL_STATE, error: [] };

    /** EDIT_ORG **/
    case Types.EDIT_ORG_FAILED:
      return { ...state, error: action.payload };

    case Types.EDIT_ORG_SUCCESS:
      return { ...state };


    /** UPDATE STATE **/
    case Types.SET_EDIT_ORG_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
};
