import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import {
  apiPostEditUser,
  clearErrorEditUser,
  clearDataEditUser,
  setEditUserState,
  validateOnBlurEditUser,
} from '../editUser/EditUserActions'
import { apiEditPassword, clearPasswords, setUserInfo, setUserDataProps, validateOnBlurChangePassword } from '../user/UserAction';
import { setNewPasswordState } from '../auth/newPassword/NewPasswordActions'


// Components
import { AlertContainer } from 'src/components';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { ModalContainer } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import EditUserManager from "../editUser/EditUserManager";
import UserManager from "../user/UserManager";
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';
import { OnboardingContainer } from '../../components/OnboardingContainer';

class UserProfileContainer extends Component {

  state = {
    isEditMode: false,
    isModalShown: false,
    isPasswordModalShown: false,
    key: 0,
  }

  componentDidMount() {
    this._clearDataEditUser();
    this._clearPasswords();
    const { userInfo } = this.props;
    this._setUserInfo(userInfo);
  }

  componentWillUnmount() {
    this._clearDataEditUser();
  }

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.myAccount'), path: Routing.nowhere },
    ]
    const { isFirstTime } = this.props;

    return (
      <>
        <HeaderContainer title={strings('viewName.myAccount')} breadcrumbList={breadcrumbList} />
        {isFirstTime ?
          <OnboardingContainer markAsSeen={() => this.props.setNewPasswordState({ prop: 'isFirstTime', value: false })} />
          :
          <div />
        }
        <ModalContainer
          closeAction={() => this._toggleModal()}
          icon='check'
          message={strings('userProfileView.modal.message')}
          modalId='editUserConfirm'
          isModalShown={this.state.isModalShown}
          primaryAction={() => this._toggleModal()}
          primaryButtonText={strings('userProfileView.modal.primaryButton')}
          title={strings('userProfileView.modal.title')}
        />
        <ModalContainer
          closeAction={() => this._togglePasswordModal()}
          icon='check'
          message={strings('userProfileView.modal.passwordMessage')}
          modalId='changePasswordConfirm'
          isModalShown={this.state.isPasswordModalShown}
          primaryAction={() => this._togglePasswordModal()}
          primaryButtonText={strings('userProfileView.modal.passwordPrimaryButton')}
          title={strings('userProfileView.modal.passwordTitle')}
        />
        {this.renderEditUserForm()}
        {this.renderEditPassword()}
        {this.renderSettings()}
      </>
    )
  }
  renderEditPassword() {
    const { currentPassword, isLoadingEditPassword, newPassword, passwordError } = this.props;

    return (
      <section className="container wrapper wrapper--content d-flex pb-5 justify-content-center">
        <div className="col-12 col-lg-9 mb-4 mb-md-0">
          <div className="card pl-5 pr-5 pb-3 pt-2">
            <form id="changePasswordForm" onSubmit={(e) => this._apiEditPassword(e)} action="" className="w-100 mt-5">
              <AlertContainer
                error={passwordError}
                id={'request'}
                className={'alert-danger'}
              />
              <div className="row pb-4">
                <div className="col-12 pb-5">
                  <span className="title-mid d-block">{strings('userProfileView.passwordForm.password')}</span>
                </div>
                { /** OLD PASSWORD */}
                <div className="col-12 col-sm-6">
                  <TextInput
                    title={strings('userProfileView.passwordForm.currentPassword')}
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    error={passwordError}
                    placeholder=""
                    onChange={(e) => this._handlePasswordValueChange(e.target.id, e.target.value)}
                    value={currentPassword}
                    onBlur={(e) => this._validateOnBlurChangePassword(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                  />
                </div>
                { /** NEW PASSWORD */}
                <div className="col-12 col-sm-6">
                  <TextInput
                    title={strings('userProfileView.passwordForm.newPassword')}
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    error={passwordError}
                    placeholder=""
                    onChange={(e) => this._handlePasswordValueChange(e.target.id, e.target.value)}
                    value={newPassword}
                    onBlur={(e) => this._validateOnBlurChangePassword(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                  />
                </div>

                <div className="form-group d-flex mt-4 justify-content-center">
                  <button type="submit" className="btn-primary">
                    {isLoadingEditPassword ? <Loading /> : strings('userProfileView.button.changePassword')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }

  renderEditUserForm() {
    const {
      email, error, isLoadingEditUser, phone, username, requestMessage, surnames,
    } = this.props


    return (
      <section className="container wrapper wrapper--content d-flex pt-5 pb-5 justify-content-center">
        <div className="col-12 col-lg-9 mb-4 mb-md-0">
          <div className="card pl-5 pr-5 pb-3 pt-2">
            <form id="userForm" onSubmit={(e) => this._apiPostEditUser(e)} action="" className="w-100 mt-5">
              <AlertContainer
                error={error}
                id={'request'}
                className={'alert-danger'}
              />
              <div className="row pb-4">
                <div className="col-12 pb-5">
                  <span className="title-mid d-block">{strings('userProfileView.userData')}</span>
                </div>
                { /** EMAIL */}
                <div className="col-12">
                  <TextInput
                    key={this.state.key}
                    title={strings('userProfileView.userForm.email')}
                    id="email"
                    name="email"
                    type="text"
                    error={error}
                    placeholder=""
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={email}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                    readOnly
                  />
                </div>
                { /** NAME */}
                <div className="col-12  col-sm-6">
                  <TextInput
                    key={this.state.key}
                    title={strings('userProfileView.userForm.name')}
                    id="username"
                    name="username"
                    type="text"
                    error={error}
                    placeholder=""
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={username}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                    readOnly={!this.state.isEditMode}
                  />
                </div>

                { /** PHONE */}
                <div className="col-12 col-sm-6">
                  <TextInput
                    key={this.state.key}
                    title={strings('userProfileView.userForm.phone')}
                    id="phone"
                    name="phone"
                    type="text"
                    error={error}
                    placeholder=""
                    onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
                    value={phone}
                    onBlur={(e) => this._validateOnBlur(e.target.id)}
                    onKeyDown={(e) => this._callTabFunction(e)}
                    readOnly={!this.state.isEditMode}
                  />
                </div>
                <div className="form-group d-flex justify-content-center">
                  {this.state.isEditMode ?
                    <button type="submit" className="btn-primary mt-4">
                      {isLoadingEditUser ? <Loading /> : strings('userProfileView.button.saveChanges')}
                    </button>
                    :
                    <div type="button" className="btn-primary mt-4" onClick={(e) => { e.stopPropagation; this.setState({ isEditMode: true }) }}>
                      {isLoadingEditUser ? <Loading /> : strings('userProfileView.button.editData')}
                    </div>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }

  renderSettings() {
    const { isFirstTime } = this.props;

    return (
      <section className="container wrapper wrapper--content d-flex pb-5 justify-content-center">
        <div className="col-12 col-lg-9 mb-4 mb-md-0">
          <div className="card pl-5 pr-5 pb-3 pt-2">
            <div className="col-12 pb-5 mt-5">
              <span className="title-mid d-block">{strings('userProfileView.settings.title')}</span>
            </div>
            <div className="row px-4 pb-5 justify-content-between">
              <div>{strings('userProfileView.settings.showOnboarding')}</div>
              <div>
                <label class="switch">
                  <input type="checkbox"
                    id='isFirstTime'
                    name='isFirstTime'
                    value={isFirstTime}
                    checked={isFirstTime}
                    onChange={(e) => { this.props.setNewPasswordState({prop: e.target.id, value: e.target.checked}) }}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  /* PRIVATE METHOD */

  _apiEditPassword(e) {
    e.preventDefault();
    this.props.apiEditPassword(() => this._togglePasswordModal());
  }

  _apiPostEditUser(e) {
    e.preventDefault();
    this.props.apiPostEditUser(() => this._toggleModal());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearDataEditUser() {
    this.props.clearDataEditUser();
  }

  _clearErrorEditUser() {
    this.props.clearErrorEditUser();
  }

  _clearPasswords() {
    this.props.clearPasswords();
  }

  _getRandomKey() {
    this.setState({ key: Math.random() })
  }

  _getUserInfo() {
    this.props.setUserInfo();
  }

  _handlePasswordValueChange = (prop, value) => {
    this.props.setUserDataProps({ prop, value });
  };

  _handleValueChange = (prop, value) => {
    this.props.setEditUserState({ prop, value });
  };

  _handleViewChange = () => {
    this._toggleModal();
    const { history } = this.props;
    history.push(Routing.backOffice);
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurEditUser(inputType)
  }

  _validateOnBlurChangePassword(inputType) {
    this.props.validateOnBlurChangePassword(inputType)
  }

  _setUserInfo(userInfo) {
    const userAttributes = UserManager.getUserAttributes(userInfo)
    const email = EditUserManager.getUserEmail(userAttributes)
    const fullName = EditUserManager.getUserFullName(userAttributes)
    const isAdmin = EditUserManager.getUserAdminState(userAttributes)
    const orgIdSap = EditUserManager.getUserIdSap(userAttributes)
    const phone = EditUserManager.getUserPhone(userAttributes)
    const userId = EditUserManager.getUserId(userInfo)

    this.props.setEditUserState({ prop: 'admin', value: isAdmin });
    this.props.setEditUserState({ prop: 'userId', value: userId });
    this.props.setEditUserState({ prop: 'username', value: fullName ? fullName : '' });
    this.props.setEditUserState({ prop: 'email', value: email });
    this.props.setEditUserState({ prop: 'organization', value: orgIdSap });
    this.props.setEditUserState({ prop: 'phone', value: phone });

    const newKey = Math.random(); // Re render radio inputs to check correct value
    this.setState({ key: newKey })
  }

  _toggleEditMode() {
    if (this.state.isEditMode === false) {
      this.setState({ isEditMode: true })
    } else {
      this.setState({ isEditMode: false })
    }
  }

  _toggleModal() {
    const editUserConfirm = document.getElementById('editUserConfirm');
    if (editUserConfirm.classList.contains('invisible')) {
      editUserConfirm.classList.remove('invisible')
      this.setState({ isModalShown: true })
    } else {
      editUserConfirm.classList.add('invisible')
      this.setState({ isModalShown: false })
      this._toggleEditMode();
      this._getRandomKey();
      this._getUserInfo();
    }
    this._clearErrorEditUser()
  }

  _togglePasswordModal() {
    const changePasswordConfirm = document.getElementById('changePasswordConfirm');
    if (changePasswordConfirm.classList.contains('invisible')) {
      changePasswordConfirm.classList.remove('invisible')
      this.setState({ isPasswordModalShown: true })
    } else {
      changePasswordConfirm.classList.add('invisible')
      this.setState({ isPasswordModalShown: false })
      this._getRandomKey();
    }
  }
}

const mapStateToProps = ({ EditUserReducer, BackOfficeReducer, NewPasswordReducer, UserReducer }) => {
  const {
    admin, email, error, isLoadingEditUser, organization, phone, username, requestMessage, surnames
  } = EditUserReducer;
  const { backOfficeOrgData } = BackOfficeReducer;
  const { currentPassword, newPassword, passwordError, userInfo } = UserReducer;
  const { isFirstTime, } = NewPasswordReducer;


  return {
    admin,
    backOfficeOrgData,
    currentPassword,
    email,
    error,
    isFirstTime,
    isLoadingEditUser,
    newPassword,
    organization,
    passwordError,
    phone,
    username,
    requestMessage,
    surnames,
    userInfo
  }
}

const mapStateToPropsAction = {
  apiEditPassword,
  apiPostEditUser,
  clearPasswords,
  clearErrorEditUser,
  clearDataEditUser,
  setEditUserState,
  setNewPasswordState,
  setUserDataProps,
  setUserInfo,
  validateOnBlurEditUser,
  validateOnBlurChangePassword,
};
export default connect(mapStateToProps, mapStateToPropsAction)(UserProfileContainer);