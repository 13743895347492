import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import {
  apiPostEditUser,
  clearDataEditUser,
  setEditUserState,
  validateOnBlurEditUser,
} from './EditUserActions'

// Components
import { AlertContainer } from 'src/components';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { ModalContainer } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import EditUserManager from "./EditUserManager";
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';
import {TypeUsers} from "../backOffice/BackOfficeManager";

class EditUserContainer extends Component {

  state = {
    isModalShown: false,
    key: 0
  }

  componentDidMount() {
    const userInfo = this.props.location.params;
    this._setUserInfo(userInfo);
  }

  componentWillUnmount() {
    this._clearDataEditUser();
  }
  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.backOffice'), path: Routing.backOffice },
      { name: strings('viewName.editUser'), path: Routing.nowhere },
    ]
    const userInfo = this.props.location.params;
    if (!userInfo) {
      const { history } = this.props;
      history.push(Routing.backOffice)
    }
    return (
      <>
        <HeaderContainer title={strings('viewName.backOffice')} breadcrumbList={breadcrumbList} />
        <ModalContainer
          closeAction={() => this._handleViewChange()}
          icon='check'
          message={strings('editUserView.modal.message')}
          modalId='editUserConfirm'
          isModalShown={this.state.isModalShown}
          primaryAction={() => this._handleViewChange()}
          primaryButtonText={strings('editUserView.modal.primaryButton')}
          title={strings('editUserView.modal.title')}
        />
        <section className="container wrapper wrapper--content d-flex pt-5 pb-5 justify-content-center">
          <div className="col-12 col-lg-9 mb-4 mb-md-0">
            <div className="card pl-5 pr-5 pb-3 pt-2">
              {this.renderEditUserForm()}
            </div>
          </div>
        </section>
      </>
    )
  }

  renderEditUserForm() {
    const
      {
        admin, backOfficeOrgData, email, error, isLoadingEditUser, mfa, organization, phone, username, requestMessage, surnames,
      } = this.props;

    return (
      <form id="loginForm" onSubmit={(e) => this._apiPostEditUser(e)} action="" className="w-100 mt-5">
        <AlertContainer
          error={error}
          id={'request'}
          className={'alert-danger'}
        />
        <div className="row pb-4">
          <div className="col-12 pb-5">
            <span className="title-mid d-block">{strings('editUserView.userData')}</span>
          </div>
          { /** EMAIL */}
          <div className="col-12">
            <TextInput
              title={strings('editUserView.form.email')}
              id="email"
              name="email"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={email}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          { /** NAME */}
          <div className="col-12  col-sm-6">
            <TextInput
              title={strings('editUserView.form.name')}
              id="username"
              name="username"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={username}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>

          { /** PHONE */}
          <div className="col-12 col-sm-6">
            <TextInput
              title={strings('editUserView.form.phone')}
              id="phone"
              name="phone"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={phone}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
            />
          </div>
          { /** ORGANIZATION */}
          <div className="col-12 col-lg-6">
            <select
              required
              id={'organization'}
              className="form-group"
              aria-label="Default select example"
              value={organization}
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
            >
              <option value=''>{strings('editUserView.form.chooseOrganization')}</option>
              {backOfficeOrgData.map((org, idx) => {
                return (
                  <option value={org.id_sap}>{org.name}</option>
                )
              })}
            </select>
          </div>
          <div className="col-12 row p-0 m-0">

            { /** ADMIN RADIO INPUTS */}
            <div className="col-12 col-sm-6 mt-4 mb-3">
              <div >
                <span className="title-mid d-block mb-3">{strings('editUserView.form.adminPermissions')}</span>
              </div>
              <div className="mb-3 pl-md-2 ">
                <div className="form-check mb-2 mr-5">
                  <input
                      required
                      className="form-check-input"
                      type="radio"
                      name={'admin'}
                      id={'admin-1'}
                      value={TypeUsers.ASSISTANT}
                      checked={admin === TypeUsers.ASSISTANT}
                      onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Tarjetas">
                    {strings('createUserView.form.assistant')}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                      required
                      className="form-check-input"
                      type="radio"
                      name={'admin'}
                      id={'admin-2'}
                      value={TypeUsers.ADMIN}
                      checked={admin === TypeUsers.ADMIN}
                      onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Facturas">
                    {strings('createUserView.form.admin')}
                  </label>
                </div>

                <div className="form-check">
                  <input
                      defaultValue={admin}
                      required
                      className="form-check-input"
                      type="radio"
                      name={'admin'}
                      id={'admin-3'}
                      value={TypeUsers.SUPER_ADMIN}
                      checked={admin === TypeUsers.SUPER_ADMIN}
                      onChange={(e) => this._handleValueChange(e.target.name, e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Facturas">
                    {strings('createUserView.form.superAdministrator')}
                  </label>
                </div>
              </div>
            </div>
            { /** MFA */}
            <div className="col-12 col-sm-6 mt-4 mb-3 pl-4">
              <div>
                <div className="title-mid mb-3">{strings('editUserView.form.SMSAuthentication')}</div>
                <div className="mb-3">{mfa}</div>
              </div>
            </div>
          </div>
          <div className="form-group d-flex mt-4 justify-content-center">
            <button type="submit" className="btn-primary">
              {isLoadingEditUser ? <Loading /> : strings('editUserView.button.save')}
            </button>
          </div>
        </div>
      </form>
    )
  }

  /* PRIVATE METHOD */

  _apiPostEditUser(e) {
    e.preventDefault();
    this.props.apiPostEditUser(() => this._toggleModal());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearDataEditUser() {
    this.props.clearDataEditUser();
  }

  _handleValueChange = (prop, value) => {
    this.props.setEditUserState({ prop, value });
  };

  _handleViewChange = () => {
    this._toggleModal();
    const { history } = this.props;
    history.push(Routing.backOffice);
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurEditUser(inputType)
  }

  _setUserInfo(userInfo) {
    const email = EditUserManager.getUserEmail(userInfo)
    const fullName = EditUserManager.getUserFullName(userInfo)
    const isAdmin = EditUserManager.getUserAdminState(userInfo)
    const mfa = EditUserManager.getUserMfa(userInfo)
    const orgIdSap = EditUserManager.getUserIdSap(userInfo)
    const phone = EditUserManager.getUserPhone(userInfo)
    const userId = EditUserManager.getUserId(userInfo)

    this.props.setEditUserState({ prop: 'admin', value: isAdmin });
    this.props.setEditUserState({ prop: 'userId', value: userId });
    this.props.setEditUserState({ prop: 'username', value: fullName ? fullName : '' });
    this.props.setEditUserState({ prop: 'email', value: email });
    this.props.setEditUserState({ prop: 'mfa', value: mfa });
    this.props.setEditUserState({ prop: 'organization', value: orgIdSap });
    this.props.setEditUserState({ prop: 'phone', value: phone });

    const newKey = Math.random(); // Re render radio inputs to check correct value
    this.setState({ key: newKey })
  }

  _toggleModal() {
    const editUserConfirm = document.getElementById('editUserConfirm');
    if (editUserConfirm.classList.contains('invisible')) {
      editUserConfirm.classList.remove('invisible')
      this.setState({ isModalShown: true })
    } else {
      editUserConfirm.classList.add('invisible')
      this.setState({ isModalShown: false })
    }
  }
}

const mapStateToProps = ({ EditUserReducer, BackOfficeReducer }) => {
  const {
    admin, email, error, isLoadingEditUser, mfa, organization, phone, username, requestMessage, surnames,
  } = EditUserReducer;
  const { backOfficeOrgData } = BackOfficeReducer;

  return {
    admin, backOfficeOrgData, email, error, isLoadingEditUser, mfa, organization, phone, username, requestMessage, surnames,
  }
}

const mapStateToPropsAction = {
  apiPostEditUser,
  clearDataEditUser,
  setEditUserState,
  validateOnBlurEditUser,
};
export default connect(mapStateToProps, mapStateToPropsAction)(EditUserContainer);
