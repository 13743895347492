import Config from "react-environment";
import moment from "moment";

export const changeCurrentLimit = (setDataProps, newLimit, id) => {
  setDataProps({ prop: id + 'Limit', value: newLimit })
}

export const changeCurrentPage = async (setDataProps, nextPage, id) => {
  await setDataProps({ prop: id + 'CurrentPage', value: nextPage })
  const yOffset = -250;
  const element = document.getElementById('tableResult');
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
export const formatToNumberWithCommas = (value) => {
  // return value ? value.toString().replace(".",",").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") : '';
  if (value === 0) {
    return 0;
  }
  return value ? value.toString().replace(".", ",").split(/(?=(?:\d{3})+(?:\,|$))/g).join(".") : '';
}

export const getActualMonth = () => {
  let month = new Date().toLocaleString('default', { month: 'long' });
  let monthToUpperCase = month.charAt(0).toUpperCase() + month.slice(1);
  return monthToUpperCase;
}


export const getMomentDateFormat = (date, format) => {
  return moment(date).format(format);
}

export const getMomentNextYear = (format) => {
  return moment().add(1, 'years').format(format);
}

export const getMomentNow = (format) => {
  return moment().format(format);
}

export const getMomentYearAgo = (format) => {
  return moment().subtract(1, 'years').format(format);
}

export const isApp = () => {
  return Config.REACT_APP_IS_APP === 'true'
}

export const isDev = () => {
  return Config.REACT_APP_IS_DEV === 'true'
}

export const isWeb = () => {
  return Config.REACT_APP_IS_WEB === 'true'
}

export const objectSize = (obj) => {
  let size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

export const parseDecimal = (value, decimal = 2) => {
  return (Math.round(value * 100) / 100).toFixed(decimal)
}

export const parseStringVar = (string) => {
  let arr = string.split('_');
  let result = '';

  for (let i = 0; i < arr.length; i++) {
    if (i !== 0) {
      let str = arr[i].toLowerCase();
      arr[i] = str.charAt(0).toUpperCase() + str.slice(1);
    }
    result += arr[i];
  }
  return result;
}
