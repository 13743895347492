import { strings } from '../../resources/locales/i18n.js';
import { parseDecimal } from '../../utils/Utils.js'

export const CardUsesStatus = {
  CANCELED: "Canceled",
  ENABLE: "Active",
  REDEEMED: "Redeemed",
  PENDING: "Pending"
}

export const monthsTranslator = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
}

export default class DashboardManager {


  static getCurrentEmittedQuantity(data) {
    let lastEmittedQuantity = '';
    if(data && data.length > 0){
      lastEmittedQuantity = parseDecimal(data[data.length-1].emittedQuantity, 0);
    }
    return lastEmittedQuantity || null;
  }

  static getCurrentMonthData(data) {
    let lastMonth = '';
    if(data && data.length > 0){
      lastMonth = data[data.length-1];
    }
    return lastMonth || null;
  }

  static getCurrentSpending(data) {
    let lastSpending = '';
    if(data && data.length > 0){
      lastSpending = parseDecimal(data[data.length-1].Spending, 0);
    }
    return lastSpending || null;
  }

  static getHistoryEmitted = (data) => {
    let emittedData = []
    if (data && data.length > 0) {
      data.forEach((element, index) => {
        const emittedQuantityFormatted = parseDecimal(element.emittedQuantity, 0);
        if (index !== data.length - 1) {
          emittedData.push(emittedQuantityFormatted)
        }
      });
      return emittedData;
    }
  };

  static getHistoryMonths = (data) => {
    let monthsData = []
    if (data && data.length > 0) {
      data.forEach((element, index) => {
        let monthName = this.getMonthName(element)
        if (index !== data.length - 1) {
          monthsData.push(monthName)
        }
      });
      return monthsData;
    }
  };

  static getHistorySpending = (data) => {
    let spendingData = []
    if (data && data.length > 0) {
      data.forEach((element, index) => {
        const spendingFormatted = parseDecimal(element.Spending, 0);
        if (index !== data.length - 1) {
          spendingData.push(spendingFormatted)
        }
      });
      return spendingData;
    }
  };

  static getMonthName = (data, isLongFormat) => {
    let month = '';
    if (data && data.Month) {
      if (data.Month === monthsTranslator.JANUARY) {
        isLongFormat ? month = strings('dashboardView.months.january') :
          month = strings('dashboardView.shortMonths.january')
      }
      if (data.Month === monthsTranslator.FEBRUARY) {
        isLongFormat ? month = strings('dashboardView.months.february') :
          month = strings('dashboardView.shortMonths.february')
      }
      if (data.Month === monthsTranslator.MARCH) {
        isLongFormat ? month = strings('dashboardView.months.march') :
          month = strings('dashboardView.shortMonths.march')
      }
      if (data.Month === monthsTranslator.APRIL) {
        isLongFormat ? month = strings('dashboardView.months.april') :
          month = strings('dashboardView.shortMonths.april')
      }
      if (data.Month === monthsTranslator.MAY) {
        isLongFormat ? month = strings('dashboardView.months.may') :
          month = strings('dashboardView.shortMonths.may')
      }
      if (data.Month === monthsTranslator.JUNE) {
        isLongFormat ? month = strings('dashboardView.months.june') :
          month = strings('dashboardView.shortMonths.june')
      }
      if (data.Month === monthsTranslator.JULY) {
        isLongFormat ? month = strings('dashboardView.months.july') :
          month = strings('dashboardView.shortMonths.july')
      }
      if (data.Month === monthsTranslator.AUGUST) {
        isLongFormat ? month = strings('dashboardView.months.august') :
          month = strings('dashboardView.shortMonths.august')
      }
      if (data.Month === monthsTranslator.SEPTEMBER) {
        isLongFormat ? month = strings('dashboardView.months.september') :
          month = strings('dashboardView.shortMonths.september')
      }
      if (data.Month === monthsTranslator.OCTOBER) {
        isLongFormat ? month = strings('dashboardView.months.october') :
          month = strings('dashboardView.shortMonths.october')
      }
      if (data.Month === monthsTranslator.NOVEMBER) {
        isLongFormat ? month = strings('dashboardView.months.november') :
          month = strings('dashboardView.shortMonths.november')
      }
      if (data.Month === monthsTranslator.DECEMBER) {
        isLongFormat ? month = strings('dashboardView.months.december') :
          month = strings('dashboardView.shortMonths.december')
      }
    }
    return data && data.Month && month ? month : ''
  }

  static getStatus = (data) => {
    let status = '';
    if (data && data.status) {
      if (data.status === CardUsesStatus.CANCELED) {
        status = strings('dashboardView.tableCardsStatus.usesStatus.canceled')
      }
      if (data.status === CardUsesStatus.ENABLE) {
        status = strings('dashboardView.tableCardsStatus.usesStatus.enabled')
      }
      if (data.status === CardUsesStatus.REDEEMED) {
        status = strings('dashboardView.tableCardsStatus.usesStatus.redeemed')
      }
      if (data.status === CardUsesStatus.PENDING) {
        status = strings('dashboardView.tableCardsStatus.usesStatus.pending')
      }
    }
    return data && data.status && status ? status : ''
  }

};

