import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


// Actions
import {
  apiConfirmAccount,
  clearDataConfirmation,
  setConfirmationState,
  resendConfirmationCode,
  validateOnBlurConfirmation,
} from './ConfirmAccountActions';

// Components
import { AlertContainer } from '../../../components';
import { Loading } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import { localAssets } from '../../../resources/assets/assets.js';
import { strings } from '../../../resources/locales/i18n.js';
import Routing from '../../routing/Routing';


class ConfirmAccountContainer extends Component {

  componentDidMount() {
    this._clearDataConfirmation();
  }

  render() {
    const { username } = this.props;

    if (!username) {
      const { history } = this.props;
      history.push(Routing.login)
    }

    return (
      <>
        <section className="container-fluid wrapper login" >
          <div className="row h-100">
            <div className="col-12 col-md-6 login-form d-flex align-items-start justify-content-center py-5">
              <div className="login-form--wrapper d-flex flex-column align-items-start px-4">
                <img src={localAssets.imgLogoHiperdino.default} alt="logo de Hiperdino" className="mb-4" />
                <div className="login-form--wrapper d-flex align-items-center ">
                  <span className="title-big mb-4">
                    {strings('global.title')}
                  </span>
                </div>
                {this.renderConfirmationForm()}
                <div className="new-add">
                  <span className="title-mid d-block mb-4">
                    {strings('login.organizationRegister')}
                  </span>
                  <p>
                    {strings('login.organizationDescription')}
                  </p>
                  <Link to={Routing.contact} className="btn-secundary w-75">
                    {strings('login.contactButton')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 login-img" />
          </div>
        </section >
      </>

    );
  }

  renderConfirmationForm() {
    const { confirmationCode, error, isLoadingConfirmation, username, requestMessage } = this.props;

    return (
      <form id="confirmationForm" onSubmit={(e) => this._apiConfirmAccount(e)} action="" className="w-100 mt-5">
        <span className="title-mid d-block mb-4">
          {strings('confirmAccountView.confirmSignup')}
        </span>
        <div className="row pb-4">
          <div className="col-12">
            <p>{strings('confirmAccountView.confirmInstructions')}</p>
          </div>

        </div>

        <AlertContainer
          error={error}
          id={'request'}
          className={requestMessage ? 'alert-success' : 'alert-danger'}
          message={requestMessage}
        />

        <TextInput
          title={strings('createUserView.email')}
          id="username"
          name="username"
          type="text"
          error={error}
          placeholder=""
          onChange={(e) => null}
          value={username}
          onBlur={(e) => null}
          onKeyDown={(e) => null}
          readOnly
        />

        <TextInput
          title={strings('createUserView.confirmationCode')}
          id="confirmationCode"
          name="confirmationCode"
          type="text"
          error={error}
          placeholder=''
          onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
          value={confirmationCode}
          onBlur={(e) => this._validateOnBlur(e.target.id)}
          onKeyDown={(e) => this._callTabFunction(e)}
        />
        <div style={{ marginTop: -10 }}>
          <span> {strings('createUserView.lostYourCode')} </span>
          <a onClick={(e) => this._resendConfirmationCode(e)} style={{ paddingLeft: 1, color: '#77BD1E', cursor: 'pointer' }}> {strings('createUserView.resendCode')} </a>
        </div>

        <div className="w-100 ml-0 mt-5 row justify-content-between align-items-center">
          <a onClick={(e) => this._goBack()} className="col-12 col-sm-4 p-0 mb-4 mb-sm-0 title-lite" style={{ cursor: 'pointer' }}>
            {strings('createUserView.backToSignIn')}
          </a>
          <button type="submit" className="btn-primary col-12 col-sm-5 p-0" disabled={!username}>
            {isLoadingConfirmation ? <Loading /> : strings('createUserView.button.confirm')}
          </button>
        </div>

      </form>
    )
  }

  /* PRIVATE METHOD */
  _apiConfirmAccount(e) {
    e.preventDefault();
    this.props.apiConfirmAccount(() => this._goBack(), () => this._handleViewChange());
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearDataConfirmation() {
    this.props.clearDataConfirmation();
  }

  _goBack() {
    const { history } = this.props;
    history.push(Routing.login)
  }

  _handleInputChange(e) {
    this._handleValueChange(e.target.id, e.target.value)
  };

  _handleValueChange = (prop, value) => {
    this.props.setConfirmationState({ prop, value });
  };

  _handleViewChange = () => {
    const { history } = this.props;
    history.push(Routing.dashboard);
  };

  _resendConfirmationCode(e) {
    const { username } = this.props;
    e.preventDefault();
    this.props.resendConfirmationCode(username);
  }

  _validateOnBlur(inputType) {
    this.props.validateOnBlurConfirmation(inputType)
  }

}

const mapStateToProps = ({ ConfirmAccountReducer, LoginReducer }) => {
  const { username } = LoginReducer;
  const {
    confirmationCode,
    error,
    isLoadingConfirmation,
    requestMessage
  } = ConfirmAccountReducer;


  return {
    confirmationCode, error, isLoadingConfirmation, requestMessage, username,
  }
}


const mapStateToPropsAction = {
  apiConfirmAccount,
  clearDataConfirmation,
  resendConfirmationCode,
  setConfirmationState,
  validateOnBlurConfirmation,
};

export default connect(mapStateToProps, mapStateToPropsAction)(ConfirmAccountContainer);
