import React, { Component, useState, useEffect } from 'react';

// Resources
import { strings } from '../resources/locales/i18n.js';
import { localAssets } from '../resources/assets/assets.js';

const onboardingData = [
  {
    background: localAssets.imgOnboardingBgFirst.default,
    icon: localAssets.imgOnboardingIcFirst.default,
    text: strings('onboarding.welcome.text'),
    title: strings('onboarding.welcome.title')
  },
  {
    background: localAssets.imgOnboardingBgSecond.default,
    icon: localAssets.imgOnboardingIcSecond.default,
    text: strings('onboarding.subscribe.text'),
    title: strings('onboarding.subscribe.title')
  },
  {
    background: localAssets.imgOnboardingBgThird.default,
    icon: localAssets.imgOnboardingIcThird.default,
    text: strings('onboarding.cards.text'),
    title: strings('onboarding.cards.title')
  },
  {
    background: localAssets.imgOnboardingBgFourth.default,
    icon: localAssets.imgOnboardingIcFourth.default,
    text: strings('onboarding.consult.text'),
    title: strings('onboarding.consult.title')
  },
  {
    background: localAssets.imgOnboardingBgFifth.default,
    icon: localAssets.imgOnboardingIcFifth.default,
    text: strings('onboarding.purchase.text'),
    title: strings('onboarding.purchase.title')
  },
]

const OnboardingContainer = (props) => {
  const [currentPage, setCurrentPage] = useState(0)
  const { markAsSeen } = props;

  const currentIsFirstPage = currentPage === 0;
  const currentIsLastPage = currentPage === 4;
  const [animation, setAnimation] = useState({
    modal: {
      marginTop: -100,
      opacity: 0,
      transition: 'opacity 0.3s ease-out .1s, margin-top 0.5s ease-out .1s',
    },
    background: {
      opacity: 0,
      zIndex: -1,
      transition: 'opacity 0.3s ease-out',
    }
  })
  useEffect(() => {
    _openOnboardingAnimation(setAnimation);
  }, [])

  const nextPage = () => {
    if (currentIsLastPage) {
      return _closeOnboarding(markAsSeen);
    }
    setCurrentPage(currentPage + 1)
  }

  const previousPage = () => {
    if (currentIsFirstPage) {
      return;
    }
    setCurrentPage(currentPage - 1)
  }

  return (
    <>
      <div
        style={{ position: 'fixed', top: 0, left: 0, zIndex: 1050, width: '100%', height: '100%', overflow: 'hidden', outline: 0, ...animation.modal }}
        id='onboardingModal'
        className='d-flex align-items-center '
        tabindex="2"
        role="dialog"
        aria-labelledby="myModalLabel"
      >

        <div className="modal-dialog mx-auto mb-md-5 mt-md-n5 onboarding-container" style={{ margin: 0 }} role="document">

          <div className="modal-content" style={{
            backgroundImage: `url(${onboardingData[currentPage].background})`, backgroundPosition: 'center', backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat', border: '1px solid white', height: '100%'
          }}>

            <div className="modal-header p-0 pr-1" style={{ borderBottom: 'none' }}>
              <button type="button" className="close text-white pt-0 font-weight-bold" aria-label="Close" onClick={() => _closeOnboarding(markAsSeen)}>
                <span className="display-4" aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body d-flex justify-content-center row">
              <div className="row justify-content-center">
                <div className="col-12 mb-4 text-center">
                  <img src={onboardingData[currentPage].icon} alt="Icono de mascota Hiperdino" className="mb-4" style={{ width: 120 }} />
                  <span className="title-onboarding title-onboarding-xl d-block mb-2">{onboardingData[currentPage].title[0].toUpperCase()}</span>
                  <span className="title-onboarding title-onboarding-xl d-block mb-2">{onboardingData[currentPage].title[1].toUpperCase()}</span>
                  <span className="title-onboarding title-onboarding-xl d-block mb-4">{onboardingData[currentPage].title[2].toUpperCase()}</span>
                  <div className='d-flex align-items-center justify-content-center py-md-3'>
                    {_renderStepper(currentPage)}
                  </div>
                  <p className='title-onboarding-big px-5 mt-4'>{onboardingData[currentPage].text}</p>
                </div>
              </div>
            </div>

            <div className="modal-footer row d-flex justify-content-center align-items-center" style={{ borderTop: 'none' }}>
              {/* <a className="text-white mx-3 d-flex justify-content-center align-items-center" style={ currentIsFirstPage ? { visibility: 'hidden' } : {border: '1px solid white', borderRadius: 100, width: 40, height: 40}} onClick={(e) => secondaryAction()} >
                <div className="material-icons">
                  navigate_before
                  </div>
              </a>
              <a className="text-white mx-3 d-flex justify-content-center align-items-center" style={currentIsLastPage ? { visibility: 'hidden' } : {border: '1px solid white', borderRadius: 100, width: 40, height: 40}} onClick={(e) => secondaryAction()} >
                <div className="material-icons">
                  navigate_next
                  </div>
              </a> */}
              <button type="button" className="btn btn-out text-white border-white mb-5 mx-2" style={currentIsFirstPage ? { visibility: 'hidden' } : {}} onClick={() => previousPage()}>
                {strings('onboarding.button.previous')}
              </button>
              <button type="button" className="btn btn-out text-white border-white mb-5 mx-2" onClick={() => nextPage()}>
                {currentIsLastPage ? strings('onboarding.button.understood') : strings('onboarding.button.next')}
              </button>
            </div>

          </div>
        </div>
        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: -1, width: '100%', height: '100%', overflow: 'hidden', outline: 0, backgroundColor: '#333', opacity: 0.8, ...animation.background }} >
        </div>
      </div>
    </>

  )
};

const _openOnboardingAnimation = setAnimation => {
  setTimeout(() => {
    _renderEnterAnimationOnMount(setAnimation);
  }, 1);
}

const _renderEnterAnimationOnMount = (setAnimation) => {
  setAnimation(
    {
      modal: {
        marginTop: 0,
        opacity: 1,
        transition: 'opacity 0.3s ease-out .2s, margin-top 0.3s ease-out .2s',
      },
      background: {
        opacity: 0.8,
        zIndex: -1,
        transition: 'opacity 0.2s ease-out',
      }
    }
  )
}

const _closeOnboarding = (markAsSeen) => {
  const onboardingModal = document.getElementById('onboardingModal')
  if (onboardingModal.classList.contains('invisible')) {
    onboardingModal.classList.remove('invisible')
  } else {
    onboardingModal.classList.add('invisible')
    markAsSeen()
  }
}

const _renderDivider = () => {
  const circleCount = [1, 1, 1, 1, 1, 1, 1, 1]
  return (
    <div>
      {
        circleCount.map((element, idx) => {
          let classStyles = 'mx-1 p-1 bg-white'
          return (
            <span key={idx} className={classStyles} style={{ borderRadius: 100, border: '1px solid white' }}></span>
          )
        })
      }
    </div>
  )
}

const _renderStepper = (currentPage) => {
  return onboardingData.map((page, idx) => {
    let classStyles = 'mx-2 p-1 ' + (idx === currentPage ? 'bg-white' : '')

    return (
      <span key={idx} className={classStyles} style={{ borderRadius: 100, border: '1px solid white' }}></span>
    )
  })
}

export { OnboardingContainer }
