import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import {
  apiGetOrg,
  apiPostCreateOrg,
  clearDataCreateOrg,
  setCreateOrgState,
  validateOnBlurCreateOrg,
} from './CreateOrgActions'

// Components
import { AlertContainer } from 'src/components';
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { ModalContainer } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing.jsx';

class CreateOrgContainer extends Component {

  state = {
    isCreateMode: false,
    isModalShown: false,
  }

  componentDidMount() {
    this._clearDataCreateOrg();
  }
  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.backOffice'), path: Routing.backOffice },
      { name: strings('viewName.createOrg'), path: Routing.nowhere },
    ]

    return (
      <>
        <HeaderContainer title={strings('viewName.backOffice')} breadcrumbList={breadcrumbList} />
        <ModalContainer
          closeAction={() => this._toggleModal()}
          icon='check'
          message={strings('createOrgView.modal.message')}
          modalId='confirmCreateOrgResponse'
          isModalShown={this.state.isModalShown}
          primaryAction={() => this._toggleModal()}
          primaryButtonText={strings('createOrgView.modal.primaryButton')}
          title={strings('createOrgView.modal.title')}
        />
        <section className="container wrapper wrapper--content d-flex pt-5 pb-5 justify-content-center">
          <div className="col-12 col-lg-9 mb-4 mb-md-0">
            <div className="card pl-5 pr-5 pb-3 pt-2">
              {this.renderCreateOrgForm()}
            </div>
          </div>
        </section>
      </>
    )
  }

  renderCreateOrgForm() {
    const
      { error, idSap, isLoadingCreateOrg, name } = this.props;

    return (
      <form id="createOrgForm" onSubmit={(e) => this._apiPostCreateOrg(e)} action="" className="w-100 mt-5">
        <AlertContainer
          error={error}
          id={'request'}
          className={'alert-danger'}
        />
        <div className="row pb-4">
          <div className="col-12 pb-5">
            <span className="title-mid d-block">{strings('createOrgView.orgData')}</span>
          </div>
          { /** ID SAP */}
          <div className="col-12 col-xl-6">
            <TextInput
              title={strings('createOrgView.form.idSap')}
              id="idSap"
              name="idSap"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleIdInputChange(e)}
              value={idSap}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
              onFocus={(e) => this._closeCreateMode()}
            />
          </div>
          { /** NAME */}
          <div className="col-12 col-xl-6">
            <TextInput
              title={strings('createOrgView.form.name')}
              id="name"
              name="name"
              type="text"
              error={error}
              placeholder=""
              onChange={(e) => this._handleValueChange(e.target.id, e.target.value)}
              value={this.state.isCreateMode ? name : ''}
              onBlur={(e) => this._validateOnBlur(e.target.id)}
              onKeyDown={(e) => this._callTabFunction(e)}
              readOnly
            />
          </div>

          <div className="form-group d-flex mt-4 justify-content-center">
            {this.state.isCreateMode ?
              <button type="submit" className="btn-primary">
                {isLoadingCreateOrg ? <Loading /> : strings('createOrgView.button.createOrg')}
              </button>
              :
              <button className="btn-primary" onClick={this._apiGetOrg.bind(this)} onSubmit={(e) => { return }}>
                {isLoadingCreateOrg ? <Loading /> : strings('createOrgView.button.getOrg')}
              </button>
            }
          </div>
        </div>
      </form>
    )
  }

  /* PRIVATE METHOD */

  _activeCreateMode() {
    this.setState({ isCreateMode: true })
  }

  _apiGetOrg(e) {
    e.preventDefault();
    this.props.apiGetOrg(this._activeCreateMode.bind(this));
  }

  _apiPostCreateOrg(e) {
    e.preventDefault();
    this.props.apiPostCreateOrg(() => this._toggleModal(), () => this._closeCreateMode())
  }

  _callTabFunction(e, inputType) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType)
    }
  }

  _clearDataCreateOrg() {
    this.props.clearDataCreateOrg();
  }

  _closeCreateMode() {
    this.setState({ isCreateMode: false })
  }

  _handleIdInputChange = (e) => {
    if (e.target.value.length !== 6) {
      this._closeCreateMode();
      this.props.setCreateOrgState({ prop: 'error', value: [] });

    }
    this._handleValueChange(e.target.id, e.target.value)
  };

  _handleValueChange = (prop, value) => {
    this.props.setCreateOrgState({ prop, value });
  };

  _handleViewChange = () => {
    const { history } = this.props;
    history.push(Routing.backOffice);
  };

  _validateOnBlur(inputType) {
    this.props.validateOnBlurCreateOrg(inputType)
  }


  _toggleModal() {
    const confirmCreateOrgResponse = document.getElementById('confirmCreateOrgResponse');
    if (confirmCreateOrgResponse.classList.contains('invisible')) {
      confirmCreateOrgResponse.classList.remove('invisible')
      this.setState({ isModalShown: true })
    } else {
      confirmCreateOrgResponse.classList.add('invisible')
      this.setState({ isModalShown: false });
      this._handleViewChange();
    }
  }
}

const mapStateToProps = ({ CreateOrgReducer }) => {
  const {
    error, idSap, isLoadingCreateOrg, name
  } = CreateOrgReducer;

  return {
    error, idSap, isLoadingCreateOrg, name
  }
}

const mapStateToPropsAction = {
  apiGetOrg,
  apiPostCreateOrg,
  clearDataCreateOrg,
  setCreateOrgState,
  validateOnBlurCreateOrg,
};
export default connect(mapStateToProps, mapStateToPropsAction)(CreateOrgContainer);