import React, {Component} from 'react';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

class RegisterContainer extends Component  {

  render() {

    return (
      <div>
        <AmplifySignOut />
        My App
      </div>
    )
  }

}
export default withAuthenticator(RegisterContainer);
