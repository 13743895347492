import Types from "./Types";

const INITIAL_STATE = {
  isLoadingNotificationsInfo: false,
  newNotifications: 0,
  notificationsData: [],
};


export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR NOTIFICATIONS INFO **/
    case Types.CLEAR_NOTIFICATIONS_INFO:
      return { ...INITIAL_STATE };

    /** GET NOTIFICATIONS INFO**/
    case Types.GET_NOTIFICATIONS_INFO_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_NOTIFICATIONS_INFO_SUCCESS:
      return {
        ...state,
        newNotifications: action.payload.newNotifications,
        notificationsData: action.payload.notifications.map(item => {return {...item, isLoadingPut: false, isLoadingDelete: false}}),
      };

    /** UPDATE STATE **/
    case Types.SET_DATA_NOTIFICATIONS_INFO:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
