import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import {
  apiCheckCard,
  apiPostRechargeCards,
  apiPostRechargeExcel,
  clearDataRechargeCards,
  setRechargeCardsDataState,
  setRechargeCardsElementDataState,
  validateOnBlurRechargeCards
} from './RechargeCardsActions.js';

// Components
import HeaderContainer from 'src/modules/navegation/HeaderContainer.jsx';
import { Loading } from 'src/components';
import { TextInput } from 'src/components';

// Resources
import { strings } from '../../resources/locales/i18n.js';
import Routing from '../routing/Routing';
import { parseDecimal, getMomentNow, getMomentNextYear } from '../../utils/Utils.js';


class RechargeCardsContainer extends Component {
  state = {
    formModuleElement: {
      cardId: '',
      rechargeAmount: '',
    },
    formType: 'cardsForm',
    key: 0,
    modalIsShown: false,
  }
  componentDidMount() {
    this._clearDataRechargeCards();
  }

  render() {
    const breadcrumbList = [
      { name: strings('viewName.home'), path: Routing.app },
      { name: strings('viewName.purchaseCards'), path: '#' },
    ];
    const title = strings('viewName.purchaseCards');
    return (
      <>
        <HeaderContainer title={title} breadcrumbList={breadcrumbList} />
        {this.renderRechargeSection()}
        {this.renderRequestModal()}
      </>
    )

  };

  renderRequestModal() {
    const { confirmationRequest, errorRequest } = this.props;
    const animationBgStyle = this.state.modalIsShown ? {
      opacity: 0.8
    } : {}
    const animationModalStyle = this.state.modalIsShown ? {
      marginTop: -50,
      opacity: 1
    } : {}
    return (
      <div className="position-fixed fixed-top w-100 h-100 d-flex justify-content-center align-items-center invisible" id="confirmationRequest">
        <div className="position-absolute">
          <div className="modal-dialog mb-5" role="document" style={{ marginTop: -180, opacity: 0, transition: 'opacity 0.4s ease-out, margin-top 0.4s ease-out', ...animationModalStyle }}>
            <div className="modal-content">
              <div className="modal-header" style={{ borderBottom: 'none' }}>
                <button type="button" className="close" aria-label="Close" onClick={() => { this._toggleModal(); this._clearDataRechargeCards() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body d-flex justify-content-center row px-5">
                <div className="row justify-content-center">
                  <div className="col-12 mb-4 text-center">
                    <span className="material-icons icon-thanks">
                      {confirmationRequest ? 'send' : 'error_outline'}
                    </span>
                    <span className="title-big d-block mb-3">{confirmationRequest ? strings('rechargeCardsView.requestSent') : strings('rechargeCardsView.error')}</span>
                    <p>{confirmationRequest || errorRequest}</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center" style={{ borderTop: 'none' }}>
                <button type="button" className="btn btn-primary mb-3" onClick={() => { this._toggleModal(); this._clearDataRechargeCards() }}>
                  {strings('button.accept')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute w-100 h-100 bg-dark" style={{
          opacity: 0, zIndex: -1,
          transition: 'opacity 0.4s ease-out',
          ...animationBgStyle
        }} />
      </div>
    )
  }

  renderFormModule() {
    const { error, isLoadingRechargeCards, rechargeCardsData } = this.props;
    return (
      <>
        <form id="recargarForm" onKeyDown={(e) => {
          if (e.key == 'Enter') {
            e.preventDefault();
            return false;
          }
        }} onSubmit={(e) => this._apiPostRechargeCards(e)} action="" className="w-100 mt-3 mb-5">
          <div className="row">
            <div className="col-12">
              <span className="title-mid d-block">{strings('rechargeCardsView.recharge')}</span>
              <p className="mt-2">{strings('rechargeCardsView.rechargeOneOrMoreCards')}</p>
            </div>
          </div>
          {
            rechargeCardsData.map((item, index) => {
              return (
                <div id="templateTarjeta" className="row add-block mb-0 pb-0">
                  {/* CARD ID */}
                  <div className="col-12 col-sm-6">
                    <TextInput
                      title={strings('rechargeCardsView.form.cardId')}
                      id={`cardId-${index}`}
                      name="cardId"
                      type="text"
                      error={error}
                      placeholder=""
                      onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                      value={item.cardId}
                      onBlur={(e) => this._validateOnBlur(e.target.id, index, e.target.value)}
                      // onBlur={(e) => {return;}}
                      onKeyDown={(e) => this._callTabFunction(e, e.target.id, index)}
                    />
                  </div>
                  {/* RECHARGE AMOUNT */}
                  <div className="col-12 col-sm-6">
                    <div className="form-group form-group--label">
                      <label htmlFor="rechargeAmount">{strings('rechargeCardsView.form.rechargeAmount')}</label>
                      <input
                        key={this.state.key}
                        required
                        id={"rechargeAmount"}
                        data-name="rechargeAmount"
                        className="form-control"
                        type="number"
                        placeholder=""
                        value={item.rechargeAmount}
                        onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                        onBlur={(e) => this._handleValueDecimalChange(e.target.id, e.target.value, index)}
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 mt-n2">
                    <div className="mb-2">{strings('purchaseCardsView.form.expirationDate')}</div>
                    <div style={{ position: 'relative' }}>
                      <span className="material-icons icon-calendar">event</span>
                      <input
                        required
                        id={"endDate"}
                        className="form-control datepicker"
                        type="date"
                        placeholder=""
                        value={item.endDate}
                        onChange={(e) => this._handleValueChange(e.target.id, e.target.value, index)}
                        min={getMomentNow("yyyy-MM-DD")}
                      />
                    </div>
                  </div>
                  {index === 0 ? null : <a key={`btn-${index}`} id="deleteButton" className='btn-delete' onClick={(e) => this._deleteFormModule(e, index)}>{strings('button.delete')}</a>}

                </div>
              )
            })
          }
          <div className="row mt-3">
            <div className="col-12">
              <div className="form-group mt-2 mb-5">
                <a id="addButton" className="link-text d-flex align-items-center" style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={(e) => this._addFormModule(e)}><span className="material-icons mr-1">playlist_add</span>{strings('rechargeCardsView.addCard')}</a>
              </div>

              <div className="form-group d-flex justify-content-center">
                <button disabled={error.length > 0} type="submit" className="btn-primary">{isLoadingRechargeCards ?
                  <Loading /> : strings('rechargeCardsView.request')}</button>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  }
  renderRechargeSection() {

    return (
      <section className="container wrapper py-5">
        <div className="row module-tabs">
          <div className="col-12 mb-md-0">
            <div className="card p-0">

              <ul className="nav tab-nav nav-tabs p-4 text-center d-flex justify-content-between flex-wrap" id="pills-tab" role="tablist">
                <li className="nav-item p-0 m-0" role="presentation">
                  <Link to={Routing.purchaseCards} className="nav-link title-lite white py-4" id="compra-tab" data-toggle="pill" role="tab" aria-controls="compra-tarjeta" aria-selected="true">Comprar</Link>
                </li>
                <li className="nav-item p-0 m-0" role="presentation">
                  <Link to={Routing.rechargeCards} className="nav-link  title-lite white py-4 active" id="recarga-tab" data-toggle="pill" role="tab" aria-controls="recarga-tarjeta" aria-selected="false">Recargar</Link>
                </li>
              </ul>

              <div className="tab-content p-4 d-flex justify-content-center" id="pills-tabContent">
                <div className="tab-pane fade show active" id="recarga-tarjeta" role="tabpanel" aria-labelledby="recarga-tab">
                  {/* RADIO BUTTONS */}
                  <div className="mb-5">
                    <div >
                      <span className="title-mid d-block mb-3">{strings('rechargeCardsView.selectRechargeType')}</span>
                    </div>
                    <div className="mb-3 d-flex">
                      <div className="form-check mb-2 mr-5">
                        <input
                          className="form-check-input"
                          type="radio"
                          name='formType'
                          id='cardsForm'
                          value={'cardsForm'}
                          checked={this.state.formType === 'cardsForm'}
                          onChange={(e) => this.setState({ formType: e.target.value })}
                        />
                        <label className="form-check-label" htmlFor="Tarjetas">
                          {strings('rechargeCardsView.cardRecharge')}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name='formType'
                          id='uploadForm'
                          value={'uploadForm'}
                          checked={this.state.formType === 'uploadForm'}
                          onChange={(e) => this.setState({ formType: e.target.value })}
                        />
                        <label className="form-check-label" htmlFor="Facturas">
                          {strings('rechargeCardsView.uploadRecharge')}
                        </label>
                      </div>
                    </div>
                  </div>

                  {this.state.formType === 'cardsForm' ?
                    this.renderFormModule()
                    : this.renderUploadModule()
                  }

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

    )
  }

  renderUploadModule() {
    const { excelFile, isLoadingRechargeCards } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <span className="title-mid d-block">{strings('rechargeCardsView.recharge')}</span>
            <p className="mt-2">{strings('rechargeCardsView.uploadAFile')}</p>
            {excelFile ?
              <div className="col-12 mt-4 mb-3 p-0 d-flex align-items-center" style={{ maxWidth: 400 }}>
                <span className="material-icons p-1">description</span>
                <span>{excelFile.name}</span>
                <button type="button" className="close ml-auto" aria-label="Close" onClick={() => { this._deleteFile() }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              : <div />}
          </div>
        </div>
        <div className="row my-5">
          <div className="col-12">
            <div className="form-group d-flex justify-content-center">
              <input
                key={this.state.key}
                id="file"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => this._handleUploadChange(e)}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              {excelFile ?
                <button className="btn-primary" onClick={(e) => this._apiPostRechargeExcel(e)}>
                  {isLoadingRechargeCards ? <Loading /> :
                    <>
                      {strings('rechargeCardsView.upload')}
                      <span className="material-icons p-1">upload</span>
                    </>}
                </button>
                : <button className="btn-primary" onClick={(e) => this._uploadFile()}>
                  {isLoadingRechargeCards ? <Loading /> :
                    <>
                      {strings('rechargeCardsView.selectFile')}
                    </>}
                </button>
              }

            </div>
          </div>
        </div>
      </>
    )
  }

  //PRIVATE METHODS
  _addFormModule(e) {
    const { rechargeCardsData } = this.props;
    e.preventDefault();
    this._setRechargeCardsDataState({ prop: 'rechargeCardsData', value: rechargeCardsData.concat(this.state.formModuleElement) })
  }
  _apiCheckCard(prop, value) {
    this.props.apiCheckCard(prop, value)
  }

  _apiPostRechargeCards(e) {
    e.preventDefault();
    this.props.apiPostRechargeCards(() => this._toggleModal())
  }
  _apiPostRechargeExcel(e) {
    e.preventDefault();
    this.props.apiPostRechargeExcel(() => this._toggleModal())
  }

  _callTabFunction(e, inputType, index) {
    if (e.keyCode === 9) {
      this._validateOnBlur(inputType, index)
    }
  }

  _clearDataRechargeCards() {
    this.props.clearDataRechargeCards();
  }

  _deleteFile() {
    this._setRechargeCardsDataState({
      prop: 'excelFile', value: null
    })
    this._getRandomKey();
  }

  _deleteFormModule(e, index) {
    const { rechargeCardsData } = this.props;
    e.preventDefault();
    const cloneRechargeCardsData = [...rechargeCardsData]
    cloneRechargeCardsData.splice(index, 1)
    this._setRechargeCardsDataState({
      prop: 'rechargeCardsData', value: cloneRechargeCardsData
    })
  }

  _getRandomKey() {
    this.setState({ key: Math.random() })
  }

  _handleUploadChange(e) {
    const excelFile = e.target.files[0]
    this._setRechargeCardsDataState({
      prop: 'excelFile', value: excelFile
    })
  }

  _handleValueChange = (prop, value, index) => {
    this.props.setRechargeCardsElementDataState({ prop, value }, index);
    if (value.length === 13) {
      this._apiCheckCard(prop, value)
    }
  };

  _handleValueDecimalChange = (prop, value, index) => {
    if (value !== '')
      this.props.setRechargeCardsElementDataState({ prop, value: parseDecimal(value) }, index);
  };

  _setRechargeCardsDataState({ prop, value }) {
    this.props.setRechargeCardsDataState({ prop, value });
  }

  _toggleModal() {
    const confirmationRequest = document.getElementById('confirmationRequest');
    if (confirmationRequest.classList.contains('invisible')) {
      confirmationRequest.classList.remove('invisible')
      this.setState({ modalIsShown: true })
    } else {
      confirmationRequest.classList.add('invisible')
      this.setState({ modalIsShown: false })
      this._getRandomKey();
    }
  }

  _validateOnBlur(inputType, index, value) {
    if (value.length === 13) {
      return;
    }
    this.props.validateOnBlurRechargeCards(inputType, index)
  }

  _uploadFile() {
    const input = document.getElementById("file")
    input.click()
  }
}

const mapStateToProps = ({ RechargeCardsReducer }) => {
  const { confirmationRequest, error, errorRequest, excelFile, isLoadingRechargeCards, rechargeCardsData } = RechargeCardsReducer;
  return {
    confirmationRequest, error, errorRequest, excelFile, isLoadingRechargeCards, rechargeCardsData
  }
}


const mapStateToPropsAction = {
  apiCheckCard,
  apiPostRechargeCards,
  apiPostRechargeExcel,
  clearDataRechargeCards,
  setRechargeCardsDataState,
  setRechargeCardsElementDataState,
  validateOnBlurRechargeCards
};

export default connect(mapStateToProps, mapStateToPropsAction)(RechargeCardsContainer);
