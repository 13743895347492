import Types from './Types';

const INITIAL_STATE = {
  accessToken: '',
  currentPassword: '',
  isLoadingEditPassword: false,
  newPassword: '',
  passwordError: [],
  refreshToken: '',
  requestMessage: '',
  userInfo: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DATA **/
    case Types.CLEAR_DATA_USER:
      return { ...INITIAL_STATE, error: [] };

    /** CLEAR PASSWORDS **/
    case Types.CLEAR_PASSWORDS_USER:
      return { ...state, currentPassword: '', newPassword: '', passwordError: [] };

    /** EDIT_USER **/
    case Types.EDIT_PASSWORD_FAILED:
      return { ...state, passwordError: action.payload };

    /** USER_ACCESS TOKEN **/
    case Types.SET_USER_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };

    /** USER DATA **/
    case Types.SET_USER_DATA:
      return { ...state, userInfo: action.payload, };

    case Types.SET_USER_DATA_PROPS:
      return { ...state, [action.payload.prop]: action.payload.value };

    /** USER LOGOUT **/
    case Types.SET_USER_LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
