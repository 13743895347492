export default class Types {
    /** CLEAR HOMES **/
    static CLEAR_DATA_REMEMBER_PASSWORD = 'CLEAR_DATA_REMEMBER_PASSWORD';
  
    /** POST REMEMBER_PASSWORD **/
    static REMEMBER_PASSWORD_FAILED      = 'REMEMBER_PASSWORD_FAILED';
    static REMEMBER_PASSWORD_START       = 'REMEMBER_PASSWORD_START';
    static REMEMBER_PASSWORD_SUCCESS     = 'REMEMBER_PASSWORD_SUCCESS';
  
    /** UPDATE STATE **/
    static SET_REMEMBER_PASSWORD_STATE   = 'SET_REMEMBER_PASSWORD_STATE';
  }