import Types from "./Types";

const INITIAL_STATE = {
  dashboardData: null,
  error: [],
  isLoadingDashboardInfo: false,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** CLEAR DASHBOARD INFO **/
    case Types.CLEAR_DASHBOARD_INFO:
      return { ...INITIAL_STATE };

    /** GET DASHBOARD INFO**/
    case Types.GET_DASHBOARD_INFO_FAILED:
      return { ...state, error: action.payload.data.message };

    case Types.GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
      };

    /** UPDATE STATE **/
    case Types.SET_DATA_DASHBOARD_INFO:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
